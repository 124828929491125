<template>
  <section id="usuarios">
    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>


    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <font-awesome-icon 
            icon="user" 
            class="color-secondary"
          />
          {{ subtitle }}
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/usuarios/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Agregar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3 input-search">
        <input 
            type="search" 
            placeholder="Ingresa nombre o RUT sin puntos y con guion"
            v-model="search_user"
            class="form-control input-custom"
            @keypress.enter="searchUser()"
            @keyup.delete="checkEmptyInput()">
            <span class="input-search-icon" @click="searchUser">
              <font-awesome-icon icon="search"/>
            </span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-container">
          <table class="table table-custom table-borderless table-custom-responsive mb-0">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Rut</th>
                <th scope="col">Tipo de usuario</th>
                <th scope="col">Sucursal</th>
                <th scope="col" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody v-if="this.paginationArr.length > 0">
              <tr v-for="(user, k) in userArr" :key="k">
                <td class="th th-nombre">{{ user.primer_nombre }} {{ user.apellido_paterno }}</td>
                <td class="th th-rut">{{ user.rut_usuario | VMask(user.rut_usuario.length == 7 ? mask_short : mask) }}-{{ user.dv_usuario }}</td>
                <td class="th th-tipo-usuario">{{ user.privilegio[0].nombre_tipo }}</td>
                <td class="th th-sucursal">{{ user.sucursal[0].nombre_sucursal }}</td>
                <td class="th th-accion text-center">
                  <div 
                    class="dropdown dropdown-custom d-flex justify-content-center">
                    <button 
                      class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                      type="button" 
                      data-bs-toggle="dropdown">
                      <font-awesome-icon icon="ellipsis-vertical"/>
                    </button>
                  <ul 
                    class="dropdown-menu"
                    :class="user.id_privilegio_fk == '1' && 'unactive'">
                    <li>
                      <a 
                        @click="editUser(user)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                        Editar
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="deleteUserModal(user)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                        Eliminar
                      </a>
                    </li>
                  </ul>
                </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="alert alert-info" v-if="userArr.length == 0">
            <font-awesome-icon class="pe-2" icon="circle-info"/>
            {{ no_results }}
          </div>
          <div class="alert alert-info mt-3 mb-0" v-if="this.paginationArr.length == 0">
              <font-awesome-icon class="pe-2" icon="circle-info"/>
              Sin resultados
          </div>
        </div>

        <div class="users-list__pagination" v-if="this.pagination.page_count > 1">
            <paginate
              v-model="pagination.actual_page"
              :page-count="pagination.page_count"
              :page-range="pagination.page_range"
              :click-handler="pagination.click_handler"
              :prev-text="pagination.prev_text"
              :next-text="pagination.next_text"
              :container-class="pagination.container_class"
              :page-class="pagination.page_class"
              >
            </paginate>
          </div>
          <!--  Modal que muestra el estado de la accion (exito o error) -->
          <Status
            v-if="open_modal_status"
            :msg="modal_status_msg"
            :status="modal_status"
            @close="closeStatus"
            />
          <!-- Modal pregunta -->
          <Question
            v-if="open_question_modal"
            :msg="question_modal_msg"
            :hideCancel="false"
            @cancel="cancelQuestion"
            @accept="acceptQuestion"
            />
          <Spinner v-if="show_spinner"/>

      </div>
    </div>
  </section>

</template>

<script>
  //import "@/assets/scss/UsersList.scss";
  import Status from "../Modales/Status.vue";
  import Question from "../Modales/Question.vue";
  import {
    GET_SUCURSAL,
    GET_EMPRESA
  } from "@/apollo/queries.js";

  import { mapState, mapActions } from "vuex";
  import Spinner from "../Spinner.vue";
  import SectionTabs from "../Section/SectionTabs.vue";

  export default {
    components: { Status, Question, Spinner, SectionTabs },
    data() {
      return {
        error_rut: false,
        pagination:{
          actual_page: 1,
          page_count: 0,
          page_range: 3,
          click_handler: this.changePage,
          prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class:"pagination-item"
        },
        tab_component: [
          {
            name: "Inicio",
            route: "usuarios-list",
          },
          {
            name: "Agregar",
            route: "usuarios-add",
          },
        ],
        user: this.$ls.get("user"),
        subtitle:
          "Completa o edita la información de tus colaboradores.",
        users: [],
        users_search_list: [],
        user_selected: null,
        search_user: "",
        no_results: "",
        company_list: [],
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        open_question_modal: false,
        question_modal_msg: "",
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        show_spinner: false,
        user_type_list: [],
        workplace_list: [],
        visible_users_list: [],
        open_select_list: false,
        filter_selected: "Tipo de usuario",
        mask: "##.###.###",
        mask_short: "#.###.###",
        sort_list: true,
        id_user:this.$ls.get("user").id_usuario,
        user_limit:'100',
      };
    },
    async mounted() {
      //this.show_spinner = true;
      this.getEmpresa();
      this.getSucursales();
      this.searchUser('init');
    },
    computed: {
      ...mapState("usersModule", ["users_list","loading", "paginationArr", "userArr"],),
      count_users() {
        return this.$store.state.usersModule.users_list.length;
      },
    },
    watch: {
      // Watch para detectar cambio en el v-model de buscar usuario
      // count_users() {
      //   this.visible_users_list = this.users_list;
      //   this.show_spinner = false;
      //   this.sortArrByNameUp();
      // },
    },
    methods: {
      ...mapActions("usersModule", ["getUsersList", "deleteUserAction", "getUserPagination", 'getUsersPaginated', 'addNewUser', "deleteUser"]),
      // Buscar usuario y mostrar lista de resultado
      async getUserPages(){
        let data_ = {
          id_empresa: this.id_empresa,
          id_usuario: this.id_user,
          nombre_usuario: this.search_user,
          limite: this.user_limit
        }
        await this.getUserPagination(data_);
        this.pagination.page_count = this.paginationArr.length;
      },
      async changePage(page){
        this.show_spinner = true;
        this.pagination.actual_page = page;
        await this.getUserByPage(this.pagination.actual_page);
        this.show_spinner = false;
      },
      async getUserByPage(page){
      this.show_spinner = true;
      //console.log('PAGE',page);
        //console.log('paginationarr',this.paginationArr);
        let data_ = {
          id_empresa: this.id_empresa,
          id_usuario: this.paginationArr[(page - 1)].usuarios,
        }
        //console.log(data_);
        await this.getUsersPaginated(data_)
        //console.log('USER ARRAY', this.userArr);
        this.visible_users_list = this.userArr;
        this.show_spinner = false;
      },
      async searchUser() {
        this.show_spinner = true;
        this.user_selected = null;
        await this.getUserPages();
        if(this.paginationArr.length > 0){
          this.pagination.actual_page = 1;
          await this.getUserByPage(this.pagination.actual_page);
          console.log('USER LIST', this.userArr);
          this.visible_users_list = this.userArr;
        }
        this.show_spinner = false;
      },
      // Funcion que abre el "detalle" del usuario al hacer click
      openUserDetail(user) {
        if(user.notlist == undefined){
        this.user_selected = user;
        this.visible_users_list = [user];
        this.users_search_list = [];
        this.search_user = "";}
        // this.search_user = user.primer_nombre + " " + user.apellido_paterno;
      },
      // Funcion para obtener la lista de usuarios al borrar el input de busqueda
      editUser(user) {
        if (user.id_privilegio_fk !== "1") {
          this.$router
            .push({
              name: "usuarios-edit",
              params: { id: user.id_usuario },
            })
            .catch(() => {
              return;
            });
        }
      },
      // Funcion para preguntar si elimina usuario
      deleteUserModal(user) {
        if (user.id_privilegio_fk !== "1") {
          this.user_selected = user;
          var name =
            user.primer_nombre.charAt(0).toUpperCase() +
            user.primer_nombre.slice(1);
          var lastname =
            user.apellido_paterno.charAt(0).toUpperCase() +
            user.apellido_paterno.slice(1);
          this.open_question_modal = true;
          this.question_modal_msg = `¿Quieres eliminar al usuario ${
            name + " " + lastname
          }? <br/> No podrás deshacer esta acción.`;
        }
      },
      // Funcion para cerrar modal Status
      closeStatus() {
        this.open_modal_status = false;
        this.user_selected = null;
      },
      // Funcion para "cancelar" la pregunta del Modal Question
      cancelQuestion() {
        this.open_question_modal = false;
      },
      // Funcion para "aceptar" la pregunta del modal Question (eliminar usuario)
    async acceptQuestion() {
        console.log('entra al eliminar', this.user_selected);
        this.open_question_modal = false;
        this.show_spinner = true;
        let data = {
          id_usuario: this.user_selected.id_usuario,
          id_empresa_fk: this.id_empresa,
          id_eliminador: this.user.id_usuario,
        };
        await this.deleteUser(data);
        this.$toast.open({
          message: 'Usuario eliminado exitosamente.',
          type: 'success',
          duration: 5000,
          position: 'top-right'
        });
      this.show_spinner = false;
      },
      // Obtener sucursales
      getSucursales() {
        this.$apollo
          .query({
            query: GET_SUCURSAL,
            variables: { id_empresa: this.id_empresa },
          })
          .then((res) => {
            var resp = res.data.sucursales;
            for (let i = 0; i < resp.length; i++) {
              this.workplace_list.push(resp[i]);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      },
      // Obtener empresa
      getEmpresa() {
        this.$apollo
          .query({
            query: GET_EMPRESA,
            variables: { id_empresa: "1" },
          })
          .then((res) => {
            var resp = res.data.empresas;
            for (let i = 0; i < resp.length; i++) {
              this.company_list.push(resp[i]);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      },
      checkEmptyInput(){
        if(this.search_user == ''){
          this.searchUser();
        }
      },
      deleteFromStoreCache(id_usuario){
        let user_index = this.visible_users_list.findIndex(user_ => {user_.id_usuario == id_usuario})
        console.log('USUARIO ENCONTRADO', user_index);
        if(user_index > -1){
          this.visible_users_list.splice(user_index, 1)
        }
      }
    },
  };
</script>