<template>
   <div class="spinner">
      <div class="spinner-container">
         <img src="@/assets/img/spinner.gif" alt="" />
      </div>
   </div>
</template>

<script>
export default {};
</script>