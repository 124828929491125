const wallList = [{
    path: "/admin-muro",
    name: "Muro-admin",
    component: (Wall) => require(["@/views/ADMIN/Wall.vue"], Wall),
    redirect: { name: "wall-list" },
    children: [{
            path: "list",
            name: "wall-list",
            component: (WallList) =>
                require(["@/components/Wall/List.vue"], WallList),
        },
        {
            path: "perfiles",
            name: "wall-perfiles",
            component: (WallProfiles) =>
                require(["@/components/Wall/ListPerfil.vue"], WallProfiles),
        },
        {
            path: "edit/:id",
            name: "post-edit",
            props: true,
            component: (WallEdit) =>
                require(["@/components/Wall/Add.vue"], WallEdit),
        },
        {
            path: "add",
            name: "post-add",
            component: (WallAdd) =>
                require(["@/components/Wall/Add.vue"], WallAdd),
        },
    ],
}, ];

export default {
    wallList,
};