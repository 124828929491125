import { apolloClient } from "../../../vue-apollo";
import { GET_SAFETY_DOCS } from  "../../../apollo/queries";
export default {
  namespaced: true,
  state: { safety_docs_list: [], library_docs_list: [] },
  mutations: {
    getDocs(state, resp) {
      state.safety_docs_list = resp;
    },
    addDoc(state, doc) {
      state.safety_docs_list.unshift(doc);
    },
    editDoc(state, editedDoc) {
      // obtiene index del obj doc
      var index = state.safety_docs_list.findIndex(
        (doc) => doc.id_documento == editedDoc.id_documento
      );
      // para sacar el antiguo y reemplazarlo por el nuevo obj
      state.safety_docs_list.splice(index, 1, editedDoc);
    },
    deleteDoc(state, idDoc) {
      var index = state.safety_docs_list.findIndex(
        (doc) => doc.id_documento == idDoc
      );
      state.safety_docs_list.splice(index, 1);
    },
    addDocLib(state, doc) {
      state.library_docs_list.unshift(doc);
    },
    editDocLib(state, editedDoc) {
      // obtiene index del obj doc
      var index = state.library_docs_list.findIndex(
        (doc) => doc.id_documento == editedDoc.id_documento
      );
      // para sacar el antiguo y reemplazarlo por el nuevo obj
      state.library_docs_list.splice(index, 1, editedDoc);
    },
    getDocsLib(state, resp) {
      state.library_docs_list = resp;
    },
    deleteDocLib(state, idDoc) {
      var index = state.library_docs_list.findIndex(
        (doc) => doc.id_documento == idDoc
      );
      state.library_docs_list.splice(index, 1);
    },
  },
  actions: {
    // Obtener documentos de seguridad
    async getDocsList({ commit }, doc) {
      const { data } = await apolloClient.query({
        query: GET_SAFETY_DOCS,
        variables: {
          id_empresa: doc.id_empresa,
          id_categoria: doc.id_categoria,
          id_modulo: doc.id_modulo,
          plataforma: doc.plataforma,
          nombre: doc.nombre,
        },
      });
      commit("getDocs", data.AllDocumentosAdmin);
    },
    // Agregar documento de seguridad
    async addDocAction({ commit }, doc) {
      commit("addDoc", doc);
    },
    // Agregar documento de biblioteca
    async addDocLibAction({ commit }, doc) {
      commit("addDocLib", doc);
    },
    // Eliminar documento de seguridad
    async deleteDocAction({ commit }, id) {
      commit("deleteDoc", id);
    },
    // Editar documento de seguridad
    async editDocAction({ commit }, doc) {
      var var_doc = {
        id_empresa: doc.id_empresa_fk,
        id_categoria: "0", //Categoría: "Todas"
        id_modulo: doc.id_modulo,
        plataforma: "2",
        nombre: "",
      };
      this.dispatch("getDocsList", var_doc);
      commit("editDoc", doc);
    },
    // Obtener documentos de biblio
    async getDocsLibList({ commit }, doc) {
      const { data } = await apolloClient.query({
        query: GET_SAFETY_DOCS,
        variables: {
          id_empresa: doc.id_empresa,
          id_categoria: doc.id_categoria,
          id_modulo: doc.id_modulo,
          plataforma: doc.plataforma,
          nombre: doc.nombre,
        },
      });
      commit("getDocsLib", data.AllDocumentosAdmin);
    },
    // Eliminar documento de biblio
    async deleteDocLibAction({ commit }, id) {
      commit("deleteDocLib", id);
    },
    // Editar documento de biblio
    async editDocLibAction({ commit }, doc) {
      var var_doc = {
        id_empresa: doc.id_empresa_fk,
        id_categoria: "0", //Categoría: "Todas"
        id_modulo: doc.id_modulo,
        plataforma: "2",
        nombre: "",
      };
      this.dispatch("getDocsLibList", var_doc);
      commit("editDocLib", doc);
    },
  },
};
