const cultureList = [{
    path: "/nuestra-cultura",
    name: "Cultura",
    component: (Culture) => require(["@/views/ADMIN/Culture.vue"], Culture),
    redirect: { name: "culture-list" },
    children: [{
            path: "add",
            name: "culture-add",
            component: (CultureAdd) =>
                require(["@/components/Culture/Add.vue"], CultureAdd),
        },
        {
            path: "addvalor/",
            name: "culture-valor-add",
            component: (CultureValorAdd) =>
                require(["@/components/Culture/AddValor.vue"], CultureValorAdd),
        },
        {
            path: "editvalor/:id",
            name: "culture-valor-edit",
            component: (CultureValorAdd) =>
                require(["@/components/Culture/AddValor.vue"], CultureValorAdd),
        },
        {
            path: "list",
            name: "culture-list",
            component: (CultureList) =>
                require(["@/components/Culture/List.vue"], CultureList),
        },
    ],
}, ];

export default {
    cultureList,
};