<template>
   <section class="content-container">
      <router-view/>
   </section>
</template>

<!-- <template>
   <div class="users">
      <div class="users-sidebar">
         <SideBar />
      </div>
      <div class="users-container">
         <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />
         <div class="users-body" :class="class_scroll">
            <div class="users-body-top">
               <SectionTitle :title="title" />
               <ProfileNav />
            </div>
            <router-view :key="$route.fullPath" />
            <footerSec></footerSec>
         </div>
      </div>
   </div>
</template> -->

<script>
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */

export default {
/*    components: {
      SideBar,
      SectionTitle,
      ProfileNav,
      footerSec,
   }, */
   data() {
      return {
         title: "Usuarios",
         class_scroll: "",
      };
   },
   watch: {
      $route(to) {
         // console.log("to", to, "from", from);
         if (to.name == "usuarios-add" || to.name == "usuarios-edit") {
            this.class_scroll = "class-scroll";
         }
         if (to.name == "usuarios-list") {
            this.class_scroll = "";
         }
      },
   },
   mounted() {
      console.log('');
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);

      this.checkMyLogin(this.$route);
      if (
         this.$route.name == "usuarios-edit" ||
         this.$route.name == "usuarios-add"
      ) {
         this.class_scroll = "class-scroll";
      }
   },
};
</script>