const videosList = [{
    path: "/videos",
    name: "Videos",
    component: (Video) => require(["@/views/ADMIN/Video.vue"], Video),
    redirect: { name: "videos-list" },
    children: [{
            path: "list",
            name: "videos-list",
            component: (VideoList) =>
                require(["@/components/VideosSection/List.vue"], VideoList),
        },
        {
            path: "add",
            name: "videos-add",
            component: (VideoAdd) =>
                require(["@/components/VideosSection/VideoAddPrincipal.vue"], VideoAdd),
        },
        {
            path: "edit/:id",
            name: "videos-edit",
            component: (VideoAdd) =>
                require(["@/components/VideosSection/VideoAddPrincipal.vue"], VideoAdd),
        },
        {
            path: "category",
            name: "videos-category",
            component: (VideoCategory) =>
                require(["@/components/VideosSection/VideoCategory.vue"], VideoCategory),
        },
    ],
}, ];

export default {
    videosList,
};