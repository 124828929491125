const libraryList = [
  {
    path: "/biblioteca",
    name: "Biblioteca",
    component: (Library) => require(["@/views/ADMIN/Library.vue"], Library),
    redirect: { name: "library-list" },
    children: [
      {
        path: "list",
        name: "library-list",
        component: (LibraryList) =>
          require(["@/components/Library/List.vue"], LibraryList),
      },
      {
        path: "add",
        name: "library-add",
        component: (LibraryAdd) =>
          require(["@/components/Library/Add.vue"], LibraryAdd),
      },
      {
        path: "edit/:id",
        name: "library-edit",
        component: (LibraryAdd) =>
          require(["@/components/Library/Add.vue"], LibraryAdd),
      },
      {
        path: "add-categories",
        name: "library-add-categories",
        component: (LibraryAddCat) =>
          require(["@/components/Library/AddCategories.vue"], LibraryAddCat),
      },
    ],
  },
];

export default {
  libraryList,
};
