<template>

  <section id="usuario-form">
    <SectionTabs :tabs="tab_component"/>

<!--|||||||||||| FF: MAQUETACIÓN FORMULARIO USUARIOS NO BORRAR ||||||||||||||-->

<!--     <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <font-awesome-icon 
            icon="user" 
            class="color-secondary"
          />
          {{ subtitle }}
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button 
          type="button"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="upload" class="pe-2"/>
          Carga masiva
        </button>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              <font-awesome-icon 
                icon="circle-user" 
                class="color-secondary"
              />
              Datos del usuario
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      Nombres
                      <span class="color-red">*</span>
                    </label>
                    <input 
                      type="text" 
                      v-model="usuario_custom.nombres"
                      class="form-control input-custom"
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      Apellidos
                      <span class="color-red">*</span>
                    </label>
                    <input 
                      type="text" 
                      v-model="usuario_custom.apellidos"
                      class="form-control input-custom"
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      RUT
                      <span class="color-red">*</span> 
                      <small> (Sin puntos y con guion)</small>
                    </label>
                    <input 
                      type="text" 
                      v-model="usuario.rut_usuario"
                      class="form-control input-custom"
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      Correo
                    </label>
                    <input 
                      type="text" 
                      v-model="usuario.correo"
                      class="form-control input-custom"
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      Teléfono móvil
                    </label>
                    <input 
                      type="text" 
                      v-model="usuario.telefono_movil"
                      class="form-control input-custom"
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      Teléfono fijo
                    </label>
                    <input 
                      type="text"
                      v-model="usuario.telefono_fijo"
                      class="form-control input-custom"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Género
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="generos"
                      placeholder="Tipos disponibles"
                      v-model="usuario.genero"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Nacionalidad
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="nationArr"
                      label="name"
                      placeholder="Tipos disponibles"
                      v-model="usuario.nacion"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12 input-display input-display-right">
                    <label class="text-secondary">
                      Fecha de nacimiento
                      <span class="color-red">*</span>
                    </label>
                    <input 
                      type="text" 
                      v-model="usuario.fecha_nacimiento"
                      @focus="focus_birthdate = true"
                      @blur="focus_birthdate = false"
                      class="form-control input-custom"
                    />
                    <span class="input-display-icon">
                      <font-awesome-icon 
                        :icon="['fa-regular','calendar']"
                        class="color-main"
                      />
                    </span>  
                    <div 
                      class="calendar-custom"
                      v-if="focus_birthdate"
                    >
                    <div class="calendar-custom-arrow"></div>
                      <v-date-picker 
                        color="blue" 
                        class="date" 
                        :key="key_calendar_birthday" 
                        :min-date="min_date"
                        :max-date="max_date" 
                        @dayclick="onDayClick" 
                        v-model="calendar_birthday" 
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              <font-awesome-icon 
                icon="user" 
                class="color-secondary"
              />
              Datos del colaborador
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Empresa
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="societyArr"
                      label="name"
                      placeholder="Tipos disponibles"
                      v-model="usuario.empresa"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Sucursal
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="workplaceArr"
                      label="name"
                      placeholder="Tipos disponibles"
                      v-model="usuario.sucursal"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Sindicato
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="syndicateArr"
                      label="name"
                      placeholder="Tipos disponibles"
                      v-model="usuario.sindicato"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Área
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="areaArr"
                      label="name"
                      placeholder="Tipos disponibles"
                      v-model="usuario.gerencia"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Cargo
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="positionArr"
                      label="name"
                      placeholder="Tipos disponibles"
                      v-model="usuario.cargo"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Turno
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="shiftArr"
                      label="name"
                      placeholder="Tipos disponibles"
                      v-model="usuario.turno"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">
                      Grupo
                      <span class="color-red">*</span>
                    </label>
                    <v-select
                      class="selvue-custom mb-3"
                      :options="groupArr"
                      label="name"
                      placeholder="Tipos disponibles"
                      v-model="usuario.grupo"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      Anexo
                    </label>
                    <input 
                      type="text" 
                      v-model="usuario.anexo"
                      placeholder="Ej 5689"
                      class="form-control input-custom"
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12 input-display input-display-right">
                    <label class="text-secondary">
                      Fecha de ingreso
                      <span class="color-red">*</span>
                    </label>
                    <input 
                      type="text" 
                      v-model="usuario.fecha_ingreso"
                      @focus="focus_entrydate = true"
                      @blur="focus_entrydate = false"
                      class="form-control input-custom"
                    />
                    <span class="input-display-icon">
                      <font-awesome-icon 
                        :icon="['fa-regular','calendar']"
                        class="color-main"
                      />
                    </span>  
                    <div 
                      class="calendar-custom"
                      v-if="focus_entrydate"
                    >
                    <div class="calendar-custom-arrow"></div>
                      <v-date-picker 
                        color="blue" 
                        class="date" 
                        :key="key_calendar_entrydate" 
                        :min-date="min_date"
                        :max-date="max_date" 
                        @dayclick="onDayClick" 
                        v-model="calendar_entrydate" 
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">
              <font-awesome-icon 
                icon="key" 
                class="color-secondary"
              />
              Permisos
            </h5>
            <div class="card flex-grow-1">
              <div class="card-body">

                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-light border d-flex align-items-center justify-content-between">
                      <label 
                        for="respuestaCorrecta"
                        class="form-check-label link-cursor text-secondary"
                      >
                      Administrador
                      </label>
                      <div class="form-check form-switch">
                        <input 
                          id="respuestaCorrecta"
                          class="form-check-input link-cursor" 
                          type="checkbox" 
                          role="switch"
                          v-model="toggle_switch_btn"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <button 
                v-if="edit_user"
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="goBack(2)"
              >
                Cancelar
              </button>
              <button 
                v-if="!edit_user"
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="goBack()"
              >
                Volver
              </button>
              <button
                v-if="!edit_user"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="validateInputs(1)"
              >
                Agregar
              </button>
              <button
                v-if="edit_user"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="validateInputs(2)"
              >
                Guardar
              </button>
            </div>
          </div>
        </div> 

      </div>
    </div> -->

<!--|||||||||||| FF: MAQUETACIÓN FORMULARIO USUARIOS NO BORRAR ||||||||||||||-->

    <div class="users-add">
      <div class="subtitle">
        <div class="left">
          {{ subtitle }}
        </div>
        <!-- carga masiva -->
        <div
          class="right"
          @click="openbulkLoad()"
          v-if="!open_bulk_load"
        >
          <button>
            <font-awesome-icon class="icon" icon="upload"></font-awesome-icon>
            Carga masiva
          </button>
        </div>
      </div>
      <div class="users-add-container">
        <!-- Formulario Crear Usuario -->
        <form class="users-add-container-form" v-if="!open_bulk_load" onsubmit="return false">
          <!-- Inputs -->
          <div class="form-container form">
            <div class="form-container__input-elem" v-for="(input_,i) in input_validation" :key="i">
              <div class="elem" v-if="input_.active && input_.type == 'text'">
                <label>{{input_.label+''+ (input_.required ? '*':'')}}</label>
                <div class="input-container" :class="input_.error ? 'error' : ''">
                  <input v-if="input_.name != 'user_rut' && input_.name != 'user_email' && (input_.name != 'user_phone' && input_.name != 'user_phone2')" :tabindex=" input_.id" type="text"
                    v-model.trim="input_.value" />
                  <input v-if="input_.name == 'user_rut'" :tabindex=" input_.id"
                    @keyup="handleKeyUpDni(input_.name, $event)" type="text" v-model.trim="input_.value" />
                  <input v-if="input_.name == 'user_email'" :tabindex=" input_.id"
                  @keyup="emailExist(input_.value)" type="text" v-model.trim="input_.value" />
                  <input v-if="input_.name != 'user_rut' && input_.name != 'user_email' && (input_.name =='user_phone' || input_.name == 'user_phone2')" :tabindex=" input_.id" type="text"
                    v-model.trim="input_.value" @keyup="validateNumber(input_.value, i)" />
                </div>
              </div>
              <div class="elem" v-if="input_.active && input_.type == 'select'">
                <label>{{input_.label+''+ (input_.required ? '*':'')}}</label>
                <div class="select-container" :class="input_.error ? 'error' : ''">
                  <v-select label="name" v-model="input_.value" :value="input_.name" :options="input_.value_list" @input="input_.name == 'user_position'? checkNewPosition(input_.name):''">
                  </v-select>
                </div>
              </div>
              <div class="elem" v-if="input_.active && input_.type == 'date'">
                <label v-if="input_.name != 'user_movorg' || (input_.name == 'user_movorg' && edit_user)">{{input_.label+''+ (input_.required ? '*':'')}}</label>
                <div class="input-container birthdate" :class="input_.error ? 'error' : ''"
                  v-if="input_.name == 'user_birthday'" v-click-outside="handleCalendar" @click="openBirthday()">
                  <img class="input-container-img" @click="openBirthday()" :src="img_calendar" alt="" />
                  <input :tabindex="input_.id" type="text" @keypress="handleKeyPressInput" v-model="input_.value" disabled
                    @click="openBirthday()" />
                </div>
                <div class="input-container entrydate" :class="input_.error ? 'error' : ''"
                  v-if="input_.name == 'user_entrydate'" v-click-outside="handleCalendar" @click="openEntrydate()">
                  <img class="input-container-img" @click="openEntrydate()" :src="img_calendar" alt="" />
                  <input :tabindex="input_.id" type="text" @keypress="handleKeyPressInput" v-model="input_.value" disabled
                    @click="openEntrydate()" />
                </div>
                <div class="input-container birthdate" :class="input_.error ? 'error' : ''"
                  v-click-outside="handleCalendar" v-if="input_.name == 'user_movorg' && edit_user" @click="openOrgani()">
                  <img class="input-container-img" @click="openOrgani()" :src="img_calendar" alt="" />
                  <input :tabindex="input_.id" type="text" @keypress="handleKeyPressInput" v-model="input_.value" disabled
                    @click="openOrgani()" />
                </div>
              </div>
            </div>
  
            <div class="form-calendar birthdate" v-if="calendar_class == 'birthdate'">
              <div class="border-arrow">
                <div class="arrow"></div>
              </div>
              <v-date-picker color="blue" class="date" :key="key_calendar_birthday" :min-date="min_date"
                :max-date="max_date" @dayclick="onDayClick" v-model="calendar_birthday" />
            </div>
            <div class="form-calendar entrydate" v-if="calendar_class == 'entrydate'">
              <div class="border-arrow">
                <div class="arrow"></div>
              </div>
              <v-date-picker color="blue" class="date" :key="key_calendar_entrydate" :min-date="min_date"
                :max-date="max_date" @dayclick="onDayClick" v-model="calendar_entrydate" />
            </div>
            <div class="form-calendar moveorg" v-if="calendar_class == 'moveorg'">
              <div class="border-arrow">
                <div class="arrow"></div>
              </div>
              <v-date-picker color="blue" class="date" :key="key_calendar_moveorg" :min-date="min_date"
                :max-date="max_date_moveorg" @dayclick="onDayClick" v-model="calendar_moveorg" />
            </div>
          </div>
        </form>
        <div class="form-info" v-if="!open_bulk_load">(*) Campo obligatorio</div>
        <!-- Submit -->
        <div class="form-container buttons" v-if="!open_bulk_load">
          <div class="left">
            <div class="toggle-switch" :class="toggle_switch_btn && 'active'" @click="handleToggleSwitch">
              <div class="circle"></div>
            </div>
            <span class="text">
              Este usuario tiene permisos de
              <strong> administrador</strong>
            </span>
          </div>
          <div class="right">
            <div class="cancel" v-if="edit_user">
              <button @click="goBack(2)" type="button" class="">Cancelar</button>
            </div>
            <div class="submit">
              <button v-if="!edit_user" class="clean " @click="goBack()" type="button">
                Volver
              </button>
              <button v-if="!edit_user" class="active-btn" @click="validateInputs(1)" type="button">
                Agregar
              </button>
              <button v-if="edit_user" @click="validateInputs(2)" type="button" class="edit_btn active-btn">
                Guardar
              </button>
            </div>
          </div>
        </div>
        <!-- Carga Masiva -->
        <AddBulkLoad v-if="open_bulk_load" :company_id="id_empresa" @close="closeBulkLoad" />
      </div>
      <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="closeStatus" />
      <Spinner v-if="show_spinner" />
      <Question v-if="open_question_modal" :hideCancel="hide_btn_question" :msg="question_modal_msg"
        @cancel="cancelQuestion" @accept="acceptQuestion" />
    </div>
  </section>

</template>

<script>
/* import "@/assets/scss/UsersAdd.scss"; */

import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";
import AddBulkLoad from "./AddBulkLoad.vue";
import { mapActions, mapState } from "vuex";
import {GET_PRIVILEGIOS, EXIST_EMAIL_RUT} from "@/apollo/queries";
import {ADD_USER } from "@/apollo/mutations";
import Question from "../Modales/Question.vue";
import SectionTabs from "../Section/SectionTabs.vue";

export default {
  components: {
    Status,
    Spinner,
    AddBulkLoad,
    Question,
    SectionTabs,
  },
  data() {
    return {
      id_empresa_save: this.$ls.get("user").empresa[0].id_empresa,
      tab_component: [
        {
          name: "Inicio",
          route: "usuarios-list",
        },
        {
          name: "Agregar",
          route: "usuarios-add",
        },
      ],
      subtitle:
        "Añade a un nuevo colaborador o utilizar la carga masiva.",
        workplace_list: [],
      workposition_list: [],
      area_list: [],
      gender_list: [
        {
          id: "F",
          name: "Femenino",
        },
        {
          id: "M",
          name: "Masculino",
        },
      ],
      user: {
        name: "",
        lastname: "",
        dni: "",
        birthdate: "",
        moveorg: "",
        anexo: "",
        phone: "",
        email: "",
        entrydate: "",
        id_usuario: "",
        empresa: "",
        nacion: {
          id: "",
          name: "",
        },
        grupo: {
          id: "",
          name: "",
        },
        sociedad: {
          id: "",
          name: "",
        },
        sindicato: {
          id: "",
          name: "",
        },
        usertype: {
          id: "",
          name: "",
        },
        turno: {
          id: "",
          name: "",
        },
        workplace: {
          id: "",
          name: "",
        },
        area: {
          id: "",
          name: "",
        },
        workposition: {
          id: "",
          name: "",
        },
        gender: {
          id: "",
          name: "",
        },
      },
      user_type_list: [],
      user_orioginal_cargo: "",
      max_date: new Date(),
      open_bulk_load: false,
      open_select_list: false,
      calendar_class: "",
      edit_user: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      show_spinner: false,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      dni_exists: false,
      dni_invalid: false,
      invalid_rut_msg: "",
      open_question_modal: false,
      question_modal_msg: "",
      user_exist_dni: null,
      loading_rut: false,
      email_exists: false,
      loading_email: false,
      hide_btn_question: false,
      toggle_switch_btn: false,
      active_edit_btn: false,
      dynamic_mask: "##.###.###-N",
      min_date: "",
      max_date_moveorg: new Date(),

      key_calendar_birthday: 0,
      key_calendar_entrydate: 0,
      key_calendar_moveorg: 0,

      calendar_birthday: new Date(),
      calendar_entrydate: new Date(),
      calendar_moveorg: new Date(),

      current_user: null,
      old_position: '',
      login_prev_rut: "",
      existing_user: "",
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      input_validation:[
        {
          id:1,
          name:'user_name',
          label:'Nombres',
          value:'',
          required: true,
          active: true,
          type: 'text',
          value_list: [],
          error: false
        },
        {
          id:2,
          name:'user_lastname',
          label:'Apellidos',
          value:'',
          required: true,
          active: true,
          type: 'text',
          value_list: [],
          error:false
        },
        {
          id:3,
          name:'user_rut',
          label:'Rut(Sin puntos y con guion)',
          value:'',
          required: true,
          active: true,
          type: 'text',
          value_list: [],
          error:false
        },
        {
          id:4,
          name:'user_email',
          label:'Correo',
          value:'',
          required: false,
          active: true,
          type: 'text',
          value_list: [],
          error:false
        },
        {
          id:5,
          name:'user_phone',
          label:'Teléfono móvil',
          value:'',
          required: false,
          active: true,
          type: 'text',
          value_list: [],
          error:false
        },
        {
          id:6,
          name:'user_phone2',
          label:'Teléfono fijo',
          value:'',
          required: false,
          active: true,
          type: 'text',
          value_list: [],
          error:false
        },
        {
          id:7,
          name:'user_birthday',
          label:'Fecha de nacimiento',
          value:'',
          required: true,
          active: true,
          type: 'date',
          value_list: [],
          error:false
        },
        {
          id:8,
          name:'user_entrydate',
          label:'Fecha de ingreso',
          value:'',
          required: true,
          active: true,
          type: 'date',
          value_list: [],
          error:false
        },
        {
          id:9,
          name:'user_annex',
          label:'Anexo',
          value:'',
          required: false,
          active: true,
          type: 'text',
          value_list: [],
          error:false
        },
        {
          id:10,
          name:'user_position',
          label:'Cargo',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [],
        },
        {
          id:11,
          name:'user_area',
          label:'Área',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [],
        },
        {
          id:12,
          name:'user_workplace',
          label:'Sucursal',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [],
        },
        {
          id:13,
          name:'user_turn',
          label:'Turno',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [],
        },
        {
          id:14,
          name:'user_society',
          label:'Sociedad',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [],
        },
        {
          id:15,
          name:'user_syndicate',
          label:'Sindicato',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [],
        },
        {
          id:16,
          name:'user_nation',
          label:'Nacionalidad',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [],
        },
        {
          id:17,
          name:'user_group',
          label:'Grupo',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [],
        },
        {
          id:18,
          name:'user_gender',
          label:'Género',
          value:'',
          required: true,
          active: true,
          type: 'select',
          value_list: [{
            id: 1,
            name: "Femenino",
            diminutive:"F"
          },
          {
            id: 2,
            name: "Masculino",
            diminutive:'M'
          },],
          error:false
        },
        {
          id: 19,
          name: 'user_movorg',
          label: 'Fecha Movimiento Org.',
          value: '',
          required: false,
          active: false,
          type: 'date',
          value_list: [],
          error: false
        },
      ],
      toast: {
        message: '',
        type:'',
        duration: 5000,
        position: 'top-right'
      },
      user_id: this.$ls.get("user").id_usuario,
      id_user_edit: 0,

      //FF
      focus_birthdate: false,
      focus_entrydate: false,
      generos: [
        {
          id: 1,
          label: "Femenino",
          value: "Femenino"
        },
        {
          id: 2,
          label: "Masculino",
          value: "Masculino"
        },
      ],
      usuario: {
        id_usuario:"",
        rut_usuario:"",
        dv_usuario:"",
        primer_nombre:"",
        segundo_nombre:"",
        apellido_paterno:"",
        apellido_materno:"",
        correo:"",
        telefono_movil:"",
        anexo:"",
        telefono_fijo:"",
        genero:"",
        imagen_perfil:"",
        fecha_nacimiento:"",
        fecha_ingreso:"",
        empresa: {},
        nacion:{},
        sociedad:{},
        sindicato:{},
        grupo:{},
        cargo:{},
        gerencia:{},
        sucursal:{},
        turno:{},
        privilegio:{},
      },
      usuario_custom: {
        nombres: "",
        apellidos: ""
      }
    };
  },
  computed: {
    ...mapState("usersModule", ["users_list", "positionArr",
      "areaArr",
      "workplaceArr",
      "shiftArr",
      "societyArr",
      "syndicateArr",
      "nationArr",
      "groupArr",
      "user_edit"]),
    // Funcion del plugin del calendario para destacar el dia seleccionado
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  watch: {
    toggle_switch_btn() {
      this.getGrupos();
    },
  },
  async mounted() {
    this.show_spinner = true;
    await this.getCargos();
    this.getAreas();
    this.getSucursales();
    this.getWorkShifts();
    this.getSindicatos();
    this.getGrupos();
    this.getSociedades();
    this.getNaciones();
    this.show_spinner = false;
    var data = {
      empresa: this.id_empresa,
      usuario: this.$ls.get("user").id_usuario,
    };
    this.getUsersList(data); //Funcion de vuex que obtiene lista de usuarios
    if (this.$route.params.id != undefined && this.$route.params.id != null) {
      console.log('editings...');
      this.id_user_edit = this.$route.params.id
      var data_ = {
        id_usuario: this.$route.params.id,
        id_empresa: this.id_empresa,
      }
      await this.getUser(data_);
      this.setInputsData(this.user_edit);
      this.edit_user = true;
      this.open_question_modal = false;
      this.tab_component[1].name = "Editar";
    }
  },
  methods: {
    // Funciones que vienen de vuex
    ...mapActions("usersModule", [
      "getUsersList",
      "editUserAction",
      "addUserAction",
      "getPositionsQuery",
      "getAreasQuery",
      "getWorkplaceQuery",
      "getShiftsQuery",
      "getSocietiesQuery",
      "getSyndicatesQuery",
      "getNationsQuery",
      "getGroupsQuery",
      "editUser",
      "getUser"
    ]),
    validateNumber(num, index){
      //let regex = /^(?=.*?[1-9])[0-9+]+$/;
      if(isNaN(num)){
        this.input_validation[index].error = true;
        this.$toast.open({
          message:'El número de télefono ingresado no es valido.',
          type:'error',
          position:'top-right',
          duration:5000
        })
      }else{
         this.input_validation[index].error = false;
        return true;
      }
    },
    // Obtener usuario para editar
   async checkEmailRut(value, type) {
      let response = true;
      let formated_value = value;
      if(type == 2)formated_value = value.split('.').join('').split('-').join('');
      let data ={
        id_empresa: this.id_empresa,
        objeto:formated_value,
        tipo_objeto:type
      }
      await this.$apollo
        .query({
          query: EXIST_EMAIL_RUT,
          variables: data
        })
        .then((res) => {
          console.log('respuesta ws check email', res);
          if(!res.loading) response = res.data.CheckEmailRut.check;
        })
        .catch((err) => {
          throw err;
        });
      return response;
    },
    validateInputs(type){
      this.input_validation.forEach(input =>{
        if (input.required && input.active) {
          if (input.name == 'user_rut' && input.value != '' && type == 1) {
            this.handleKeyUpDni('user_rut', '')
          } else {
            input.error = input.value == '' ? true : false;
          }
        }
      });
      if (this.input_validation.some(val => val.error)) {
        this.toast = {
          message: 'Por favor completa los campos obligatorios.',
          type: 'error',
          duration: 5000,
          position: 'top-right'
        },
        this.$toast.open(this.toast)
      } else {
        if(type == 1) this.createUser();
        if (type == 2) this.userEdit();
      }
    },
    setInputsData(user) {
      this.old_position = user.cargo[0];
      let formated_rut = user.rut_usuario+''+user.dv_usuario
      if (formated_rut.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
        formated_rut = formated_rut.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
      } else if (formated_rut.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
        formated_rut = formated_rut.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, "$1.$2.$3-$4");
      } else if (formated_rut.match(/^(\d)(\d{3})(\d{0,2})$/)) {
        formated_rut = formated_rut.replace(/^(\d)(\d{3})(\d{0,2})$/, "$1.$2.$3");
      } else if (formated_rut.match(/^(\d)(\d{0,2})$/)) {
        formated_rut = formated_rut.replace(/^(\d)(\d{0,2})$/, "$1.$2");
      }
      let cargo = {
        id: user.cargo[0].id_cargo,
        name:user.cargo[0].nombre_cargo
      }
      let area = {
        id: user.gerencia[0].id_gerencia,
        name: user.gerencia[0].nombre_gerencia
      }
      let sucursal = {
        id: user.sucursal[0].id_sucursal,
        name: user.sucursal[0].nombre_sucursal
      }
      let turno = {
        id: user.turno[0].id_turno,
        name: user.turno[0].nombre_turno
      }
      let sociedad = {
        id: user.sociedad[0].id_sociedad,
        name: user.sociedad[0].nombre_sociedad
      }
      let sindicato = {
        id: user.sindicato[0].id_sindicato,
        name: user.sindicato[0].nombre_sindicato
      }
      let nacionalidad = {
        id: user.nacion[0].id_nacion,
        name: user.nacion[0].nombre_nacion
      }
      let grupo = {
        id: user.grupo[0].id_grupo,
        name: user.grupo[0].nombre_grupo
      }
      let genero = {
        id: user.genero == "M" ? 1:2,
        name: user.genero == "M" ? "Masculino":"Femenino",
        diminutive: user.genero
      }
      this.input_validation[0].value = user.primer_nombre + ' ' + user.segundo_nombre;
      this.input_validation[1].value = user.apellido_paterno + ' ' + user.apellido_materno;
      this.input_validation[2].value = formated_rut;
      this.input_validation[3].value = user.correo;
      this.input_validation[4].value = user.telefono_movil;
      this.input_validation[5].value = user.telefono_fijo;
      this.input_validation[6].value = user.fecha_nacimiento.split("-").reverse().join("-");
      this.input_validation[7].value = user.fecha_ingreso.split("-").reverse().join("-");
      this.input_validation[8].value = user.anexo;
      this.input_validation[9].value = cargo;
      this.input_validation[10].value = area;
      this.input_validation[11].value = sucursal;
      this.input_validation[12].value = turno;
      this.input_validation[13].value = sociedad;
      this.input_validation[14].value = sindicato;
      this.input_validation[15].value = nacionalidad;
      this.input_validation[16].value = grupo;
      this.input_validation[17].value = genero;
      this.toggle_switch_btn = user.privilegio[0].id_tipo == '3' ? false : true;
    },
    // Obtener tipos de usuarios
    getUserType() {
      this.$apollo
        .query({
          query: GET_PRIVILEGIOS,
        })
        .then((res) => {
          var resp = res.data.privilegios;
          for (let i = 0; i < resp.length; i++) {
            this.user_type_list.push(resp[i]);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // Obtener cargos
    async getCargos() {
    let input_label = 'Cargo';
    let found_index = this.input_validation.findIndex(element => element.label == input_label);
     let data = {
      id_empresa:this.id_empresa
    };
     await this.getPositionsQuery(data);
     this.input_validation[found_index].value_list = this.positionArr;
    },
    // Obtener areas
    async getAreas() {
      let input_label = 'Área';
      let found_index = this.input_validation.findIndex(element => element.label == input_label);
      let data = {
        id_empresa: this.id_empresa
      };
     await this.getAreasQuery(data);
     this.input_validation[found_index].value_list = this.areaArr;
     //console.log('AREAS_', this.areaArr);
    },
    // Obtener sucursales
    async getSucursales() {
      let input_label = 'Sucursal';
      let found_index = this.input_validation.findIndex(element => element.label == input_label);
      let data = {
        id_empresa: this.id_empresa
      };
      await this.getWorkplaceQuery(data);
      this.input_validation[found_index].value_list = this.workplaceArr;
      //console.log('WORKPLACE_', this.workplaceArr);
    },
    // obtener sindicatos
    async getSindicatos() {
      let input_label = 'Sindicato';
      let found_index = this.input_validation.findIndex(element => element.label == input_label);
      let data = {
        id_empresa: this.id_empresa
      };
      await this.getSyndicatesQuery(data);
      this.input_validation[found_index].value_list = this.syndicateArr;
      //console.log('SINDICATO', this.syndicateArr);
    },
    // obtener grupos
    async getGrupos() {
      
      //var id_privilegio = this.$ls.get("user").privilegio[0].id_tipo;
      // Si el usuario que hace la carga remota es fundador no tendra
      // acceso a los grupos por lo que se cambia a 'Admnistrador'
      // para poder acceder a este dato
      var tipo_privilegio = this.toggle_switch_btn ? "2" : "3";
      ////console.log(this.toggle_switch_btn);
      let input_label = 'Grupo';
      let found_index = this.input_validation.findIndex(element => element.label == input_label);
      let data = {
        id_tipo_privilegio: tipo_privilegio,
        id_empresa: this.id_empresa,
      };
      await this.getGroupsQuery(data);
      this.input_validation[found_index].value_list = this.groupArr;
     //console.log('GRUPOS_', this.groupArr);
    },
    // obtener sociedades
    async getSociedades() {
      let input_label = 'Sociedad';
      let found_index = this.input_validation.findIndex(element => element.label == input_label);
      let data = {
        id_empresa: this.id_empresa
      };
      await this.getSocietiesQuery(data);
     this.input_validation[found_index].value_list = this.societyArr;
       //console.log('SOCIETIES_', this.societyArr);
    },
    // obtener naciones
    async getNaciones() {
      let input_label = 'Nacionalidad';
      let found_index = this.input_validation.findIndex(element => element.label == input_label);
      let data = {
        id_empresa: this.id_empresa
      };
      await this.getNationsQuery(data);
      this.input_validation[found_index].value_list = this.nationArr;
      //console.log('NATIONS_', this.nationArr);
    },
    // Obtener turnos
    async getWorkShifts() {
      let input_label = 'Turno';
      let found_index = this.input_validation.findIndex(element => element.label == input_label);
      let data = {
        id_empresa: this.id_empresa
      };
      await this.getShiftsQuery(data);
      this.input_validation[found_index].value_list = this.shiftArr;
      //console.log('TURNOS_', this.shiftArr);
    },
    // Funcion para formatear fechas
    handleDate(date) {
      var p = date.split(/\D/g);
      return [p[2], p[1], p[0]].join("-");
    },
    // Abrir sección carga masiva
    openbulkLoad() {
      this.open_bulk_load = true;
      this.subtitle = "Lee las instrucciones antes de comenzar la carga masiva";
    },
    // Cerrar sección carga masiva
    closeBulkLoad() {
      this.open_bulk_load = false;
      this.subtitle = "(*) Campo obligatorio";
    },
    // Funcion para abrir y cerrar listas de selects
    toggleSelect(selected) {
      if (selected == this.open_select_list) {
        this.open_select_list = false;
      } else {
        this.open_select_list = selected;
      }
    },
    // Funcion para permitir solo numeros en el input de telefono
    handleNumberInputs(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // Funcion para seleccionar fechas
    onDayClick(day) {
      if (this.calendar_class == "birthdate") {
        let name_ = 'user_birthday';
        let found_index = this.input_validation.findIndex(element => element.name == name_);
        //console.log('fi', found_index);
        this.input_validation[found_index].value = day.id.split("-").reverse().join("-");
      }
      if (this.calendar_class == "entrydate") {
        let name_ = 'user_entrydate';
       
        let found_index = this.input_validation.findIndex(element => element.name == name_);
        //console.log('ed', found_index);
        this.input_validation[found_index].value = day.id.split("-").reverse().join("-");
      }
      if (this.calendar_class == "moveorg") {
        let name_ = 'user_movorg';
        let found_index = this.input_validation.findIndex(element => element.name == name_);
        //console.log('ed', found_index);
        this.input_validation[found_index].value = day.id.split("-").reverse().join("-");
      }
      this.calendar_class = "";
    },
    // Funcion para abrir calendario fecha de nacimiento
    openBirthday() {
      setTimeout(() => {
        this.calendar_class = "birthdate";
        if (this.edit_user) {
          var date_split = this.user.birthdate.split("-");
          var date = new Date(
            parseInt(date_split[2]),
            parseInt(date_split[1]) - 1,
            parseInt(date_split[0])
          );
          this.calendar_birthday = date;
        }
        this.key_calendar_birthday = this.key_calendar + 1;
      }, 100);
    },
    // Funcion para abrir calendario fecha de ingreso
    openEntrydate() {
      setTimeout(() => {
        this.calendar_class = "entrydate";
        if (this.edit_user) {
          var date_split = this.user.entrydate.split("-");
          var date = new Date(
            parseInt(date_split[2]),
            parseInt(date_split[1]) - 1,
            parseInt(date_split[0])
          );
          this.calendar_entrydate = date;
        }
        this.key_calendar_entrydate = this.key_calendar_entrydate + 1;
      }, 100);
    },
    // Funcion para abrir calendario fecha de movimiento
    openOrgani() {
      setTimeout(() => {
        this.calendar_class = "moveorg";
        if (this.edit_user) {
          var date_split = this.user.moveorg.split("-");
          var date = new Date(
            parseInt(date_split[2]),
            parseInt(date_split[1]) - 1,
            parseInt(date_split[0])
          );
          this.calendar_moveorg = date;
        }
        this.key_calendar_moveorg = this.key_calendar_moveorg + 1;
      }, 100);
    },
    // Funcion para que no puedan escribir en el input
    handleKeyPressInput(e) {
      e.preventDefault();
      return false;
    },
    // Editar usuario
    async userEdit() {
      this.show_spinner = true;
      await this.editUser(this.getUserData(2));
      this.show_spinner = false;
      this.modal_status_msg = "Usuario editado con éxito.";
      this.$toast.open({
        message: 'Usuario editado con éxito.',
        type: 'success',
        duration: 5000,
        position:'top-right'
      })
      this.$router.push({name:'usuarios-list'})
    },
    // Crear usuario
    createUser() {
      // var birthdate = this.user.birthdate;
      // var entrydate = this.user.entrydate;
      // var grupo = this.user.grupo.id == "" ? "0" : this.user.grupo.id;

      // if (this.validateFormatDate(this.user.birthdate)) {
      //   birthdate = this.user.birthdate.split("-").reverse().join("-");
      // }
      // if (this.validateFormatDate(this.user.entrydate)) {
      //   entrydate = this.user.entrydate.split("-").reverse().join("-");
      // }
      // Si el tipo de usuario/privilegio es igual a " " quiere decir que es colaborador
      
      // Si el rut viene con puntos y guion
      this.show_spinner = true;
      this.$apollo
        .mutate({
          mutation: ADD_USER,
          variables: this.getUserData(1),
        })
        .then((res) => {
          var user = res.data.addUsuario;
          //console.log("createUser", user);
          this.show_spinner = false;

          if (user.rut_usuario.includes("Existe")) {
            this.open_question_modal = true;
            this.question_modal_msg =
              "El usuario que intentas agregar ya existe. ¿Deseas editarlo?";
            this.existing_user = user.id_usuario;
          } else {
            // this.modal_status_msg = "Usuario creado con éxito";
            // this.open_modal_status = true;
            this.$toast.open({
              message: 'Usuario agregado exitosamente.',
              type: 'success',
              duration: 5000,
              position: 'top-right'
            });
            this.cleanInputs();
          }
        })
        .catch(() => {
          this.show_spinner = false;
          this.modal_status_msg =
            "El usuario no ha sido creado, intenta nuevamente";
          this.open_modal_status = true;
          this.modal_status = false;
        });
    },
    // Funcion para cerrar modal Status
    getUserData(type) {
      if (this.user.usertype.id == "") {
        this.user.usertype.id = "3";
      }
      let data = {
        primer_nombre: this.input_validation[0].value.split(" ")[0],
        segundo_nombre: this.input_validation[0].value.split(" ").length > 1
          ? this.input_validation[0].value.split(" ")[1]
          : "",
        apellido_paterno: this.input_validation[1].value.split(" ")[0],
        apellido_materno:
          this.input_validation[1].value.split(" ").length > 1
            ? this.input_validation[1].value.split(" ")[1]
            : "",
        rut_usuario: this.input_validation[2].value.split(".").join("").split("-")[0],
        dv_usuario: this.input_validation[2].value.split(".").join("").split("-")[1],
        correo: this.input_validation[3].value,
        telefono_movil: this.input_validation[4].value,
        telefono_fijo: this.input_validation[5].value,
        fecha_nacimiento: this.input_validation[6].value.split("-").reverse().join("-"),
        fecha_ingreso: this.input_validation[7].value.split("-").reverse().join("-"),
        anexo: this.input_validation[8].value,
        id_cargo_fk: this.input_validation[9].value.id,
        id_gerencia_fk: this.input_validation[10].value.id,
        id_sucursal_fk: this.input_validation[11].value.id,
        id_turno_fk: this.input_validation[12].value.id,
        id_nacion_fk: this.input_validation[13].value.id,
        id_sociedad_fk: this.input_validation[14].value.id,
        id_sindicato_fk: this.input_validation[15].value.id,
        id_grupo_fk: this.input_validation[16].value.id,
        id_creador: this.user_id,
        id_empresa_fk: this.id_empresa,
        genero: this.input_validation[16].value.id,
        id_privilegio_fk: this.user.usertype.id,
        entity_id: "0",
      }
      if (type == 2) {
        data.id_usuario = this.id_user_edit,
        data.id_modificador = this.user_id,
        data.fecha_movimiento = this.input_validation[18].value != '' ? this.input_validation[18].value.split("-").reverse().join("-") :''
      }
      return data;
    },
    goBack(type) {
      this.open_question_modal = true;
      if (type == 1) {
        this.question_modal_msg = '¿Estás seguro que deseas volver? Se perderán los datos ingresados.';
      } else {
        this.question_modal_msg = '¿Estás seguro que deseas cancelar la edición? Se perderán los cambios realizados.';
      }
    },
    // Funcion para validar que fecha tenga formato dd-mm-yyyy
    validateFormatDate(date) {
      var reg =
        /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
      if (date.match(reg)) {
        return true;
      } else {
        return false;
      }
    },
    async emailExist(mail) {
      console.log('checking email', mail);
      let name_ = 'user_email';
      let found_index = this.input_validation.findIndex(element => element.name == name_);

      if(await this.checkEmailRut(mail, 1)){
        this.input_validation[found_index].error = true;
        this.$toast.open({
          message: 'El correo ingresado no es valido o ya existe.',
          type: 'error',
          duration: 5000,
          position: 'top-right'
        });
      }else{
        this.input_validation[found_index].error = false;
      }
    },
    // Fucnion para cerrar modal de pregunta (usar rut para editar)
    cancelQuestion() {
      this.open_question_modal = false;
      this.user.dni = "";
      this.$router
        .push({
          name: "usuarios-list",
        })
        .catch(() => {
          return;
        });
    },
    // Funcion para aceptar abrir id de usuario en editar
    acceptQuestion() {
      this.open_question_modal = false;
      this.$router
        .push({
          name: "usuarios-list",
        })
    },

    // Funcion para seleccionar tipo de usuario
    handleToggleSwitch() {
      if (this.toggle_switch_btn) {
        this.toggle_switch_btn = false;
        this.user.usertype = {
          id: this.user_type_list[2].id_tipo,
          name: this.user_type_list[2].nombre_tipo,
        };
        if (this.edit_user) {
          this.active_edit_btn =
            this.user.usertype.id !== this.current_user.privilegio[0].id_tipo
              ? true
              : false;
        }
      } else {
        this.toggle_switch_btn = true;
        this.user.usertype = {
          id: this.user_type_list[1].id_tipo,
          name: this.user_type_list[1].nombre_tipo,
        };
        if (this.edit_user) {
          this.active_edit_btn =
            this.user.usertype.id !== this.current_user.privilegio[0].id_tipo
              ? true
              : false;
        }
      }
      this.user.grupo.name = "Selecciona un grupo";
      this.user.grupo.id = "";
    },
    // Funcion para 'activar' boton de crear usuario
   
    cleanUser() {
      this.user = {
        name: "",
        lastname: "",
        dni: "",
        birthdate: "",
        anexo: "",
        phone: "",
        phone_fijo: "",
        email: "",
        entrydate: "",
        id_usuario: "",
        nacion: {
          id: "",
          name: "",
        },
        grupo: {
          id: "",
          name: "",
        },
        sociedad: {
          id: "",
          name: "",
        },
        sindicato: {
          id: "",
          name: "",
        },
        usertype: {
          id: "",
          name: "",
        },
        turno: {
          id: "",
          name: "",
        },
        workplace: {
          id: "",
          name: "",
        },
        area: {
          id: "",
          name: "",
        },
        workposition: {
          id: "",
          name: "",
        },
        gender: {
          id: "",
          name: "",
        },
      };
    },
    // funcion para manejar el cambio en el input de rut cuando se edita
    handleDniChange() {
      var current_rut = this.user.dni;

      if (this.user.dni.includes(".")) {
        current_rut = this.user.dni.split(".").join("");
        this.validRut(current_rut);
      }
      if (this.edit_user) {
        if (this.user.dni.includes(".") && this.user.dni.includes("-")) {
          current_rut = this.user.dni.split(".").join("");
          current_rut = this.user.dni.split("-").join("");
        }
        var rut_usuario =
          this.current_user.rut_usuario + this.current_user.dv_usuario;

        this.active_edit_btn = current_rut !== rut_usuario ? true : false;
      }
    },
    // Funcion para formatear rut y agregar puntos y guion
    async handleKeyUpDni(name, e) {
      let found_index = this.input_validation.findIndex(element => element.name == name);
      let value = this.input_validation[found_index].value.replace(/\./g, "").replace("-", "");
      //console.log('keycode', e.keyCode)
      if (e.keyCode != 8 || e == '') {
        if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
          value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
        } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
          value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, "$1.$2.$3-$4");
        } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
          value = value.replace(/^(\d)(\d{3})(\d{0,2})$/, "$1.$2.$3");
        } else if (value.match(/^(\d)(\d{0,2})$/)) {
          value = value.replace(/^(\d)(\d{0,2})$/, "$1.$2");
        }
        if (value.length > 10 && value.length < 13) {
          let rut_raw = value.split(".").join("");
          if (!this.rutValidation(rut_raw)) {
            this.input_validation[found_index].error = true;
            this.$toast.open({message: 'El RUT ingresado no es valido o ya existe.',
              type: 'error',
              duration: 5000,
              position: 'top-right'});
          } else {
            const checkInput = await this.checkEmailRut(rut_raw, 2);
            if(checkInput){
              this.input_validation[found_index].error = true;
              this.$toast.open({message: 'El RUT ingresado no es valido o ya existe.',
              type: 'error',
              duration: 5000,
              position: 'top-right'});
            }else{
              this.input_validation[found_index].error = false;
            }
          }
        }
        if (value.length > 12) {
          this.input_validation[found_index].error = true;
          this.toast = {
            message: 'El RUT ingresado no es valido o ya existe.',
            type: 'error',
            duration: 5000,
            position: 'top-right'
          }
        }
        this.input_validation[found_index].value = value;
      }
    },
    // funcion para validar rut chileno
    rutValidation(fullRut) {
      if (!/^[0-9]+-[0-9kK]{1}$/.test(fullRut))
        return false;
      var tmp = fullRut.split('-');
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == 'K') digv = 'k';
      return (this.dvValidation(rut) == digv);
    },
    dvValidation(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
      return S ? S - 1 : 'k';
    },
    handleDniBackspace() {
      this.loading_rut = false;
      this.dni_exists = false;
      this.dni_invalid = false;
    },
    handleCalendar() {
      if (this.calendar_class != "") {
        this.calendar_class = "";
      }
    },
    cleanInputs() {
      this.input_validation.forEach(input => {
        input.value = '';
        input.error = false;
      })
    },
    openQuestion() {
      this.open_question_modal = true;
    },
    closeStatus() {
      this.open_modal_status = false;
    },
    checkNewPosition(name) {
      console.log('POSITION NAME', name);
      let found_index = this.input_validation.findIndex(element => element.name == name);
      let position = this.input_validation[found_index];
      if (position.id != this.old_position.id_cargo) {
        this.input_validation[18].active = true;
      } else {
        this.input_validation[18].active = false;
      }
    }
  },
};
</script>
