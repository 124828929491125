import { apolloClient } from "../../../vue-apollo";
import {
    GET_ESTADISTICAS,
    GET_INICIO_EMPRESA,
    TOP_NOTICIAS,
    TOP_GALERIAS,
    ESTADISTICAS_GENERALES_MODULOS,
    GET_DATA_EXCEL_ESTADISTICAS_TOTAL,
    GET_FILTROS_ESTADISTICAS,
    GET_GRAFICO_USUARIO_SESION,
    GET_DATA_EXCEL_HOME,
    GET_YEAR_DASHBOARD
} from "@/apollo/queries";

export default {
    namespaced: true,

    state: {
        statisticsModule: {},
        dashboardEmpresa: {},
        usuario: {},
        topNews: [],
        topGallery: [],
        generalStatistics: [],
        filters: [],
        usability: [],
        searchUsability: "",
        percentageUsers: [],
        dataExcel: [],
        year_user: [],
        dataExcelTotal: []
    },
    getters: {
        getDataFilterPerMonth(state) {
            if (state.searchUsability === "") {
                return state.usability.find((datos) => datos.is_current);
            } else {
                return state.usability.find(
                    (datos) => datos.month === state.searchUsability.substring(0, 3)
                );
            }
        },
    },
    mutations: {
        STATISTICS_MODULE(state, data) {
            state.statisticsModule = data;
        },
        DASHBOARD_EMPRESA(state, data) {
            state.dashboardEmpresa = data;
        },
        TOP_NEWS(state, data) {
            if (data.length > 5) {
                state.topNews = data.slice(0, 5);
            } else {
                state.topNews = data;
            }
        },
        TOP_GALLERY(state, data) {
            if (data.length > 5) {
                state.topGallery = data.slice(0, 5);
            } else {
                state.topGallery = data;
            }
        },
        GENERAL_STATISTICS(state, data) {
            state.generalStatistics = data;
        },
        FILTERS_STATISTICS(state, data) {
            state.filters = data;
        },
        FILTER_USABILIY(state, data) {
            state.usability = data;
        },
        SET_SEARCH(state, newSearch) {
            state.searchUsability = newSearch;
            console.log(newSearch);
        },
        SET_DATA_EXCEL(state, data) {
            state.dataExcel = data;
        },
        SET_YEAR_DASHBOARD(state, data){
            state.year_user = data;
        },
        SET_DATA_TOTAL_EXCEL(state, data){
            state.dataExcelTotal = data;
        }

    },

    actions: {
        async getStatisticsModule({ commit }, data_) {
            let response = [];
            await apolloClient.query({
                query: GET_ESTADISTICAS,
                variables: data_,
                fetchPolicy: 'network-only'
            }).then(res => {
                console.log('ESTADISTICAS_GENERALES_MODULOS', res);
                if (!res.loading) response = res.data.ModuloEstadisticaInd;
            });
            commit("STATISTICS_MODULE", response);
        },
        async getDashboardEmpresa({ commit }, params) {
            const { data } = await apolloClient.query({
                query: GET_INICIO_EMPRESA,
                variables: {
                    id_empresa: params.idEmpresa,
                },
            });
            commit("DASHBOARD_EMPRESA", data.DashboardEmpresa);
        },
        async getTopNews({ commit }, params) {
            const { data } = await apolloClient.query({
                query: TOP_NOTICIAS,
                variables: {
                    idEmpresa: params.idEmpresa,
                    idUsuario: params.idUsuario,
                },
            });
            commit("TOP_NEWS", data.noticiasTopVistas);
        },
        async getTopGallery({ commit }, params) {
            const { data } = await apolloClient.query({
                query: TOP_GALERIAS,
                variables: {
                    idEmpresa: params.idEmpresa,
                    idUsuario: params.idUsuario,
                },
            });
            commit("TOP_GALLERY", data.galeriasTop);
        },
        async getGeneralStatistics({ commit }, params) {
            const { data } = await apolloClient.query({
                query: ESTADISTICAS_GENERALES_MODULOS,
                variables: {
                    idEmpresa: params.idEmpresa,
                },
            });
            commit("GENERAL_STATISTICS", data.EstadisticasGeneralesModulos);
        },
        async getFiltersStatistics({ commit }) {
            const { data } = await apolloClient.query({
                query: GET_FILTROS_ESTADISTICAS,
                variables: {},
            });
            commit("FILTERS_STATISTICS", data.GetFiltrosEstadisticas);
        },
        async getGraficoUsuariosSesion({ commit }, params) {
            const { data } = await apolloClient.query({
                query: GET_GRAFICO_USUARIO_SESION,
                variables: {
                    idFiltro: params.id_filtro,
                    idEmpresa: params.idEmpresa,
                    fechaInicio: params.fecha_inicio,
                    fechaFin: params.fecha_fin,
                },
            });
            commit("FILTER_USABILIY", data.GetGraficoUsuariosSesion.datos_grafico);
        },
        setSearchUsability({ commit }, newSearch) {
            commit("SET_SEARCH", newSearch);
        },
        async getDataForExcel({ commit }, data_) {
            let response = [];
            await apolloClient.query({
                query: GET_DATA_EXCEL_HOME,
                variables: data_,
                cachepolicy: 'network-only'
            }).then(res => {
                console.log('EXCEL DATA', res);
                response = res.data.ListUsuariosSesion
            });
            commit("SET_DATA_EXCEL", response);
        },
        
        async getDataForExcelTotal({ commit }, data_){
            let response = {};
            let data = {};
            let params = {
                query: GET_DATA_EXCEL_ESTADISTICAS_TOTAL,
                variables: data_
            }
            try {
                await apolloClient.query(params).then(res => {
                if (!res.loading) data = res.data.MetricasExcel;
                response = data;
                //console.log(data);
                });
            } catch(e) {
                console.log("listar getDataForExcelTotal falla: ", e);
                response = null;
            }
            return new Promise((resolve)=>{
                if(response  != null){
                    commit("SET_DATA_TOTAL_EXCEL", response);
                    resolve(response)
                } else {
                    resolve(null)
                }
            });
        },
        //Metodo para solicitar las fechas asociadas a la empresa 
        async getYearDashboard({ commit }, data_){
            let response = {};
            let data = {};
            let params = {
                query: GET_YEAR_DASHBOARD,
                variables: data_
            }
            try {
                await apolloClient.query(params).then(res => {
                if (!res.loading) data = res.data.YearsDashboard;
                response = data;
                //console.log(data);
                });
            } catch(e) {
                console.log("listar getYearDashboard falla: ", e);
                response = null;
            }
            return new Promise((resolve)=>{
                if(response  != null){
                    commit("SET_YEAR_DASHBOARD", response);
                    resolve(response)
                } else {
                    resolve(null)
                }
            });
        }
    },
};