import { apolloClient } from "../../../vue-apollo";

import {
  GET_CANALES,
  GET_ESTADO_CANALES,
  GET_LIST_SCREENS,
  GET_SCREENS_REGISTERED,
  GET_ONE_SCREENS,
  GET_LIST_CHANNELS_OPT,
  GET_TIMEZONES
} from "../../../apollo/queries";
import {
  CANAL_ADD,
  CANAL_UPDATE,
  CANAL_DELETE,
  VALIDATE_SCREEN_DISP,
  CREATE_SCREEN,
  UPDATE_SCREEN,
  DELETE_SCREEN,
  CHANGE_SCREEN_STATE
} from "../../../apollo/mutations";
export default {
  namespaced: true,
  state: {
    validationDisp: {},
    newSreen: {},
    CantidadPantallasAdmin: {
      pantallas_contrato: "0",
      registradas: "0",
      no_registradas: "0",
      offline: "0",
      online: "0",
    },

    online_data: [
      {
        nombre: "Nombre pantalla",
        ubicacion: "Comedor, seccion 5",
        canal: "Canal 01 Oficinas GE",
        ultima_conexion: "17/03/2022, 10:00 AM",
      },
    ],

    offline_data: [
      {
        nombre: "Nombre pantalla",
        ubicacion: "Comedor, seccion 5",
        canal: "Canal 01 Oficinas GE",
        ultima_conexion: "17/03/2022, 10:00 AM",
      },
    ],
    GetCanalesOptions: [],
    
    GetTimezones: [],

    GetPantallaList: [],
    
    GetListCanales: [],

    GetEstadoCanalesFiltro: []
  },
  getters: {
    getScreenById: (state) => (id) => {
      const { GetPantallaList } = state;
      const screen = GetPantallaList.find((screen) => screen.id_pantalla == id);
      return screen;
    },
    getChannelById: (state) => (id) => {
      const { GetListCanales } = state;
      const channel = GetListCanales.find((channel) => channel.id_canal == id);
      return channel;
    },
    areThereScreensAvailable(state) {
      return !(state.CantidadPantallasAdmin.pantallas_contrato === state.CantidadPantallasAdmin.registradas);
    },
  },
  mutations: {
    SET_CANALES(state, canales){
      state.GetListCanales = canales;
    },
    SET_ESTADO_CANALES_FILTRO(state, estados){
      state.GetEstadoCanalesFiltro = estados;
    },

    SET_NEW_CANAL(state, canal){
      state.GetListCanales.unshift(canal);
    },
    editCanalList(state, canal) {
      console.log('CANAL', canal);
      console.log('CANAL LIST', state.GetListCanales);
      let canal_index = state.GetListCanales.findIndex(canal_ => canal_.id_canal == canal.id_canal);
      if (canal_index != undefined) {
          console.log('CANAL Encontrador', state.GetListCanales[canal_index]);
          if (state.GetListCanales.length > 0) Object.assign(state.GetListCanales[canal_index], canal)
      }
    },
    deleteCanalLista(state, data) {
      console.log('SE BORRA DE LA LISTA', data);
      console.log('CANAL LIST', state.GetListCanales);
      let found_index = state.GetListCanales.findIndex(canal => canal.id_canal == data.id_canal);
      console.log('CANAL ENCONTRADO', found_index);
      state.GetListCanales.splice(found_index, 1);
    },
    setPantallas(state, resp) {
      state.GetPantallaList = resp;
    },
    setPantallaOne(state, resp) {
      state.newSreen = resp;
    },
    setCanalesOptions(state, resp) {
      if (state.GetCanalesOptions.length < 1) {
          state.GetCanalesOptions = resp;
      }
    },
    setTimezonesOptions(state, resp) {
      if (state.GetTimezones.length < 1) {
          state.GetTimezones = resp;
      }
    },
    setCantidadPantallas(state, resp) {
      if (parseInt(state.CantidadPantallasAdmin.registradas) < 1) {
          state.CantidadPantallasAdmin = resp;
      }
    },
    setValidationData(state, resp) {
      if (Object.keys(state.validationDisp).length < 1) {
        state.validationDisp = resp;
      }
    },
    setNewScreen(state, resp) {
      console.log(state.GetPantallaList)
      state.CantidadPantallasAdmin.registradas = parseInt(state.CantidadPantallasAdmin.registradas) + 1;
      state.GetPantallaList.push(resp);
      state.GetPantallaList.sort((a, b) => parseInt(b.id_pantalla) - parseInt(a.id_pantalla));
    },
    setUpdateScreen(state, resp) {
      let index = state.GetPantallaList.findIndex(screen => parseInt(screen.id_pantalla) == parseInt(resp.id_pantalla));
      if (index > -1) {
          if (state.GetPantallaList.length > 0) Object.assign(state.GetPantallaList[index], resp)

      }
    },
    setDeleteScreen(state, resp) {
      let found_index = state.GetPantallaList.findIndex(screen => screen.id_pantalla == resp.id_pantalla);
      state.CantidadPantallasAdmin.registradas = parseInt(state.CantidadPantallasAdmin.registradas) - 1;
      state.GetPantallaList.splice(found_index, 1);
    },
  },
  actions: {

    async getCanales({ commit }, data_) {
        let response = [];
        let params = {
            query: GET_CANALES,
            variables: data_
        }

        apolloClient.subscribe(params).subscribe(
          (res) => {
            console.log("respuest de server: ", res);
            if (!res.loading) response = res.data.GetListCanales;
            commit("SET_CANALES", response);
          },
          (e) => {
            console.log("Falla error, getCanales ", e);
          });

        // return new Promise((resolve)=>{
        //   if(response!=null){
        //     if (response.length==0){
        //       resolve(false);
        //     }
        //     else {
        //       resolve(response);
        //       commit("SET_CANALES", response);
        //     }
        //   }
        //   else {
        //     resolve(false);
        //   }
          
        // });
        
        
    },
    async getEstadoCanales({ commit }){
      let response = [];
      let params = {
          query: GET_ESTADO_CANALES
      }
      try {
        await apolloClient.query(params).then(res => {
          //console.log("respuest de server getEstadoCanales: ", res);
            if (!res.loading) response = res.data.EstadoCanales;
        });
        commit("SET_ESTADO_CANALES_FILTRO", response);
      }
      catch(e){
        console.log("Error metodo getEstadoCanales", e);
      }
      
    },

    async addCanal({ commit }, data_) {
      console.log('data para el crear');
      let response = null;
      let data = [];
      let params = {
          mutation: CANAL_ADD,
          variables: data_
      }
      try{
        await apolloClient.mutate(params).then(res => {
            console.log('SET_NEW_CANAL', res);
            if (!res.loading) data = res.data.SaveCanal;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo addCanal: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          resolve(response);
          commit("SET_NEW_CANAL", response);
        }
        else {
          resolve(false);
        }
        
      });
      
    },
    async editCanal({ commit }, data_) {
      console.log('data para el edit', data_);
      let response = null;
      let data = [];
      let params = {
          mutation: CANAL_UPDATE,
          variables: data_
      }
      try{
        await apolloClient.mutate(params).then(res => {
          console.log('editCanal', res);
          if (!res.loading) data = res.data.UpdateCanal;
          response = data;
        });
      }
      catch(e){
        console.log("Falla metodo editCanal: ", e);
        //console.log("error fatal: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          resolve(response);
        }
        else {
          resolve(false);
        }
        commit("editCanalList", response);
      });
      
      
    },
    async deleteCanal({ commit }, data_) {
      console.log('data para el delete');
      let response = null;
      let data = [];
      let params = {
          mutation: CANAL_DELETE,
          variables: data_
      }
      try
      {
        await apolloClient.mutate(params).then(res => {
            //console.log('deleteCanal', res);
            if (!res.loading) data = res.data.DeleteCanal;
            response = data;
        });
      }
      catch(e){
        console.log("Error de deleteCanal canal: ", e);
      }
      return new Promise((resolve)=>{
          if(response!=null){
            console.log("DELETE CANAL ",response);
            if(response.id_canal !=0 || response.id_canal != null){
              commit("deleteCanalLista", response);
            }
            resolve(response);
          }
          else {
            resolve(false);
          }
          
      }); 
      
    },
    async GetPantallaListAction({ commit }, data_) {
      let response = [];
      let params = {
          query: GET_LIST_SCREENS,
          variables: data_
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.GetPantallaList;
        });
      } catch(e) {
        console.log("response de listar pantallas: ", e);
        response = null;
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response);
          commit("setPantallas", response);
        } else {
          resolve(null);
        }
      });
    },

    async GetOnePantallaAction({ commit },data_) {
      let response = [];
      let params = {
          query: GET_ONE_SCREENS,
          variables: data_,
          fetchPolicy: 'network-only',
      };
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.GetPantallaOne;
        });
      } catch (e) {
        console.log("response de buscar pantalla: ", e);
        response = null;
      }
      return new Promise((resolve)=>{
        if(response  != null){
          console.log("pasa a distinto de null")
          resolve(response);
          commit("setPantallaOne", response)
        } else {
          resolve(null);
        }
      });
    },

    async GetCantidadPantallasAction({ commit }, data_) {
      let response = {};
      let params = {
          query: GET_SCREENS_REGISTERED,
          variables: data_
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.CantidadPantallasAdmin;
        });
      } catch(e) {
        console.log("problemas al revisar los datos iniciales de pantalla, ", e);
        response = null;
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response);
          commit("setCantidadPantallas", response);
        } else {
          resolve(null);
        }
      });
    },

    async ValidateScreenDispAction({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
          mutation: VALIDATE_SCREEN_DISP,
          variables: data_
      }
      try {
        await apolloClient.mutate(params).then(res => {
          console.log(res);
          if (!res.loading) data = res.data.ValidarDispositivo;
          response = data;
        });
      } catch (e) {
        console.log("validar codigo falla: ", e);
        response = null;
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response);
          commit("setValidationData", response);
        } else {
          resolve(null);
        }
      });
    },

    async GetListChannelsActions({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
        query: GET_LIST_CHANNELS_OPT,
        variables: data_
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.GetListCanales;
          response = data;
        });
      } catch(e) {
        console.log("listar canales falla: ", e);
        response = null
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response)
          commit("setCanalesOptions", response);
        } else {
          resolve(null)
        }
      });
      

    },

    async GetTimezonesActions({ commit }) {
      let response = {};
      let data = {};
      let params = {
        query: GET_TIMEZONES,
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.GetZonasHorarias;
          response = data;
        });
      } catch(e) {
        console.log("listar zonas horarias falla: ", e);
        response = null
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response)
          commit("setTimezonesOptions", response);
        } else {
          resolve(null)
        }
      });
    },

    //mutaciones
    async SaveScreenAction({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
          mutation: CREATE_SCREEN,
          variables: data_
      }
      try {
        await apolloClient.mutate(params).then(res => {
          if (!res.loading) {
            data = res.data.SavePantalla;
          }
          response = data;
        });
      } catch(e) {
        console.log("guardar pantalla falla: ", e);
        response = null
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response);
          commit("setNewScreen", response);
        } else {
          resolve(null);
        }
      });
    },
    async UpdateScreenAction({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
          mutation: UPDATE_SCREEN,
          variables: data_
      }
      try {
        await apolloClient.mutate(params).then(res => {
          if (!res.loading) data = res.data.UpdatePantalla;
          response = data;
        });
      } catch(e) {
        console.log("editar pantalla falla: ", e);
        response = null;
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response);
          commit("setUpdateScreen", response);
        } else {
          resolve(null);
        }
      });
    },

    async DeleteScreenAction({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
          mutation: DELETE_SCREEN,
          variables: data_
      }
      try {
        await apolloClient.mutate(params).then(res => {
          console.log(res);
          if (!res.loading) data = res.data.DeletePantalla;
          response = data;
        });
      } catch(e) {
        console.log("eliminar pantalla falla: ", e);
        response = null;
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response);
          commit("setDeleteScreen", response);
        } else {
          resolve(null);
        }
      });
    },
    async RejectScreenSaving({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
          mutation: CHANGE_SCREEN_STATE,
          variables: data_
      }
      try {
        await apolloClient.mutate(params).then(res => {
          console.log(res);
          if (!res.loading) data = res.data.UpdateEstadoDispositivo;
          response = data;
        });
      } catch (e) {
        console.log("rechazar pantalla falla: ", e);
        response = null;
      }
      return new Promise((resolve)=>{
        if(response  != null){
          resolve(response);
          commit("setValidationData", response);
        } else {
          resolve(null)
        }
      });
    },

  },
};
