const alertPushList = [{
    path: "/alerta-push",
    name: "Push",
    component: (AlertaPush) => require(["@/views/ADMIN/AlertaPush.vue"], AlertaPush),
    redirect: { name: "push-list" },
    children: [{
            path: "list",
            name: "push-list",
            component: (AlertaPushList) =>
                require(["@/components/AlertaPush/ListFilter.vue"], AlertaPushList),
        },
        {
            path: "add",
            name: "push-add",
            component: (AlertaPushAdd) =>
                require(["@/components/AlertaPush/Add.vue"], AlertaPushAdd),
        },
        {
            path: "edit/:id",
            name: "push-edit",
            component: (AlertaPushAdd) =>
                require(["@/components/AlertaPush/Add.vue"], AlertaPushAdd),
        },
    ],
}, ];

export default {
    alertPushList,
};