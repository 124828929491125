<template>
  <div id="app">
    <main id="main" class="main" v-if="(this.$ls.get('user') !== null)"> 
      <SideBar/>
      <section class="wrapper-container">
        <NavBar :title="tituloModulo"/>
        <router-view/>
        <FooterSection/>
      </section>
    </main>
    <main class="main-login" v-if="(this.$ls.get('user') === null)">
      <router-view/>
    </main>
  </div>
</template>

<script>
  import "regenerator-runtime";
  import { mapActions, mapState, mapGetters } from "vuex";
  import SideBar from "@/components/SideBar.vue";
  import NavBar from "@/components/NavBar.vue";
  import FooterSection from "@/components/Section/SectionFooter.vue";
  import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";

  export default {
    components: {
      SideBar,
      NavBar,
      FooterSection
    },
    data() {
      return {
        data: "",
        colorPrimario: this.$ls.get("user")?.empresa[0].color_primario,
        colorSecundario: this.$ls.get("user")?.empresa[0].color_secundario,
        idEmpresa: this.$ls.get("user")?.empresa[0].id_empresa,
        idPrivilegio: this.$ls.get("user")?.privilegio[0].id_tipo,

        //FF
        currentRoute: window.location.href,
        moduleRoute: window.location.pathname,
        tituloModulo: ""
      };
    },
    watch: {
      $route: {
        handler: function (val) {
          this.checkMyLogin(val);
        },
        immediate: true,
      },
      moduleRoute() {
        this.getNombreModulo();
      }
    },
    updated() {
      this.currentRoute = window.location.href;
      this.moduleRoute = window.location.pathname;
    },
    
    computed: {
      ...mapState("sidebar", ["modules"]),
      ...mapGetters("sidebar", [ "userHasOnlyOneModule"]),
    },

    created() {
      this.cargarLocalStorage();
    },

    async mounted() {
      const data = {
        id_empresa: this.idEmpresa,
        id_privilegio: this.idPrivilegio
      };
      await this.getModules(data);
      await this.getNombreModulo();
    }, 
    methods: {
      ...mapActions("encuestasModule", ["cargarLocalStorage"]),
      ...mapActions("sidebar", ["getModules"]),

      //FF
      getNombreModulo() {
        let modulo = this.moduleRoute.split('/', 2);
        let pathModulo = "/" + modulo[1];
        const found = this.modules.find(element => element.moduloGeneral.ruta_admin === pathModulo);
        this.tituloModulo = found.moduloGeneral.nombre_modulo
      }
    },
  };

</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  input:focus-visible {
    outline: none;
  }
  input[type="text"] {
    font-size: 15px;
  }
  input[type="number"] {
    color: #6B6E7F;
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }
  .tippy-popper {
    .tippy-tooltip {
      background: #555555;
      .tippy-arrow {
        border-top-color: #555555;
      }
      .tippy-content {
        letter-spacing: 0.7px;
        font-weight: 600;
        color: white;
      }
    }
  }
}
/* #app {
  font-family: "Product Sans Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #999ba6;
  height: 100%;
} */
</style>
