const birthdayList = [
  {
    path: "/birthdays",
    name: "Birthday",
    component: Birthday => require(["@/views/ADMIN/Birthday.vue"], Birthday),
  },
];
export default {
  birthdayList,
};
