const organigramaList = [
  {
    path: "/organigrama",
    name: "Organigrama",
    component: (Organigrama) =>
      require(["@/views/ADMIN/Organigrama.vue"], Organigrama),
    redirect: { name: "org-list" },
    children: [
      {
        path: "list",
        name: "org-list",
        component: (OrgList) =>
          require(["@/components/Organigrama/List.vue"], OrgList),
      },
      {
        path: "add",
        name: "org-add",
        component: (OrgAdd) =>
          require(["@/components/Organigrama/Add.vue"], OrgAdd),
      },
      {
        path: "edit/:id",
        name: "org-edit",
        props: true,
        component: (OrgAdd) =>
          require(["@/components/Organigrama/Add.vue"], OrgAdd),
      },
      {
        path: "empresas",
        name: "org-empresas",
        component: (OrgEmpresas) =>
          require(["@/components/Organigrama/OrganigramaEmpresas.vue"], OrgEmpresas),
      },
    ],
  },
];

export default {
  organigramaList,
};
