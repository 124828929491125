const seguridadList = [
  {
    path: "/seguridad",
    name: "Seguridad",
    component: (Seguridad) =>
      require(["@/views/ADMIN/Seguridad.vue"], Seguridad),
    redirect: { name: "seguridad-list" },
    children: [
      {
        path: "list",
        name: "seguridad-list",
        component: (SeguridadList) =>
          require(["@/components/Seguridad/List.vue"], SeguridadList),
      },
      {
        path: "add",
        name: "seguridad-add",
        component: (SeguridadAdd) =>
          require(["@/components/Seguridad/Add.vue"], SeguridadAdd),
      },
      {
        path: "edit/:id",
        name: "seguridad-edit",
        component: (SeguridadAdd) =>
          require(["@/components/Seguridad/Add.vue"], SeguridadAdd),
      },
      {
        path: "add-categories",
        name: "seguridad-add-categories",
        component: (SeguridadAddCat) =>
          require([
            "@/components/Seguridad/AddCategories.vue",
          ], SeguridadAddCat),
      },
    ],
  },
];

export default {
  seguridadList,
};
