const galleryList = [
  {
    path: "/galeria",
    name: "Galeria",
    component: (Gallery) => require(["@/views/ADMIN/Gallery.vue"], Gallery),
    redirect: { name: "gallery-list" },
    children: [
      {
        path: "list",
        name: "gallery-list",
        component: (GalleryList) =>
          require(["@/components/Gallery/List.vue"], GalleryList),
      },
      {
        path: "add",
        name: "gallery-add",
        component: (GalleryAdd) =>
          require(["@/components/Gallery/Add.vue"], GalleryAdd),
      },
      {
        path: "edit/:id",
        name: "gallery-edit",
        component: (GalleryAdd) =>
          require(["@/components/Gallery/Add.vue"], GalleryAdd),
      },
    ],
  },
];


export default {
  galleryList,
};