const newsList = [
  {
    path: "/noticias",
    name: "Noticias",
    component: (News) => require(["@/views/ADMIN/News.vue"], News),
    redirect: { name: "news-list" },
    children: [
      {
        path: "list",
        name: "news-list",
        component: (NewsList) =>
          require(["@/components/News/List.vue"], NewsList),
      },
      {
        path: "userlist",
        name: "intra-news-list",
        component: (NewsUserList) =>
          require(["@/components/News/UserList.vue"], NewsUserList),
      },
      {
        path: "add",
        name: "news-add",
        component: (NewsAdd) => require(["@/components/News/Add.vue"], NewsAdd),
      },
      {
        path: "edit/:id",
        name: "news-edit",
        component: (NewsAdd) => require(["@/components/News/Add.vue"], NewsAdd),
      },
      {
        path: "add-categories/",
        name: "news-categories",
        component: (NewsAdd) => require(["@/components/News/AddCategories.vue"], NewsAdd),
      },
    ],
  },
  {
    path: "/intra-noticias",
    name: "IntraNoticias",
    component: (NewsUserList) =>
      require(["@/components/News/UserList.vue"], NewsUserList),
  },
];

export default {
  newsList,
};
