<template>
  <div class="users-add-container-bulk-load">
    <Spinner v-if="show_spinner" />
    <div class="download">
      <a href="/files/plantilla_carga_masiva.xlsx" download><button>Descargar Plantilla</button></a>
    </div>
    <div class="bulk-load">
      <div class="bulk-load-container">
        <label for="bulk-load" v-show="bulk_load_file_name == ''">
          <input id="bulk-load" type="file" @change="onChange" accept=".xlsx, .xls" style="display: none" />
          <xlsx-read :file="excel_file">
            <xlsx-json>
              <template #default="{collection}">
                <div style="display:none">
                  {{ getExcelData(collection)}}
                </div>
              </template>
            </xlsx-json>
          </xlsx-read>
          <div class="plus-icon">
            <span class="icon">
              <font-awesome-icon icon="plus-circle" />
            </span>
            <span>
              Haz clic aquí para cargar un archivo desde tu computador
            </span>
          </div>
        </label>
        <div class="file-preview" v-if="bulk_load_file_name !== ''">
          <div class="file-name">{{ bulk_load_file_name }}</div>
          <div class="delete-preview">
            <button @click="deletePreviewFile()">
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bulk-load-list" v-if="excel_data_res.length > 0">
      <div class="header">
        <div class="header-nombre">Nombre</div>
        <div class="header-rut">RUT</div>
        <div class="header-correo">
          Estado
        </div>
        <div class="header-result">
          Acción
        </div>
      </div>
      <div class="boxrow">
        <div class="row" v-for="ex in excel_data_res" :key="ex.rut">
          <div class="row-nombre">
            <p>
              {{ `${ex.primer_nombre} ${ex.apellido_paterno}` }}
            </p>
          </div>
          <div class="row-rut">{{ (ex.rut_usuario != '' && ex.rut_usuario != undefined) && (ex.dv_usuario != '' &&
          ex.dv_usuario!= undefined) ? ex.rut_usuario+'-'+ex.dv_usuario:'-'}}</div>
          <div class="row-correo">
            {{ ex.action == "in database" ? 'Existente': ex.action == 'field-error' ? 'Error: Campos inválidos': '' }}
          </div>
          <div class="row-result">
            <p :class="ex.result">
              {{
              ex.action == "in database"
              ? "Actualizar"
              : ex.action == "updated"
              ? "Actualizado"
              : ex.action == "field-error" ?
              "-":''
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bulk-load-buttons">
      <button class="cancel" @click="closeBulkLoad()">Cancelar</button>
      <button class="create" :class="active_save_btn && 'active_save'" @click="validateExcelData('add')"
        v-if="excel_data_res.length == 0">
        Agregar Usuarios
      </button>
      <button class="create" :class="active_save_btn && 'active_save'" @click="addBulkLoad('update')"
        v-if="excel_data_res.length != 0">
        Actualizar datos
      </button>
    </div>
    <!-- Modal para mostrar instrucciones de Carga Masiva-->
    <Instructions v-if="false" />
    <!-- Modal para mostrar resultados de Carga Masiva -->
    <!-- Modal pregunta -->
    <Question v-if="open_question_modal" :msg="question_modal_msg" :hideCancel="false" @cancel="cancelQuestion"
      @accept="acceptQuestion" />
    <!-- Spinner -->

    <!--  Modal que muestra el estado de la carga masiva (exito o error) -->
    <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="closeStatus" />
  </div>
</template>

<script>
import Instructions from "../Modales/Instructions.vue";
import Question from "../Modales/Question.vue";
// import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
// import XlsxJson from "vue-xlsx/dist/components/XlsxJson";
import Spinner from "../Spinner.vue";
import Status from "../Modales/Status.vue";
import { ADD_BULK_LOAD, EDIT_BULK_LOAD } from "@/apollo/mutations";



export default {
  components: {
    Instructions,
    Question,
    Spinner,
    Status,
  },
  props: {
    company_id: { require: true },
  },
  data() {
    return {
      show_modal_resp: true,
      open_instructions_modal: false,
      bulk_load_resp: [],
      user_fields: [
        {id:1,
          name:'RUT',
          required: true,
          value_list: [],
          var_name:'rut_usuario',
        },
        {id:2,
          name:'Primer_nombre',
          required: true,
          value_list: [],
          var_name:'primer_nombre',
        },
        {id:3,
        name:'Segundo_nombre',
        required: false,
        value_list: [],
        var_name:'segundo_nombre',
        },
        {id:4,
        name:'Apellido_paterno',
        required:true,value_list:[],var_name:'apellido_paterno',},
        {id:5,
        name:'Apellido_materno',
        required:false,value_list:[],var_name:'apellido_materno',},
        {id:6,
        name:'Correo',
        required:false,value_list:[],var_name:'correo',},
        {id:7,
        name:'Telefono_fijo',
        required:false,value_list:[],var_name:'telefono_fijo',},
        {id:8,
        name:'Telefono_movil',
        required:false,value_list:[],var_name:'telefono_movil',},
        {id:9,
        name:'Anexo',
        required:false,value_list:[],var_name:'anexo',},
        {id:10,
        name:'Fecha_nacimiento',
        required:true,value_list:[],var_name:'fecha_nacimiento',},
        {id:11,
        name:'Fecha_ingreso',
        required:true,value_list:[],var_name:'fecha_ingreso',},
        {id:12,
        name:'Cargo',
        required:true,value_list:[],var_name:'cargo',},
        {id:13,
        name:'Gerencia',
        required:true,value_list:[],var_name:'gerencia',},
        {id:14,
        name:'Sucursal',
        required:true,value_list:[],var_name:'sucursal',},
        {id:15,
        name:'Turno',
        required:false,value_list:[],var_name:'turno',},
        {id:16,
        name:'Sociedad',
        required:true,value_list:[],var_name:'sociedad',},
        {id:17,
        name:'Sindicato',
        required:false,value_list:[],var_name:'sindicato',},
        {id:18,
        name:'Nacionalidad',
        required:true,value_list:[],var_name:'nacion',},
        {id:19,
        name:'Genero',
        required:true,value_list:[],var_name:'genero',},
        {id:19,
        name:'Privilegio',
        required:false,value_list:[],var_name:'privilegio',},
        {id:19,
          name:'Grupo',
          required: false, 
          value_list: [],
          var_name:'grupo',
        },
        {
          id:20,
          name: 'DV-RUT',
          required: false,
          value_list: [],
          var_name:'dv_usuario'
        },
        {
          id:21,
          name: 'Entity-ID',
          required: false,
          value_list: [],
          var_name:'entity_id'
        },
      ],
      open_question_modal: false,
      question_modal_msg: "",
      show_spinner: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      id_creador: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      active_save_btn: false,
      excel_file: null,
      excel_data : [],
      excel_data_res:[],
      excel_data_err:[],
      loaded_data: false,
      bulk_load_file_name:''
    };
  },
  mounted() {

  },
  methods: {
     getExcelData(data){
      console.log('data excel',data);
      this.excel_data = data;
      if (data && !this.loaded_data){
        if(data.length > 0){
          this.show_spinner = false;
          this.excel_data.forEach(data =>{
            data.error = false;
          });
          this.loaded_data = true;
          console.log('Documento excel', this.excel_data);
          this.$toast.open({
            message: 'Documento cargado correctamente.',
            type: 'success',
            duration: 6000,
            position: 'top-right'
          });
        }else{
          this.$toast.open({
            message:'Ha ocurrido un error al cargar el documento, intentalo nuevamente.',
            type:'error',
            duration:6000,
            position:'top-right'
          })
        }
      }
    },  
    onChange(event){
      this.excel_file = event.target.files ? event.target.files[0] : null;
      console.log(this.excel_file);
      this.bulk_load_file_name = this.excel_file.name;
      this.show_spinner = true;
    },
    validateExcelData(type) {
      console.log('loaded_data', this.loaded_data);
      this.show_spinner = true;
      if(this.loaded_data){
        this.user_fields.forEach(field => {
        let data_arr = [];
        for (const user in this.excel_data) {
          let user_data = this.excel_data[user];
          console.log('user DEL FOR IN', user_data);
          let value = '';
          if (Object.hasOwnProperty.call(user_data, field.name)) {
            console.log(user+', Existe el campo: '+field.name);
            console.log(user+', usuario: ',user_data);
             value = user_data[field.name];
            if (field.name == 'RUT') {
              console.log(user_data[field.name]);
              let rut_ = user_data[field.name].toString().slice(0, -1).replace(/[^A-Z0-9]/ig, "");
              let dv_rut = user_data[field.name].toString().slice(-1);
              this.user_fields[21].value_list.push(dv_rut);
              value = rut_
            }
            if (field.name == 'Fecha_ingreso' || field.name == 'Fecha_nacimiento') value = this.ExcelDateToJSDate(user_data[field.name])
            if (field.name == 'Telefono_fijo' || field.name == 'Telefono_movil' || field.name == 'Anexo') value = user_data[field.name].toString();
          }else{
            value = '';
            if(field.required){
              console.log(user+',No Existe el campo y es requerido: '+field.name);
              let error_data = {
                id: (user + 1),
                action: 'field-error',
                apellido_paterno: user_data.Apellido_paterno,
                id_privilegio: 'Colaborador',
                primer_nombre: user_data.Primer_nombre,
                result: '-',
              }
              let res_found = this.excel_data_res.find(res => (res.id == error_data.id));
              if(res_found == undefined){
                this.excel_data_res.push(error_data);
              }
              console.log('BUSCANDO COINCIDENCIA DE ERROR', res_found);
              console.log('EN ERROR REQUERIDO USER:', user)
              let err_index_ = user;
              this.excel_data_err.push(err_index_);
            }
            if (field.name == 'Privilegio') value = 'Colaborador';
            if (field.name == 'Grupo') value = 'Colaboradores internos';
            if (field.name == 'DV-RUT') value = field.value_list[user];
            if(field.name == 'Entity-ID') value = '0';
          }
            data_arr.push(value)
        }
        field.value_list = data_arr;
        });
        let index_rem = [...new Set(this.excel_data_err)];
        console.log('index rem', index_rem.sort((a,b)=>b-a));
        this.user_fields.forEach(field =>{
          for (let i = 0; i < index_rem.length; i++) {
            field.value_list.splice(index_rem[i],1);  
          }
        })
      console.log('res data', this.excel_data_res);
      console.log('user field', this.user_fields);
        this.addBulkLoad(type);
      }else{
        this.show_spinner = false;
        this.$toast.open({
          message:'Carga un archivo desde tu computador para continuar.',
          type:'error',
          duration:6000,
          position:'top-right'
        })
      }
     
    },
    action(ac) {
      if (ac == "in database") {
        ac = "Existente";
      }
      return ac;
    },
    deletePreviewFile() {
      this.bulk_load_file_name = "";
      this.excelData = [];
      this.bulk_load_resp = [];
      this.loaded_data = false;
    },
    // Cerrar modal de resultados de carga masiva
    closeBulkLoadModal() {
      if (this.bulk_load_exception.length == 0) {
        this.bulk_load_resp = [];
        this.bulk_load_file_name = "";
        this.bulk_load_array = [];
        this.$router.push({ name: "usuarios-list", params: { reload: true } });
      }
    },
    // Funcion que formatea la fecha que sale al convertir el excel en array
    ExcelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      var fractional_day = serial - Math.floor(serial) + 0.0000001;
      var total_seconds = Math.floor(86400 * fractional_day);
      var seconds = total_seconds % 60;
      total_seconds -= seconds;
      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;
      var date = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate() + 1,
        hours,
        minutes,
        seconds
      );
      var day = date.getDate();
      if (day < 10 && day > 0) {
        day = "0" + day;
      }
      var month = date.getMonth() + 1;
      if (month < 10 && month > 0) {
        month = "0" + month;
      }
      var year = date.getFullYear();

      return year + "-" + month + "-" + day;
    },
    // Funcion para guardar datos de la plantilla en un array
    addData(data) {
      // Por cada objeto dentro de 'data'
      data.forEach((obj) => {
        this.bulk_load_array.push(obj);
        // Debe recorrer arr con las propiedades 'obligtorias'
        if (Object.keys(obj).length < 18) {
          this.open_question_modal = true;
          this.question_modal_msg =
            "Los usuarios que no posean todos <br/> los campos obligatorios, no serán cargados <br/> ¿Deseas continuar?";
          return;
        } else {
          this.handleObj(obj);
        }
      });
    },
    // Funcion para ver si tiene los items obligatorios
    handleObj(obj) {
      for (let i = 0; i < this.required_items.length; i++) {
        //verificar que el obj tenga todos los campos obligatorios
        if (
          {}.propertyIsEnumerable.call(obj, this.required_items[i]) &&
          Object.keys(obj).length == 18
        ) {
          // si tiene todas las propiedades obligatorias y no existe en el array, se agrega
          if (!this.bulk_load_array.includes(obj)) {
            this.bulk_load_array.push(obj);
          }
        }
      }
    },
    // Cancelar carga masiva y cerrar modal de pregunta
    cancelQuestion() {
      this.open_question_modal = false;
    },
    // Modal para aceptar pregunta (continuar con carga masiva si hay items con error o sin llenar) de modal de Pregunta
    acceptQuestion() {
      this.open_question_modal = false;
    },
    // Cerrar sección carga masiva
    closeBulkLoad() {
      this.$emit("close");
    },
    // Cerrar modal Status
    closeStatus() {
      this.open_modal_status = false;
      this.active_save_btn = false;
    },
    // Funcion que ejecuta la carga masiva
    addBulkLoad(type) {
      let data_for_bulkload = {
        rut_usuario: [],
        dv_usuario: [],
        primer_nombre: [],
        segundo_nombre: [],
        apellido_materno: [],
        apellido_paterno: [],
        correo: [],
        telefono_movil: [],
        anexo: [],
        telefono_fijo: [],
        fecha_nacimiento: [],
        fecha_ingreso: [],
        genero: [],
        cargo: [],
        gerencia: [],
        sucursal: [],
        turno: [],
        nacion: [],
        sociedad: [],
        sindicato: [],
        privilegio: [],
        grupo: [],
        id_creador: this.id_creador,
        id_empresa_fk: this.id_empresa,
        entity_id: [],
      }
      this.user_fields.forEach(field => {
        if (Object.hasOwnProperty.call(data_for_bulkload, field.var_name)) {
          data_for_bulkload[field.var_name] = field.value_list;
        }
      });
      if(type=='add'){
        this.$apollo
        .mutate({
          mutation: ADD_BULK_LOAD,
          variables: data_for_bulkload,
        })
        .then((res) => {
          console.log('RESPUESTA ADD BULK', res);
            this.show_spinner = false;
          let resp = res.data.cargaMasivaUpdated;
          if (resp.length > 0) {
            for (let i = 0; i < resp.length; i++) {
              this.excel_data_res.push(resp[i]);
            }
          }
          console.log('excel_res', this.excel_data_res);
        })
        .catch((err) => {
          this.show_spinner = false;
          throw err;
        });
      }
      if (type == 'update') {
        if (this.loaded_data) {
          this.$apollo
            .mutate({
              mutation: EDIT_BULK_LOAD,
              variables: data_for_bulkload,
            })
            .then((res) => {
              console.log('RESPUESTA EDIT BULK', res);
              this.show_spinner = false;
              let resp = res.data.updateNominaMasiva;
              this.excel_data_res = [];
              if (resp.length > 0) {
                for (let i = 0; i < resp.length; i++) {
                  this.excel_data_res.push(resp[i]);
                }
              }
            })
            .catch((err) => {
              this.show_spinner = false;
              throw err;
            });  
        }
      }
    },
  },
};
</script>
