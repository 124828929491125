import { VIDEOS_CATEGORIAS } from "@/apollo/queries";
import { apolloClient } from "../../../vue-apollo";

export default {
   namespaced: true,

   state: { 
    video_categories_actions_list: [], 
    video_categories_action: {},
    video_categories: []
  },

  mutations: {
    addVideoCatAct(state, res) {
       state.video_categories_actions_list.push(res);
     },
     addVideoCatAllAct(state, res) {
       state.video_categories_action = res;
     },
     getVideoCategories(state, categories) {
        state.video_categories = categories;
     }
   },

   actions: {
    async getVideoCategoriesAction({ commit }, payload) {
      const params = {
        query: VIDEOS_CATEGORIAS,
        variables: payload
      };

      let response = null;
      try {
        const res = await apolloClient.query(params)
        if (!res.loading) response = res.data.categoriasVideos;
        commit("getVideoCategories", response);
      } catch (e) {
        console.log("response de obtener categorías de videos: ", e);
      }

      return response;
    },

     // Añadir acciones de muro
     async addVideoCatAction({ commit }, action) {
       //valores
       //{ action: "accion", data: objeto }
       commit("addVideoCatAct", action);
     },
     async addVideoCatAllAction({ commit }, action) {
       //muro
       commit("addVideoCatAllAct", action);
     },
   },
 };
 