<template>
  <footer class="footer-container">
    <div class="made-with">
      Creado con
      <img :src="heart" alt="" class="heart" />
      por &nbsp; <strong>CARPETRES</strong>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      heart: require("@/assets/img/corazon.svg"),
    };
  },
  methods: {
  },
};
</script>