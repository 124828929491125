const profileList = [
  {
    path: "/perfil",
    name: "Perfil",
    component: (Profile) => require(["@/views/Profile.vue"], Profile),
  },
];
export default {
  profileList,
};
