import { GET_POSTS_PAGINATION, GET_POSTS_BY_PAGE, GET_WALL_PROFILES } from "@/apollo/queries";
import { WALL_STATE, WALL_ADD, WALL_ADD_PROFILE, WALL_EDIT_PROFILE, WALL_DELETE_PROFILE, WALL_EDIT } from "@/apollo/mutations";
import { apolloClient } from "../../../vue-apollo";
export default {
    namespaced: true,
    state: {
        pagination_data: [],
        paginated_posts: [],
        profile_list: [],
    },
    getters: {
        getPage: (state) => (page_id) => {
            let page = [];
            let found_page = state.pagination_data.find(pagination => pagination.pagina === page_id.toString());
            if (found_page != undefined) page = found_page.muros;
            return page;
        },
        getPostById: (state) => (post_id) => {
            console.log('lista de posts', state.paginated_posts);
            let post_ = {};
            let found_post = state.paginated_posts.find(post => post.id_muro === post_id);
            console.log('post getter', found_post);
            if (found_post != undefined) post_ = found_post;
            return post_;
        }
    },
    mutations: {
        DELETE_POST(state, post_) {
            let index_post = state.paginated_posts.findIndex(
                post => post.id_muro == post_.id_muro
            );
            if (index_post != -1) {
                state.paginated_posts.splice(index_post, 1);
                state.pagination_data.splice(index_post, 1);
            }
        },
        DISABLE_POST(state, post_) {
            let index_post = state.paginated_posts.findIndex(
                post => post.id_muro == post_.id_muro
            );
            if (index_post != -1) {
                let changed_post = state.paginated_posts[index_post];
                changed_post.id_estado_fk = post_.id_estado_fk;
                Object.assign(state.paginated_posts[index_post], changed_post)
            }
        },
        EDIT_POST(state, post) {
            let index = state.paginated_posts.findIndex(
                e => e.id_muro == post.id_muro
            );
            if (post.id_muro && index != -1) {
                state.paginated_posts[index] = post;
                //console.log("data pusheada: ", state.paginated_posts[index]);
            }
        },
        ADD_POST(state, post) {
            state.paginated_posts.unshift(post);
            state.pagination_data.unshift(post.id_muro);
            state.paginated_posts.splice((state.paginated_posts.length - 1), 1);
            state.pagination_data.splice((state.pagination_data.length - 1), 1);
        },
        ADD_PROFILE(state, profile) {
            state.profile_list.unshift(profile);
        },
        UPDATE_PROFILE(state, profile_) {
            let index_profile = state.profile_list.findIndex(
                profile => profile.id_perfil == profile_.id_perfil
            );
            if (index_profile != -1) {
                console.log(state.profile_list[index_profile]);
                Object.assign(state.profile_list[index_profile], profile_)
            }
        },
        REMOVE_PROFILE(state, id_profile_) {
            let index_profile = state.profile_list.findIndex(
                profile => profile.id_perfil == id_profile_
            );
            if (index_profile != -1) {
                state.profile_list.splice(index_profile, 1);
            }
        },
        SET_PROFILE_LIST(state, profiles) {
            state.profile_list = profiles;
        },
        SET_PAGINATION(state, posts) {
            state.pagination_data = posts;
        },
        SET_PAGINATED_POST(state, posts) {
            state.paginated_posts = posts;
        },
    },
    actions: {
        async getPostsPagination({ commit }, data_) {
            let response = null;
            let params = {
                query: GET_POSTS_PAGINATION,
                variables: data_,
                fetchPolicy: 'network-only'
            };
            await apolloClient.query(params).then(res => {
                if (!res.loading) response = res.data.TotalPaginasPublicacionesMuro;
                if (response != null) {
                    commit('SET_PAGINATION', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async getAllPostsByPage({ commit }, data_) {
            let response = null;
            let params = {
                query: GET_POSTS_BY_PAGE,
                variables: data_,
                fetchPolicy: 'network-only'
            };
            await apolloClient.query(params).then(res => {
                if (!res.loading) response = res.data.PublicacionesMuroPaginas;
                if (response != null) {
                    commit('SET_PAGINATED_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async getProfiles({ commit }, data_) {
            let response = null;
            let params = {
                query: GET_WALL_PROFILES,
                variables: data_,
                fetchPolicy: 'network-only'
            };
            await apolloClient.query(params).then(res => {
                console.log('Perfiles', res);
                if (!res.loading) response = res.data.PerfilesListAll;
                if (response != null) {
                    commit('SET_PROFILE_LIST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async deletePost({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_STATE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                if (!res.loading) response = res.data.ChangeEstadoMuro;
                if (response != null) {
                    commit('DELETE_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async disablePost({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_STATE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('disabled', res);
                if (!res.loading) response = res.data.ChangeEstadoMuro;
                if (response != null) {
                    commit('DISABLE_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async addPost({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_ADD,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('add post', res);
                if (!res.loading) response = res.data.crearMuroAdmin;
                if (response != null) {
                    commit('ADD_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async editPost({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_EDIT,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('edit post', res.data.editarMuroAdmin);
                if (!res.loading) response = res.data.editarMuroAdmin;
                if (response != null) {
                    commit('EDIT_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async addProfile({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_ADD_PROFILE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('add profile', res);
                if (!res.loading) response = res.data.CreatePerfil;
                if (response != null) {
                    commit('ADD_PROFILE', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async editProfile({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_EDIT_PROFILE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                if (!res.loading) response = res.data.UpdatePerfilMuro;
                if (response != null) {
                    commit('UPDATE_PROFILE', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async removeProfile({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_DELETE_PROFILE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('respuesta servidor', res);
                if (!res.loading) response = res.data.DeletePerfilMuro.id_perfil;
                console.log('delete profile', response);
                if (response != null) {
                    commit('REMOVE_PROFILE', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        }
    },
};