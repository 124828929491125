import { apolloClient } from "../../../vue-apollo";
import { 
	GET_GALERIES,
	PAGINAS_GALERIAS,
	LIST_PAGINADA_GALERIA
} from "../../../apollo/queries";
import { DELETE_GALERIA, EDIT_GALERIA, CREATE_GALERIA } from "../../../apollo/mutations";

export default {
	namespaced: true,
	state: { album_list: [], paginas_galeria: [] },
	mutations: {
		getGalleries(state, galleries) {
			state.album_list = galleries;
		},
		addGallery(state, gallery) {
			state.album_list.unshift(gallery);
		},
		deleteGallery(state, id) {
			// se guarda la nueva data en el array que se usa para mostrar las galeria
			var index = state.album_list.findIndex((album) => album.id_galeria == id);
			state.album_list.splice(index, 1);
		},
		editGallery(state, editedGallery) {
			delete editedGallery.id_empresa;
			delete editedGallery.fecha_inicio;
			delete editedGallery.fecha_final;
			delete editedGallery.nombre;
			// obtiene index del obj album
			var index = state.album_list.findIndex(
				(el) => el.id_galeria == editedGallery.id_galeria
			);
			state.album_list.splice(index, 1, editedGallery);
		},

		SET_PAGINAS_GALERIA(state, paginas){
			state.paginas_galeria = paginas;
		}
	},
	actions: {
		// Este metodo ya no está siendo ocupado (DEPRECADO)
		async getGalleriesList({ commit }, payload) {
			const params = {
				query: GET_GALERIES,
				variables: payload,
			}

			let response = null;
			try {
				let data = null;
				await apolloClient.query(params).then(res => {
					if (!res.loading) data = res.data.galeriasAdmin;
					response = data;
				});
			} catch (e) {
				console.log("response de get galería: ", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					console.log('getGalleriesList response:', response);
					commit("getGalleries", response);
				} else {
					resolve(false);
				}
			});
		},

		async addGalleryAction({ commit }, payload) {
			const params = {
				mutation: CREATE_GALERIA,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.crearGaleria;
					response = data;
				});
			} catch (e) {
				console.log("response de crear galería: ", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					console.log('addGalleryAction response:', response);
					commit("addGallery", response);
				} else {
					resolve(false);
				}
			});
		},

		async deleteGalleryAction({ commit }, payload) {
			const params = {
				mutation: DELETE_GALERIA,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.eliminarGaleria;
					response = data;
				});
			} catch (e) {
				console.log("response de eliminar galería: ", e);
				console.log(e.networkError.result.errors);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					console.log('deleteGalleryAction response:', response);
					commit("deleteGallery", response.id_galeria);
				} else {
					resolve(false);
				}
			});
		},

		async editGalleryAction({ commit }, payload) {
			const params = {
				mutation: EDIT_GALERIA,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.editarGaleria;
					response = data;
				});
			} catch (e) {
				console.log("response de editar galería: ", e);
				console.log(e.networkError.result.errors);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					console.log('editGalleryAction response:', response);
					commit("editGallery", response);
				} else {
					resolve(false);
				}
			});
		},

		// Metodo para capturar las paginas de galería
		async getPaginasGaleria({ commit }, data_){
			// seguir con el paginated
			let response = null;
			let params = {
				query: PAGINAS_GALERIAS,
				variables: data_,
				fetchPolicy: 'network-only'
			}
			try {
			await apolloClient.query(params).then(res => {
				//console.log("respuest de server getPaginacionSolicitudes: ", res.data.PaginasGaleriaAdmin);
				if (!res.loading) response = res.data.PaginasGaleriaAdmin;
			});
			}
			catch(e){
			console.log("Falla error, getPaginasGaleria ", e);
			//return false
			}
			return new Promise((resolve)=>{
			if(response!=null){
				commit("SET_PAGINAS_GALERIA", response);
				resolve(response);
			}
			else{
				resolve(false);
			}
			});
		},

		//Metodo para obtener lista de galerias paginadas
		async getGaleriaList({ commit }, data_){
			let response = null;
			let params = {
				query: LIST_PAGINADA_GALERIA,
				variables: data_,
				fetchPolicy: 'network-only'
			}
			try {
			await apolloClient.query(params).then(res => {
				console.log("respuest de server getListGaleria: ", res.data.galeriasIdAdmin);
				if (!res.loading) response = res.data.galeriasIdAdmin;
				//commit("getAdminListSoli", response);
			});
			}
			catch(e){
			console.log("Falla error, getListGaleria ", e);
			}
			return new Promise((resolve)=>{
			if(response!=null)
			{
				commit("getGalleries", response);
				resolve(response);
			}
			else 
			{
				resolve(false);
			}
			});
		}

	}
};