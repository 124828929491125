// El adapter "transforma" el objeto a adaptar en algo parecido al objeto objetivo
// (el objeto al que queremos parecernos).
// En este caso adaptamos el objeto que viene de Repos para que se su interfaz se
// parezca al objeto que viene del módulo Videos, de modo que los nombres de los métodos 
// y propiedades del Adapter serán los mismos que los de la interfaz del objetivo.
// Pasamos de..
// VideoRepo --X--> VideosContainer <--✓-- VideoVideos 
// a..
// VideoRepo --paso como constructor--> VideoAdapter(VideoRepo) --✓--> VideosContainer <--✓-- VideoVideos

export class VideoAdapter {
	constructor(init = {}) {
		Object.assign(this, init);
		if (init.id_archivo) {
			this._id_adapter = this.id_archivo;
			this.id_video = null;
		}
		if (init.id_video) {
			this._id_adapter = this.id_video;
			this.id_archivo = null;
		}
		if (init.nombre_archivo) {
			this.titulo_video = init.nombre_archivo;
		}
		if (!init.url_imagen) {
			this.url_imagen = "https://storage.googleapis.com/c3-bucket-01/LKW__LOGO_512x512px.png";
		}
		if (!init.tiempo_video) {
			this.tiempo_video = "00:20:00";
		}
	}

	static from_get_canal(init) {
		if (init.archivo != null) {
			return new VideoAdapter({ id_file: init.id_file, ...init.archivo});
		} else {
			return new VideoAdapter({ id_file: init.id_file, ...init.video_tv });	
		}
	}

}