const muroList = [
  {
    path: "/muro",
    name: "Muro",
    component: (Muro) => require(["@/views/ADMIN/Muro.vue"], Muro),
    redirect: { name: "muro-list" },
    children: [
      {
        path: "list",
        name: "muro-list",
        component: (MuroList) =>
          require(["@/components/Muro/List.vue"], MuroList),
      },
      {
        path: "add",
        name: "muro-add",
        component: (MuroAdd) => require(["@/components/Muro/Add.vue"], MuroAdd),
      },
      {
        path: "statistics",
        name: "muro-statistics",
        component: (MuroStatistics) =>
          require(["@/components/Muro/Statistics.vue"], MuroStatistics),
      },
      {
        path: "edit/:id",
        name: "muro-edit",
        component: (MuroAdd) => require(["@/components/Muro/Add.vue"], MuroAdd),
      },
    ],
  },
];

export default {
  muroList,
};
