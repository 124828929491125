const benefitList = [
  {
    path: "/beneficios",
    name: "Beneficios",
    component: Benefit => require(["@/views/ADMIN/Benefit.vue"], Benefit),
    redirect: { name: "benefit-Dashboard" },
    children: [
      {
        path: "Dashboard",
        name: "benefit-Dashboard",
        component: BenefitDashboard => require(["@/components/Benefit/Dashboard.vue"], BenefitDashboard),
      },
      {
        path: "solicitud/:id",
        name: "solici-request",
        component: BenefitRequest => require(["@/components/Benefit/BenefitRequest.vue"], BenefitRequest),
      },
      // { path: "gas", name: "gas-request", component: GasRequest => require(["@/components/Benefit/GasRequest.vue"], GasRequest) },
      // { path: "salmon", name: "salmon-request", component: SalmonRequest => require(["@/components/Benefit/SalmonRequest.vue"], SalmonRequest) },
      // {
      //   path: "educacion",
      //   name: "educacion-request",
      //   component: EducacionRequest => require(["@/components/Benefit/EducacionRequest.vue"], EducacionRequest),
      // },
    ],
  },
];
 export default {
  benefitList,
 }