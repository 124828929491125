import { apolloClient } from "../../../vue-apollo";
import { GET_SAFETY_DOCS_CATEGORIES } from "../../../apollo/queries";
export default {
    namespaced: true,
    state: {
        safety_cat_list: [],
    },
    mutations: {
        getSafeCategories(state, resp) {
            state.safety_cat_list = resp;
        },
        addSafeCat(state, cat) {
            state.safety_cat_list.unshift(cat);
        },
        deleteSafeCat(state, idCat) {
            state.safety_cat_list = state.safety_cat_list.filter(
                (cat) => cat.id_categoria !== idCat
            );
        },
        editSafeCat(state, editedCat) {
            // obtiene index del obj categoria
            var index = state.safety_cat_list.findIndex(
                (doc) => doc.id_categoria == editedCat.id_categoria
            );
            // para sacar el antiguo y reemplazarlo por el nuevo obj
            state.safety_cat_list.splice(index, 1, editedCat);
        },
    },
    actions: {
        // Obtener categorias de seguridad
        async getSafeCatsList({ commit }, data_) {
            const { data } = await apolloClient.query({
                query: GET_SAFETY_DOCS_CATEGORIES,
                variables: data_,
            });
            console.log(data.CategoriasDocumentosAdmin);
            commit("getSafeCategories", data.CategoriasDocumentosAdmin);
        },
        // Agregar categorias de seguridad
        async addSafeCatAction({ commit }, cat) {
            commit("addSafeCat", cat);
        },
        // Eliminar categorias de seguridad
        async deleteSafeCatAction({ commit }, id) {
            commit("deleteSafeCat", id);
        },
        // Editar categorias de seguridad
        async editSafeCatAction({ commit }, cat) {
            commit("editSafeCat", cat);
        },
    },
};