import { apolloClient } from "../../../vue-apollo";
import {
  SAVE_CHANNEL_LAYOUT,
  EDIT_CHANNEL_LAYOUT,
  SAVE_FILE_REPOSITORIO,
  DELETE_FILE_REPOSITORIO
} from "../../../apollo/mutations";
import {
  GET_ONE_CANAL,
  GET_PAGINAS_FILES_REPOSITORIO,
  GET_TOTAL_FILES_REPOSITORIO
} from "../../../apollo/queries";
import moment from 'moment';
import { VideoAdapter } from "../../../models/VideoAdapter";

export default {
  namespaced: true,
  state: {
    channelLayout: {
      id_canal: "",
      logo: {},
      url_logo: "",
      videos: [],
      tiempo_videos: "00:00:00",
      banners: [],
      color: "",
      widgets: {},
      timePerBanner: "",
      _editedFiles: {
        videos: [],
        banners: [],
      },
      _dbFiles: {
        videos: [],
        banners: [],
      }
    },
    newChannelLayoutResponse: {},
    channelData: {},
    baseChannelLayout: {
      id_canal: "",
      logo: {},
      url_logo: "",
      videos: [],
      tiempo_videos: "00:00:00",
      banners: [],
      color: "",
      widgets: {},
      timePerBanner: "",  
      _editedFiles: {
        videos: [],
        banners: [],
      },
      _dbFiles: {
        videos: [],
        banners: [],
      }
    },
    allRepoVideos: [],
    repoVideosPagination: [],
    allRepoBanners: [],
    repoBannersPagination: []
  },

  getters: {
    isLayoutValidForBack(state) {
      let valid = true;
      if (Object.keys(state.channelLayout.widgets).length === 0) {
        valid = false;
      }
      if (state.channelLayout.videos.length === 0) {
        valid = false;
      }
      if (state.channelLayout.banners.length === 0) {
        valid = false;
      }
      if (state.channelLayout.url_logo === "" && Object.keys(state.channelLayout.logo).length === 0) {
        valid = false;
      }
      return valid;
    },
    repoVideosFiltered: (state) => (page) => {
      if (state.repoVideosPagination.length === 0) {
        return [];
      } else {
        return state.allRepoVideos.filter(video => state.repoVideosPagination[page].ids.includes(video.id_archivo));
      }
    },
    repoBannersFiltered: (state) => (page) => {
      if (state.repoBannersPagination.length === 0) {
        return [];
      } else {
        return state.allRepoBanners.filter(banner => state.repoBannersPagination[page].ids.includes(banner.id_archivo));
      }
    },
    paginationDataForRepoVideos(state) {
      return {
        page_count: state.repoVideosPagination.length == 0 ? 1 : state.repoVideosPagination.length
      };
    }
  },

  mutations: {
    resetChannelContent(state) {
      state.channelLayout = JSON.parse(JSON.stringify(state.baseChannelLayout));
    },
    setChannelLayoutLogo(state, logo) {
      state.channelLayout.logo = logo;
    },
    deleteChannelLayoutLogo(state) {
      state.channelLayout.logo = {};
      state.channelLayout.url_logo = "";
    },
    setChannelLayoutVideos(state, data_) {
      createOrEditFile(state.channelLayout, data_, "videos", "_id_adapter");
      state.channelLayout.tiempo_videos = data_.tiempo_total;
    },
    setChannelLayoutBanners(state, data_) {
      createOrEditFile(state.channelLayout, data_, "banners", "id_archivo");
      state.channelLayout.timePerBanner = data_.timePerBanner;
    },
    setChannelLayoutColor(state, color) {
      state.channelLayout.color = color;
    },
    setChannelLayoutWidgets(state, data_) {
      if (state.channelLayout.widgets[data_.rank]) {
        Object.assign(state.channelLayout.widgets[data_.rank], data_.widget);
      } else {
        state.channelLayout.widgets[data_.rank] = { ...data_.widget };
      }
      state.channelLayout.widgets[data_.rank].tiempo_ejecucion = data_.tiempo_ejecucion;
    },
    setChannelLayoutId(state, id) {
      state.channelLayout.id_canal = id;
    },
    SET_CHANNEL_CONTENT(state, response) {
      state.newChannelLayoutResponse = response;
    },
    getCanal(state, response) {
      state.channelData = response;
      state.channelLayout = JSON.parse(JSON.stringify(state.baseChannelLayout));

      const hms_to_sec = (hms) => { 
        let a = hms.split(':');
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      };
      // Id canal, Id lista, Logo, Color
      state.channelLayout.id_canal = response.id_canal;
      state.channelLayout.url_logo = response.lista_reproduccion[0].url_logo;
      state.channelLayout.color = response.lista_reproduccion[0].color;
      state.channelLayout.id_lista = response.lista_reproduccion[0].id_lista;

      // Videos
      state.channelLayout.videos = response.lista_reproduccion[0].videos[0] ? 
        response.lista_reproduccion[0].videos
          .sort((a, b) => +a.orden - +b.orden)
          .map(video => VideoAdapter.from_get_canal(video)) : [];
      state.channelLayout.tiempo_videos = response.lista_reproduccion[0].videos[0] ?
        response.lista_reproduccion[0].videos[0].total_tiempo : "00:00:00";

      // Banners
      state.channelLayout.banners = response.lista_reproduccion[0].banners[0] ? 
        response.lista_reproduccion[0].banners
          .sort((a, b) => +a.orden - +b.orden)
          .map(banner => { return { id_file: banner.id_file, ...banner.archivo }}) : [];
      state.channelLayout.timePerBanner = response.lista_reproduccion[0].banners[0] ?
        hms_to_sec(response.lista_reproduccion[0].banners[0].tiempo_ejecucion) : 4;


      // Widgets
      const frontWidgets = response.lista_reproduccion[0].widgets.map(widget => {
        return {
          id_widget: widget.id_widget,
          tipo_widget: widget.tipo_widget,
          tiempo_ejecucion: hms_to_sec(widget.tiempo_ejecucion),
          id_objeto:     widget.tipo_widget == "1" ? widget.modulo?.id_modulo     : widget.api?.id_api,
          imagen_widget: widget.tipo_widget == "1" ? widget.modulo?.url_imagen    : widget.api?.url_imagen,
          nombre_widget: widget.tipo_widget == "1" ? widget.modulo?.nombre_modulo : widget.api?.nombre_api
        };
      });
      state.channelLayout.widgets = { ...frontWidgets };

      state.channelLayout._dbFiles.videos  = state.channelLayout.videos.slice();
      state.channelLayout._dbFiles.banners = state.channelLayout.banners.slice();
      state.channelLayout._dbFiles.widgets = { ...state.channelLayout.widgets };
    },
    getChannelStateFromLS(state) {
      if (localStorage.getItem('channelLayout')) {
        Object.assign(
          state.channelLayout, JSON.parse(localStorage.getItem('channelLayout'))
        );
      }
    },
    setAllRepoFiles(state, fileObjArr) {
      if (fileObjArr.length === 0) return;
      if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "1") {
        state.allRepoVideos = fileObjArr;
      } else if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "2") {
        state.allRepoBanners = fileObjArr;
      }
    },
    deleteRepoFile(state, fileObj) {
      if (fileObj.detalle_tipoa.tipo_archivo.id_tipo === "1") {
        state.allRepoVideos = state.allRepoVideos.filter(video => video.id_archivo != fileObj.id_archivo);
      } else if (fileObj.detalle_tipoa.tipo_archivo.id_tipo === "2") {
        state.allRepoBanners = state.allRepoBanners.filter(banner => banner.id_archivo != fileObj.id_archivo);
      }
    },
    addUploadedFile(state, fileObj) {
      if (fileObj.detalle_tipoa.tipo_archivo.id_tipo === "1") {
        state.allRepoVideos.unshift(fileObj);
        if ("ids" in state.repoVideosPagination[0]) state.repoVideosPagination[0].ids.unshift(fileObj.id_archivo);
      } else if (fileObj.detalle_tipoa.tipo_archivo.id_tipo === "2") {
        state.allRepoBanners.unshift(fileObj);
        if ("ids" in state.repoBannersPagination[0]) state.repoBannersPagination[0].ids.unshift(fileObj.id_archivo);
      }
    },
    setPagination(state, { pagination, payload }) {
      if (payload.tipoArchivo === "1") {
        state.repoVideosPagination = pagination;
      } else if (payload.tipoArchivo === "2") {
        state.repoBannersPagination = pagination;
      }
    }
  },

  actions: {
    getChannelStateFromLS({ commit }) {
      commit("getChannelStateFromLS");
    },
    resetChannelContent({ commit }) {
      commit("resetChannelContent");
    },
    setChannelLayoutLogo({ commit }, logo) {
      commit("setChannelLayoutLogo", logo);
    },
    deleteChannelLayoutLogo({ commit }) {
      commit("deleteChannelLayoutLogo");
    },
    setChannelLayoutVideos({ commit }, data_) {
      commit("setChannelLayoutVideos", data_);
    },
    setChannelLayoutBanners({ commit }, data_) {
      commit("setChannelLayoutBanners", data_);
    },
    setChannelLayoutColor({ commit }, color) {
      commit("setChannelLayoutColor", color);
    },
    setChannelLayoutWidgets({ commit }, data_) {
      commit("setChannelLayoutWidgets", data_);
    },
    setChannelLayoutId({ commit }, id_canal) {
      commit("setChannelLayoutId", id_canal);
    },
    async setChannelLayout({ commit, state }, data_) {
      let t = new Date(1970, 0, 0);
      t.setSeconds(state.channelLayout.timePerBanner);
      const tiempo_ejecucion_banner = moment(t).format("HH:mm:ss");
      t = new Date(1970, 0, 0);
      t.setSeconds(state.channelLayout.timePerBanner * state.channelLayout.banners.length);
      const total_tiempo_banners = moment(t).format("HH:mm:ss");
      const videos = state.channelLayout._editedFiles.videos.length ? 
        state.channelLayout._editedFiles.videos : state.channelLayout.videos;
      const banners = state.channelLayout._editedFiles.banners.length ? 
        state.channelLayout._editedFiles.banners : state.channelLayout.banners;

      const widgets = Object.entries(state.channelLayout.widgets)
        .sort(entry => entry[0] > entry[1])
        .map(entry => entry[1]); // crea array con widgets ordenados según rank
      let response = null;
      let payload = {
        id_canal: state.channelLayout.id_canal,
        id_usuario: data_.id_usuario,
        id_empresa: data_.id_empresa,
        lista: {
          id_lista: data_.id_lista ?? undefined,
          logo: state.channelLayout.logo.src ? state.channelLayout.logo.src : "",
          color: state.channelLayout.color,
          formato_logo: "image/" + String(state.channelLayout.logo.format),
          videos: videos.map(video => {
            return {
              id_archivo_lista: video.id_file ?? "0",
              id_archivo_repositorio: video.id_archivo, // los que vienen del repo
              id_video: video.id_video, // los que vienen de Videos
              tipo_archivo: "1",
              tiempo_ejecucion: video.tiempo_video,
              total_tiempo: state.channelLayout.tiempo_videos, // tiempo total de playlist
              fecha_activacion: null
            }
          }),
          banners: banners.map(banner => {
            return {
              id_archivo_lista: banner.id_file ?? "0",
              id_archivo_repositorio: banner.id_archivo,
              id_video: null,
              tipo_archivo: "2",
              tiempo_ejecucion: tiempo_ejecucion_banner,
              total_tiempo: total_tiempo_banners,
              fecha_activacion: null
            }
          }),
          widgets: widgets.map(widget => {
            let t = new Date(1970, 0, 0);
            t.setSeconds(widget.tiempo_ejecucion);
            const duration  = moment(t).format("HH:mm:ss");
            return {
              id_widget: widget.id_widget ?? "0",
              id_objeto: widget.id_objeto,
              tipo_widget: widget.tipo_widget,
              tiempo_ejecucion: duration
            };
          }),
        }
      };
      let params = {
        mutation: data_.id_lista ? EDIT_CHANNEL_LAYOUT : SAVE_CHANNEL_LAYOUT,
        variables: payload
      };

      try {
        let data = null;
        await apolloClient.mutate(params).then(res => {
          if (!res.loading) data = data_.id_lista ? 
            res.data.UpdateListaReproduccion : res.data.SaveListaReproduccion;
          response = data;
        });
      } catch (e) {
        // console.log("response de cargar contenido al canal: ", response);
        console.log("response de cargar contenido al canal: ", e);
        console.log(e.networkError.result.errors);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          console.log('setChannelLayout response:', response);
        } else {
          resolve(false);
        }
        commit("SET_CHANNEL_CONTENT", response);
      });
    },

    async getCanal({ commit }, data_) {
      let response = [];
      let params = {
          query: GET_ONE_CANAL,
          variables: data_,
          fetchPolicy: 'network-only'
        };
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.GetOneCanal;
        });
      } catch (e) {
        console.log("response de getCanal: ", e);
        response = null;
      }
      return new Promise((resolve)=>{
        if(response != null){
          resolve(response);
          commit("getCanal", response);
        } else {
          resolve(null);
        }
      });
    },

    async uploadFileRepositorio({ commit }, data_) {
      const duration = new Date(data_.file.duration * 1000).toISOString().slice(11, 19);
      
      const payload = {
        id_empresa: data_.id_empresa,
        id_usuario: data_.id_usuario,
        archivo: {
          descripcion_archivo: "",
          id_repositorio: "0",
          tipo_archivo: data_.tipo_archivo,
          nombre_archivo: data_.file.name,
          tiempo:         String(duration),
          archivo_b64:    data_.file.src,
          formato_b64:    data_.file.type,
        }
      };

      // const mini_payload = Object.assign({}, { 
      //   id_empresa: data_.id_empresa,
      //   id_usuario: data_.id_usuario,
      //   archivo: {
      //     descripcion_archivo: "",
      //     id_repositorio: "0",
      //     tipo_archivo: data_.tipo_archivo,
      //     nombre_archivo: data_.file.name,
      //     tiempo:         String(duration),
      //     archivo_b64:    data_.file.src.slice(0,50),
      //     formato_b64:    data_.file.type,
      //   }
      // });
      // console.log("uploadFileRepositorio payload:", JSON.stringify(mini_payload));
      
      const params = {
        mutation: SAVE_FILE_REPOSITORIO,
        variables: payload
      };
      
      let response = null;
      try {
        let data = null;
        await apolloClient.mutate(params).then(res => {
          if (!res.loading) data = res.data.SaveRepositorio;
          response = data;
        });
      } catch (e) {
        console.log("error de cargar archivo al repositorio: ", e);
        // console.log(e.networkError.result.errors);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          console.log('uploadFileRepositorio response:', response);
          commit("addUploadedFile", response);
        } else {
          resolve(false);
        }
      });
    },

    async deleteFileRepositorio({ commit }, data_) {
      const params = {
        mutation: DELETE_FILE_REPOSITORIO,
        variables: data_
      };
      
      let response = null;
      try {
        let data = null;
        await apolloClient.mutate(params).then(res => {
          if (!res.loading) data = res.data.DeleteFileRepositorio;
          response = data;
        });
      } catch (e) {
        console.log("response de eliminar archivo del repo: ", response);
        console.log("error de eliminar archivo del repo: ", e);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          console.log('deleteFileRepositorio response:', response);
        } else {
          resolve(false);
        }
        commit("deleteRepoFile", response);
      });
    },

    async getRepoFiles(_, data_) {
      const params = {
				query: GET_PAGINAS_FILES_REPOSITORIO,
				variables: data_
			};

      let response = null;
			try {
				let data = null;
				response = await apolloClient.query(params);
        if (!response.loading) data = response.data.PaginasFilesRepositorio;
        response = data;
			} catch (e) {
				console.log("response de getRepoFiles: ", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
				} else {
					resolve(false);
				}
			});
    },

    async getFilesPagination({ commit }, data_) {
      const params = {
				query: GET_TOTAL_FILES_REPOSITORIO,
				variables: data_
			};

      let response = null;
			try {
				let data = null;
				response = await apolloClient.query(params);
        if (!response.loading) data = response.data.TotalFilesRepositorio;
        response = data;
			} catch (e) {
				console.log("response de getFilesPagination: ", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
          commit("setPagination", { pagination: response, payload: data_ });
				} else {
					resolve(false);
				}
			});
    },

    async getAllRepoFiles({ dispatch, commit }, data_) {
      Object.assign(data_, {
        nombreArchivo: ""
      });

			const paginatedFiles = await dispatch("getFilesPagination", data_);      

      let allFiles = null;
      if (paginatedFiles !== null) {
        let repoFilesIds = [];
        paginatedFiles.forEach(page => {
          repoFilesIds = repoFilesIds.concat(page.ids);
        });
        allFiles = await dispatch("getRepoFiles", { idEmpresa: data_.idEmpresa, idArchivo: repoFilesIds });
      } else {
				console.log("getAllRepoFiles. error en getFilesPagination. respuesta null");
      }
      
			return new Promise((resolve) => {
				if (allFiles != null) {
					resolve(allFiles);
					commit("setAllRepoFiles", allFiles);
				} else {
					resolve(false);
				}
			});
    }
  },
};

function createOrEditFile(channelState, data, filetype, id_field) {
  channelState._editedFiles[filetype] = [];
  const dbFilesCopy = channelState._dbFiles[filetype].slice();

  if (dbFilesCopy.length != 0) {

    for (const file of data[filetype]) {
      const foundIdx = dbFilesCopy.findIndex(f => f[id_field] === file[id_field])
      if (foundIdx != -1) {
        channelState._editedFiles[filetype].push({ ...dbFilesCopy[foundIdx] });
        dbFilesCopy.splice(foundIdx, 1);
      } else {
        channelState._editedFiles[filetype].push({ id_file: "0", ...file });
      }
    }

    for (const file of dbFilesCopy) {
      channelState._editedFiles[filetype].push({ ...file, id_archivo: null, id_video: null });
    }

  }

  channelState[filetype] = data[filetype].slice();
}
