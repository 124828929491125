import Users from "../../../views/ADMIN/Users.vue";
import UsersAdd from "../../../components/Users/Add.vue";
import UsersList from "../../../components/Users/List.vue";

const userList = [
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Users,
    redirect: { name: "usuarios-list" },
    children: [
      { path: "list", name: "usuarios-list", component: UsersList },
      { path: "add", name: "usuarios-add", component: UsersAdd },
      { path: "edit/:id", name: "usuarios-edit", component: UsersAdd },
    ],
  },
];
export default {
  userList,
};
