const recognizeList = [
  {
    path: "/reconoce",
    name: "Reconoce",
    component: (Recognize) =>
      require(["@/views/ADMIN/Recognize.vue"], Recognize),
    redirect: { name: "recognize-Dashboard" },
    children: [
      {
        path: "year",
        name: "recognize-year",
        component: (RecognizeYear) =>
          require(["@/components/Recognize/Year.vue"], RecognizeYear),
      },
      {
        path: "behavior",
        name: "recognize-behavior",
        component: (RecognizeBehavior) =>
          require(["@/components/Recognize/Behavior.vue"], RecognizeBehavior),
      },
      {
        path: "management",
        name: "recognize-management",
        component: (RecognizeManagement) =>
          require([
            "@/components/Recognize/Management.vue",
          ], RecognizeManagement),
      },
      {
        path: "age",
        name: "recognize-age",
        component: (RecognizeAge) =>
          require(["@/components/Recognize/Age.vue"], RecognizeAge),
      },
      {
        path: "ranking",
        name: "recognize-ranking",
        component: (RecognizeRanking) =>
          require(["@/components/Recognize/Ranking.vue"], RecognizeRanking),
      },
      {
        path: "contributor",
        name: "recognize-contributor",
        component: (RecognizeContributor) =>
          require([
            "@/components/Recognize/Contributor.vue",
          ], RecognizeContributor),
      },
      {
        path: "reports",
        name: "recognize-reports",
        component: (RecognizeReports) =>
          require(["@/components/Recognize/Reports.vue"], RecognizeReports),
      },
      {
        path: "Dashboard",
        name: "recognize-Dashboard",
        component: (RecognizeDashboard) =>
          require(["@/components/Recognize/Dashboard.vue"], RecognizeDashboard),
      },
    ],
  },
];

export default {
  recognizeList,
};
