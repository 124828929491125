<template>
  <aside :class="['sidebar', {'sidebar-narrow': narrow_sidebar}]">
      <a 
        href="javascript:"
        class="sidebar-narrow-link d-none d-xl-flex" 
        @click="narrowSidebar">
          <font-awesome-icon :icon="narrow_sidebar ? 'chevron-right' : 'chevron-left'" />
      </a>
      <div class="sidebar-logo">
        <img class="sidebar-logo-complete" src="../assets/img/twu_logo.svg">
        <img class="sidebar-logo-letter" src="../assets/img/twu_letra.svg">
        <a 
          class="btn sidebar-btn btn-custom btn-custom-color-white btn-block d-md-none me-3" 
          href="javascript:" 
          @click="toggleSidebar"
          role="button">
          <i class="fas fa-bars"></i>
        </a>
      </div>
      <ul :class="['sidebar-list mb-0', {'sidebar-list-show': mobile_sidebar === true}]">
        <li
          class="sidebar-list-item"
          :class="[
            path == section.moduloGeneral.ruta_admin && 'active',
            active_sidebar && path == section.moduloGeneral.route && 'active_background',
            section.nombre_modulo,
          ]"
          v-for="(section, k) in print_sections"
          :key="k"
          @click="goToRoute(section.moduloGeneral.ruta_admin)"
          @mouseover="hover_section = section"
          @mouseleave="hover_section = {}"
          v-show="checkSection(section)"
          >
          <a 
            href="javascript:"
            class="sidebar-list-item-link">
            <span  class="sidebar-list-item-link-icon">
              <img
                :content="section.nombre_modulo"
                v-tippy="{ arrow: false, placement : 'right', trigger:trigger_tippy }"
                :src="handleSidebarImage(section)"
                alt=""
                class="sidebar-list-item-link-icon-img"
              />         
            </span>
            <span class="sidebar-list-item-link-text" v-if="!narrow_sidebar">
              {{ section.nombre_modulo }}
            </span>
          </a>
        </li>
      </ul>
  </aside>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      trigger_tippy: 'mouseenter focus',
      sections: [
        {
          name: "Inicio",
          id_modulo: "1",
          route: "/inicio",
          img: require("../assets/img/sidebar-icons/home.svg"),
          img_active: require("../assets/img/sidebar-icons/home-active.svg"),
        },
        {
          name: "Noticias",
          id_modulo: "3",
          route: "/noticias",
          img: require("../assets/img/sidebar-icons/noticias.svg"),
          img_active: require("../assets/img/sidebar-icons/noticias-active.svg"),
        },
        {
          name: "Galería",
          id_modulo: "7",
          route: "/galeria",
          img: require("../assets/img/sidebar-icons/galeria.svg"),
          img_active: require("../assets/img/sidebar-icons/galeria-active.svg"),
        },
        {
          name: "TV",
          id_modulo: "6",
          route: "/videos",
          img: require("../assets/img/sidebar-icons/tv.svg"),
          img_active: require("../assets/img/sidebar-icons/tv-active.svg"),
        },
        {
          name: "Usuarios",
          id_modulo: "11",
          route: "/usuarios",
          img: require("../assets/img/sidebar-icons/usuarios.svg"),
          img_active: require("../assets/img/sidebar-icons/usuarios-active.svg"),
        },
        {
          name: "Nuestra Cultura",
          id_modulo: "12",
          route: "/nuestra-cultura",
          img: require("../assets/img/sidebar-icons/cultura.svg"),
          img_active: require("../assets/img/sidebar-icons/cultura-active.svg"),
        },
        {
          name: "Organigrama",
          route: "/organigrama",
          img: require("../assets/img/sidebar-icons/organigrama.svg"),
          img_active: "../assets/img/sidebar-icons/organigrama-active.svg",
        },
        {
          name: "Reconoce",
          id_modulo: "4",
          route: "/reconoce",
          img: require("../assets/img/sidebar-icons/reconoce.svg"),
          img_active: require("../assets/img/sidebar-icons/reconoce-active.svg"),
        },
        {
          name: "Beneficios",
          id_modulo: "5",
          route: "/beneficios",
          img: require("../assets/img/sidebar-icons/benefit.svg"),
          img_active: require("../assets/img/sidebar-icons/benefit-active.svg"),
        },
        {
          name: "Seguridad",
          id_modulo: "9",
          route: "/seguridad",
          img: require("../assets/img/sidebar-icons/seguridad.svg"),
          img_active: require("../assets/img/sidebar-icons/seguridad-active.svg"),
        },
        {
          name: "Biblioteca",
          id_modulo: "16",
          route: "/biblioteca",
          img: require("../assets/img/sidebar-icons/library.svg"),
          img_active: require("../assets/img/sidebar-icons/library-active.svg"),
        },
        {
          name: "Muro",
          id_modulo: "2",
          route: "/admin-muro",
          img: require("../assets/img/sidebar-icons/muro.svg"),
          img_active: require("../assets/img/sidebar-icons/muro.svg"),
        },
        {
          name: "Covid-19",
          id_modulo: "15",
          route: "/covid",
          img: require("../assets/img/sidebar-icons/covid.svg"),
          img_active: require("../assets/img/sidebar-icons/covid-active.svg"),
        },
        {
          name: "Encuesta",
          id_modulo: "20",
          route: "/encuestas",
          img: require("../assets/img/sidebar-icons/encuestas.svg"),
          img_active: require("../assets/img/sidebar-icons/encuestas.svg"),
        },
        {
          name: "Alerta Push",
          id_modulo: "14",
          route: "/push",
          img: require("../assets/img/sidebar-icons/alertas.svg"),
          img_active: require("../assets/img/sidebar-icons/alertas.svg"),
        },
        {
          name: "Transporte",
          id_modulo: "22",
          route: "/transporte",
          img: require("../assets/img/sidebar-icons/transporte.png"),
          img_active: require("../assets/img/sidebar-icons/transporte-active.svg"),
        },
      ],
      print_sections: [],
      modulos: this.$ls.get("modules"),
      user: this.$ls.get("user"),
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      path: "",
      active_sidebar: false,
      active_sidebar_text: false,
      logo_img: require("../assets/img/twu_logo.svg"),
      letra_img: require("../assets/img/twu_letra.svg"),
      openMenu: false,
      hover_section: {},

      //FF VAR
      narrow_sidebar: false,
      mobile_sidebar: false,
      media_mobile: window.matchMedia("(max-width: 767px)"),
      media_table: window.matchMedia("(min-width: 768px) and (max-width: 1199px)"),
      media_desktop: window.matchMedia("(min-width: 1200px)")
    };
  },

  computed: {
    ...mapState("sidebar",["sidebar_open", "modules"]),
  },
  updated() {
    this.path = this.$route.matched[0].path;
  },
  async mounted() {
    await this.getModulesFromStore();
    this.path = this.$route.matched[0].path;
  },
  watch: {
    active_sidebar: function () {
      if (this.active_sidebar) {
        setTimeout(() => {
          this.active_sidebar_text = true;
        }, 100);
      } else {
        this.active_sidebar_text = false;
      }
    },
  },
  methods: {
    ...mapActions("sidebar",["sideAc","getModules"]),
    checkSection(section){
      if((section.url_menu_activo != '' && section.url_menu_activo != null) && (section.url_menu_inactivo != '' && section.url_menu_inactivo != null)){
        return true;
      }
      return false;
    },
    async getModulesFromStore(){
      let data ={
        id_empresa:this.id_empresa,
        id_privilegio: this.user.privilegio[0].id_tipo
      }
      await this.getModules(data);
      this.print_sections = this.modules;
    },
    goToRoute(route) {
      this.path = this.$route.matched[0].path;
      if (route == this.$route.matched[0].path) {
        return;
      } else {
        this.$router.push({ path: route });
      }
    },
    handleSidebarImage(section) {
      if (this.path == section.moduloGeneral.ruta_admin) {
        return section.url_menu_activo;
      } else {
        if(this.hover_section == section){
          return section.url_menu_activo;
        }else{
          return section.url_menu_inactivo;
        }
      }
    },
    sectionDef() {
      var ws_sections = [];
      this.sections.forEach(sec => {
        if (sec.id_modulo == "1") ws_sections.push(sec);
        if (sec.id_modulo != "1") {
          let found_modulo = this.modulos.find(
            (e) => e.moduloGeneral.id_modulo == sec.id_modulo
          );
          if (found_modulo != undefined && found_modulo != null) {
            sec.name = found_modulo.nombre_modulo;
            ws_sections.push(sec);
          }
        }
      });
      this.print_sections = ws_sections;
    },
    openMenuOpen() {
      if (this.openMenu) {
        this.openMenu = false;
        this.active_sidebar = false;
        this.trigger_tippy = 'mouseenter focus'
        setTimeout(() => {
          this.sideAc(false);
        }, 550);
      } else {
        this.openMenu = true;
        this.active_sidebar = true;
        this.trigger_tippy = 'manual'
        setTimeout(() => {
          this.sideAc(true);
        }, 550);
      }
    },

    //FF FUNCTIONS
    narrowSidebar () {
      let main = document.getElementById("main");
      this.narrow_sidebar = !this.narrow_sidebar;
      if(this.narrow_sidebar) {
        main.classList.add('main-narrow');
      }
      else {
        main.classList.remove('main-narrow');
      }
    },
    mediaBreakpoints () {
      let main = document.getElementById("main");
      if(!this.narrow_sidebar) {
        if(this.media_table.matches) {
          this.narrow_sidebar = true;
          main.classList.add('main-narrow');
        }
      }
      if(this.narrow_sidebar) {
        if(this.media_mobile.matches) {
          this.narrow_sidebar = false;
          main.classList.remove('main-narrow');
        }
        if(this.media_desktop.matches) {
          this.narrow_sidebar = true;
        }
      }
    },
		toggleSidebar: function () {
      this.mobile_sidebar = !this.mobile_sidebar;
		},
  },
  beforeMount() {
    window.addEventListener('resize', this.mediaBreakpoints);
  }
};
</script>
