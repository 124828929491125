import { apolloClient } from "../../../vue-apollo";
import {
    GET_ENCUESTAS_ADMIN,
    GET_SEGMENTACION,
    GET_SEGMENTACION_ELEMENTOS,
    REPORTE_ENCUESTAS_ADMIN,
    PAGINAS_ENCUESTAS_ADMIN,
    GET_ENCUESTAS_PAGINADAS,
    GET_ESTADOS_ENCUESTA

} from "@/apollo/queries";
import {
  CREATE_ENCUESTA,
  EDIT_ENCUESTA_ID,
  DELETE_ENCUESTA
} from "@/apollo/mutations"
export default {
  namespaced: true,
  state: {
      encuestas_list: [],
      encuesta_add: [],
      segmentaciones_list: [],
      encuestaStore: {},
      segmentaciones_filtradas: [],
      preguntas_deleted: [],
      reporte: {},
      paginas_encuestas: [],
      estados: []
  },
  getters: {
      getEncuestaById: (state) => (id_encuesta) => {
          const { encuestas_list } = state;
          const encuesta = encuestas_list.find(
              (survey) => survey.id_encuesta == id_encuesta
          );

          return encuesta;
      },
  },
  mutations: {
    cargar(state, payload) {
      state.encuestaStore = payload;
    },
    // Trae encuestas y agrega un estado
    GET_ENCUESTAS(state, encuesta) {
      
      state.encuestas_list = encuesta;
      //console.log("get");
    },
    GET_SEGMENTACIONES(state, segmentacion) {
      state.segmentaciones_list = segmentacion;
    },
    GET_SEGMENTACIONES_FILTRADAS(state, filtrosPush) {
      state.segmentaciones_filtradas = filtrosPush;
    },
    EDIT_ENCUESTA(state, encuesta) {
      let index = state.encuestas_list.findIndex(
        e => e.id_encuesta == encuesta.id_encuesta
      );
      // reemplazo
      state.encuestas_list[index] = encuesta;
    },
    CREATE_ENCUESTA(state, encuesta) {
      
      // //state.encuesta_add.push(encuesta);
      state.encuestas_list.unshift(encuesta);
      //console.log("add", state.encuesta_add);
    },
    ADD_PREGUNTA_DELETED(state, pregunta) {
      state.preguntas_deleted.push(pregunta);
      console.log("state: ", state.preguntas_deleted);
    },
    // Ver detalle
    GET_REPORTE(state, data) {
      state.reporte = data;
    },

    DETELE_ENCUESTA(state, data){
      let index = state.encuestas_list.findIndex(
        (e) => e.id_encuesta === data.id_encuesta
      );
      state.encuestas_list.splice(index, 1);
    },

    SET_PAGINAS_ENCUESTA(state, data){
      state.paginas_encuestas = data;
    },

    SET_ESTADOS_ENCUESTA(state, data){
      state.estados = data;
    }
  
  },
  actions: {

    // crear encuesta NUEVO METODO
    async crearEncuesta({ commit }, data_){
      console.log('creación de crearEncuesta');
      let response = null;
      let data = [];
      let params = {
          mutation: CREATE_ENCUESTA,
          variables: data_
      }
      try{
        await apolloClient.mutate(params).then(res => {
            //console.log('crearEncuesta', res.data.crearEncuesta);
            if (!res.loading) data = res.data.crearEncuesta;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo crearEncuesta: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          commit("CREATE_ENCUESTA", response);
          resolve(response);
        }
        else {
          resolve(false);
        }
        
      });
    },
    // edita encuesta NUEVO METODO
    async editarEncuesta({ commit }, data_){
      console.log('editar editarEncuesta');
      let response = null;
      let data = [];
      let params = {
          mutation: EDIT_ENCUESTA_ID,
          variables: data_
      }
      try{
        await apolloClient.mutate(params).then(res => {
            //console.log('editarEncuesta', res.data.editarEncuesta);
            if (!res.loading) data = res.data.editarEncuesta;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo editarEncuesta: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          resolve(response);
          commit("EDIT_ENCUESTA", response);
        }
        else {
          resolve(false);
        }
      });
    },
    // Obtener paginas de encuestas NUEVO METODO
    async getPaginasEncuestas({ commit }, data_){
      // seguir con el paginated
      let response = null;
      let params = {
          query: PAGINAS_ENCUESTAS_ADMIN,
          variables: data_,
          fetchPolicy: 'network-only'
      }
      try {
      await apolloClient.query(params).then(res => {
          //console.log("respuest de server getPaginasEncuestas: ", res.data.PaginasEncuestasAdmin);
          if (!res.loading) response = res.data.PaginasEncuestasAdmin;
      });
      }
      catch(e){
      console.log("Falla error, getPaginasEncuestas ", e);
      //return false
      }
      return new Promise((resolve)=>{
      if(response!=null){
        if (response.length==0){
            commit("SET_PAGINAS_ENCUESTA", response);
            resolve(false);
        }
        else {
            commit("SET_PAGINAS_ENCUESTA", response);
            resolve(response);
        }
      }
      else {
          resolve(false);
      }
      
      });
    },
    // Obtener encuestas ya paginadas NUEVO METODO
    async getEncuestasList({ commit }, data_){
      let response = null;
      let params = {
          query: GET_ENCUESTAS_PAGINADAS,
          variables: data_,
          fetchPolicy: 'network-only'
      }
      try {
      await apolloClient.query(params).then(res => {
          //console.log("respuest de server getEncuestasList: ", res.data.LimitEncuestasAdmin);
          if (!res.loading) response = res.data.LimitEncuestasAdmin;
          //commit("getAdminListSoli", response);
      });
      }
      catch(e){
      console.log("Falla error, getEncuestasList ", e);
      }
      return new Promise((resolve)=>{
      if(response!=null){
          if (response.length==0){
              commit("GET_ENCUESTAS", response);
              resolve(false);
          }
          else {
              commit("GET_ENCUESTAS", response);
              resolve(response);
          }
      }
      else {
          resolve(false);
      }
      });
    },
    // borrar encuesta NUEVO METODO
    async deteleEncuesta({ commit }, data_){
      console.log('deteleEncuesta');
      let response = null;
      let data = [];
      let params = {
          mutation: DELETE_ENCUESTA,
          variables: data_
      }
      try{
        await apolloClient.mutate(params).then(res => {
            //console.log('deteleEncuesta', res.data.eliminarEncuesta);
            if (!res.loading) data = res.data.eliminarEncuesta;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo deteleEncuesta: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          commit("DETELE_ENCUESTA", response);
          resolve(response);
        }
        else {
          resolve(false);
        }
      });
    },
    // Obtener estados de encuesta NUEVO METODO
    async getEstadosEncuestas({ commit }, data_){
      let response = {};
      let data = {};
      let params = {
        query: GET_ESTADOS_ENCUESTA,
        variables: data_
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.CategoriasEncuestas;
          response = data;
        });
      } catch(e) {
        console.log("listar getEstadosEncuestas falla: ", e);
        response = null
      }
      return new Promise((resolve)=>{
        if(response  != null){
          commit("SET_ESTADOS_ENCUESTA", response);
          resolve(response)
        } else {
          resolve(null)
        }
      });
    },

    // METODOS ANTIGUOS
    //obtener encuestas (metodo deprecado)
    async getEncuestasStore({ commit }, encuestas_query) {
      const { data } = await apolloClient.query({
        query: GET_ENCUESTAS_ADMIN,
        variables: {
          id_empresa: encuestas_query.id_empresa,
          nombre: encuestas_query.nombre,
          fecha_inicio: encuestas_query.fecha_inicio,
          fecha_fin: encuestas_query.fecha_fin,
          id_categoria: encuestas_query.id_categoria,
          limit: encuestas_query.limit,
          last_id: encuestas_query.last_id,
          last_fecha: encuestas_query.last_fecha,
        },
      });
      commit("GET_ENCUESTAS", data.listEncuestasAdmin);
    },
    // editar encuestas (metodo deprecado)
    async editEncuestaAction({ commit }, encuesta) {
      commit("EDIT_ENCUESTA", encuesta);
    },

    //crear encuestas (metodo deprecado)
    async createEncuestaAction({ commit }, encuesta) {
      commit("CREATE_ENCUESTA", encuesta);
    },  

    async getSegmentacionesStore({ commit }, segmentaciones_query) {
      const { data } = await apolloClient.query({
        query: GET_SEGMENTACION,
        variables: {
          id_elementos_segmentacion: segmentaciones_query.id_elementos_segmentacion,
          nombre_segmentacion: segmentaciones_query.nombre_segmentacion,
        },
      });
      commit("GET_SEGMENTACIONES", data.tipoSegmentacion);
    },

    cargarLocalStorage({ commit }) {
      if (localStorage.getItem("encuestaStore")) {
        const encuestaStore = JSON.parse(localStorage.getItem("encuestaStore"));
        commit("cargar", encuestaStore);
        return;
      }
    },

    async getFiltrosSegmentaciones({ commit }, id_elementos_segmentacion) {
      const { data } = await apolloClient.query({
        query: GET_SEGMENTACION_ELEMENTOS,
        variables: {
          idEmpresa: id_elementos_segmentacion.idEmpresa,
        },
      });
      commit("GET_SEGMENTACIONES_FILTRADAS", data.FiltrosPush);
    },

    async addPreguntasDeleted({ commit }, pregunta) {
      commit("ADD_PREGUNTA_DELETED", pregunta);
    },

    async getReporte({ commit }, params) {
      const { data } = await apolloClient.query({
        query: REPORTE_ENCUESTAS_ADMIN,
        variables: {
          idEmpresa: params.idEmpresa,
          idEncuesta: params.idEncuesta,
        }
      });
      console.log('graph data encuesta reporte', data);
      commit("GET_REPORTE", data.reportesEncuestasAdmin);
    },
  },
};
