const transporteList = [
  {
    path: "/transporte",
    name: "Transporte",
    component: (Transporte) =>
      require(["@/views/ADMIN/Transporte.vue"], Transporte),
    redirect: { name: "transporte-dashboard" },

    children: [
      {
        path: "dashboard",
        name: "transporte-dashboard",
        component: (TransporteDash) =>
          require(["@/components/Transporte/Dashboard.vue"], TransporteDash),
      },
      {
        path: "tramos",
        name: "transporte-tramos",
        component: (TransporteTramos) =>
          require(["@/components/Transporte/MisTramos.vue"], TransporteTramos),
      },
      {
        path: "planificaciones",
        name: "transporte-planificaciones",
        component: (Planificaciones) =>
          require([
            "@/components/Transporte/MisPlanificaciones.vue",
          ], Planificaciones),
      },
      {
        path: "en_curso",
        name: "transporte-en_curso",
        component: (EnCurso) =>
          require(["@/components/Transporte/EnCurso.vue"], EnCurso),
      },
      {
        path: "transportes",
        name: "transporte-transportes",
        component: (Transportes) =>
          require(["@/components/Transporte/Transportes.vue"], Transportes),
      },
      {
        path: "inscripciones",
        name: "transporte-inscripciones",
        component: (InscripcionesPersonal) =>
          require([
            "@/components/Transporte/InscripcionesPersonal.vue",
          ], InscripcionesPersonal),
        children: [
          {
            path: "list",
            name: "transporte-inscripciones-list",
            component: (InscripcionesBusqueda) =>
              require([
                "@/components/Transporte/InscripcionesBusqueda.vue",
              ], InscripcionesBusqueda),
          },
          {
            path: "add",
            name: "transporte-inscripciones-add",
            component: (InscripcionesAsignar) =>
              require(["@/components/Transporte/InscripcionesAsignar.vue",], InscripcionesAsignar),
          },
          {
            path: "edit/:id",
            name: "transporte-inscripciones-edit",
            component: (InscripcionesAsignar) =>
              require(["@/components/Transporte/InscripcionesAsignar.vue",], InscripcionesAsignar),
          },
        ],
      },
    ],
  },
];

export default {
  transporteList,
};
