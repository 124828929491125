export default {
  namespaced: true,
  state: { wallPerfiles_actions_list: [], wallPerfiles_action: {} },
  mutations: {
    addWallPerfilesAct(state, res) {
      state.wallPerfiles_actions_list.push(res);
    },
    addWallPerfilesAllAct(state, res) {
      //console.log("desc1", res);
      state.wallPerfiles_action = res;
      //console.log("desc2", state.wallPerfiles_action);
    },
  },
  actions: {
    // Añadir acciones de muro
    async addWallPerfilesAction({ commit }, action) {
      //valores
      //{ action: "accion", data: objeto }
      commit("addWallPerfilesAct", action);
    },
    async addWallPerfilesAllAction({ commit }, action) {
      //muro
      commit("addWallPerfilesAllAct", action);
    },
  },
};
