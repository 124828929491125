<template>
   <div class="status-modal">
      <div class="status-modal-container">
         <div class="status-modal-container-img">
            <div :class="status ? 'success' : 'fail'">
               <div class="img-container">
                  <img :src="status ? `${publicPath}imgs/success_face.svg` :`${publicPath}imgs/error_face.svg`" alt="" />
               </div>
            </div>
         </div>
         <div class="status-modal-container-message">{{ msg }}</div>
         <div class="status-modal-container-accept">
            <button @click="close">Aceptar</button>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      msg: { require: true },
      status: { require: true },
   },
   data() {
      return {
         publicPath: process.env.BASE_URL,
      };
   },
   methods: {
      close() {
         this.$emit("close");
      },
   },
};
</script>