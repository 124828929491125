const loginList = [
  {
    path: "/",
    name: "",
    component: (Login) => require(["@/views/Login.vue"], Login),
  },
  {
    path: "/Login/:token/:key",
    name: "Login-token",
    component: (LoginToken) => require(["@/views/LoginToken.vue"], LoginToken),
  },
  {
    path: "/Login/",
    name: "Login",
    component: (Login) => require(["@/views/Login.vue"], Login),
  },
];

export default {
  loginList,
};
