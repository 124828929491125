const covidList = [{
    path: "/folovid",
    name: "Covid-19",
    component: (Covid) => require(["@/views/ADMIN/Covid.vue"], Covid),
    redirect: { name: "covid-home" },
    children: [{
            path: "home",
            name: "covid-home",
            component: (CovidHome) =>
                require(["@/components/Covid/CovidHome.vue"], CovidHome),
        },
        {
            path: "user",
            name: "covid-user",
            component: (CovidUser) =>
                require(["@/components/Covid/CovidUser.vue"], CovidUser),
        },
        {
            path: "symptoms",
            name: "covid-symptoms",
            component: (CovidSymptoms) =>
                require(["@/components/Covid/CovidSymptoms.vue"], CovidSymptoms),
        },
    ],
}, ];

export default {
    covidList,
};