<template>
   <div class="instructions-modal">
      <div class="instructions-modal-container">
         <div class="close" @click="close">
            <font-awesome-icon icon="times"></font-awesome-icon>
         </div>
         <div class="title">
            <h3>Instrucciones</h3>
         </div>
         <hr />
         <div class="lists-container">
            <div class="list">
               <ul class="main-list">
                  <li class="main-list-li">
                     Descargar plantilla excel y llenar todos los campos con los
                     datos correspondientes
                  </li>
                  <li class="main-list-li">
                     Ítems a considerar:
                     <ul class="second-list">
                        <li>
                           &nbsp;<b>Rut:</b> Debe escribirse sin puntos ni guión
                           (solo números)
                        </li>
                        <li>
                           &nbsp;<b>Genero:</b> "F" para mujer, "M" para hombre
                        </li>
                        <li>
                           &nbsp;<b>FechaNacimiento:</b> el formato de fecha
                           debe ser <br />
                           DD-MM-AAAA
                        </li>
                        <li>
                           &nbsp;<b>FechaIngreso:</b> el formato de fecha debe
                           ser <br />
                           DD-MM-AAAA
                        </li>
                        <li>
                           &nbsp;<b>LugarTrabajo:</b> Debe ir el ID (número)
                           asignado
                        </li>
                        <li>
                           &nbsp;<b>AreaTrabajo:</b> Debe ir el ID (número)
                           asignado
                        </li>
                        <li>
                           &nbsp;<b>TipoUsuario:</b> Debe ir el ID (número)
                           asignado
                        </li>
                        <li>
                           &nbsp;<b>TurnosTrabajo:</b> Debe ir el ID (número)
                           asignado
                        </li>
                        <li>
                           &nbsp;<b>CargoTrabajo:</b> Debe ir el ID (número)
                           asignado
                        </li>
                        <li>
                           &nbsp;<b>Sociedad:</b> Debe ir el ID (número)
                           asignado
                        </li>
                        <li>
                           &nbsp;<b>Sindicato:</b> Debe ir el ID (número)
                           asignado
                        </li>
                        <li>
                           &nbsp;<b>Nación:</b> Debe ir el ID (número) asignado
                        </li>
                        <li>
                           &nbsp;<b>Grupo:</b> Debe ir el ID (número) asignado
                        </li>
                     </ul>
                  </li>
               </ul>
            </div>
            <div class="id-list">
               <div class="id-list-row">
                  <div class="titles">
                     <div class="title sociedad">
                        <span>Sociedad</span>
                        <span>ID</span>
                     </div>
                     <div class="title area">
                        <span>AreaTrabajo</span>
                        <span>ID</span>
                     </div>
                     <div class="title sucursal">
                        <span>LugarTrabajo</span>
                        <span>ID</span>
                     </div>
                     <div class="title turno">
                        <span>TurnosTrabajo</span>
                        <span>ID</span>
                     </div>
                     <div class="title grupo">
                        <span> Grupo </span>
                        <span>ID</span>
                     </div>
                  </div>
                  <div class="hr"></div>
                  <div class="content">
                     <div class="content-list sociedad">
                        <div
                           class="content-list-item"
                           v-for="(sociedad, k) in sociedad_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ sociedad.nombre_sociedad }}
                           </div>
                           <div class="id">
                              {{ sociedad.id_sociedad }}
                           </div>
                        </div>
                     </div>
                     <div class="content-list area">
                        <div
                           class="content-list-item"
                           v-for="(area, k) in area_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ area.nombre_gerencia }}
                           </div>
                           <div class="id">
                              {{ area.id_gerencia }}
                           </div>
                        </div>
                     </div>
                     <div class="content-list sucursal">
                        <div
                           class="content-list-item"
                           v-for="(sucursal, k) in sucursal_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ sucursal.nombre_sucursal }}
                           </div>
                           <div class="id">
                              {{ sucursal.id_sucursal }}
                           </div>
                        </div>
                     </div>
                     <div class="content-list turno">
                        <div
                           class="content-list-item"
                           v-for="(turno, k) in turno_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ turno.nombre_turno }}
                           </div>
                           <div class="id">
                              {{ turno.id_turno }}
                           </div>
                        </div>
                     </div>
                     <div class="content-list grupo">
                        <div
                           class="content-list-item"
                           v-for="(grupo, k) in grupo_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ grupo.nombre_grupo }}
                           </div>
                           <div class="id">
                              {{ grupo.id_grupo }}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="id-list-row below">
                  <div class="titles">
                     <div class="title cargo">
                        <span> CargoTrabajo </span>
                        <span>ID</span>
                     </div>
                     <div class="title sindicato">
                        <span> Sindicato </span>
                        <span>ID</span>
                     </div>
                     <div class="title user-type">
                        <span>TipoUsuario </span>
                        <span>ID</span>
                     </div>
                     <div class="title nacion">
                        <span>Nación</span>
                        <span>ID</span>
                     </div>
                  </div>
                  <div class="hr"></div>
                  <div class="content">
                     <div class="content-list cargo">
                        <div
                           class="content-list-item"
                           v-for="(cargo, k) in cargo_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ cargo.nombre_cargo }}
                           </div>
                           <div class="id">
                              {{ cargo.id_cargo }}
                           </div>
                        </div>
                     </div>
                     <div class="content-list sindicato">
                        <div
                           class="content-list-item"
                           v-for="(sindicato, k) in sindicato_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ sindicato.nombre_sindicato }}
                           </div>
                           <div class="id">
                              {{ sindicato.id_sindicato }}
                           </div>
                        </div>
                     </div>
                     <div class="content-list user-type">
                        <div
                           class="content-list-item"
                           v-for="(type, k) in user_type_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ type.nombre_tipo }}
                           </div>
                           <div class="id">
                              {{ type.id_tipo }}
                           </div>
                        </div>
                     </div>
                     <div class="content-list nacion">
                        <div
                           class="content-list-item"
                           v-for="(nacion, k) in nacion_list"
                           :key="k"
                        >
                           <div class="nombre">
                              {{ nacion.nombre_nacion }}
                           </div>
                           <div class="id">
                              {{ nacion.id_nacion }}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
/* import "@/assets/scss/InstructionsModal.scss"; */
import {
   GET_CARGOS,
   GET_GERENCIAS,
   GET_SUCURSAL,
   GET_WORK_SHIFTS,
   GET_NATIONS,
   GET_SOCIETIES,
   GET_SINDICATO,
   GET_PRIVILEGIOS,
   GET_GROUPS,
} from "@/apollo/queries";

export default {
   data() {
      return {
         id_empresa: null,
         creador: null,
         grupo_list: [],
         user_type_list: [],
         sindicato_list: [],
         sociedad_list: [],
         nacion_list: [],
         turno_list: [],
         sucursal_list: [],
         area_list: [],
         cargo_list: [],
      };
   },
   mounted() {
      this.id_empresa = this.$ls.get("user").empresa[0].id_empresa;
      this.getCargos();
      this.getAreas();
      this.getSucursal();
      this.getTurno();
      this.getNacion();
      this.getSociedad();
      this.getSindicato();
      this.getUserType();
      this.getGrupos();
   },
   methods: {
      close() {
         this.$emit("close");
      },
      getCargos() {
         this.$apollo
            .query({
               query: GET_CARGOS,
               variables: { id_empresa: this.id_empresa },
            })
            .then((res) => {
               var resp = res.data.cargos;
               this.cargo_list = resp;
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      getAreas() {
         this.$apollo
            .query({
               query: GET_GERENCIAS,
               variables: { id_empresa: this.id_empresa },
            })
            .then((res) => {
               var resp = res.data.gerencias;
               this.area_list = resp;
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      getSucursal() {
         this.$apollo
            .query({
               query: GET_SUCURSAL,
               variables: { id_empresa: this.id_empresa },
            })
            .then((res) => {
               var resp = res.data.sucursales;
               this.sucursal_list = resp;
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      getTurno() {
         this.$apollo
            .query({
               query: GET_WORK_SHIFTS,
               variables: { id_empresa: this.id_empresa },
            })
            .then((res) => {
               var resp = res.data.turnos;
               this.turno_list = resp;
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      getNacion() {
         this.$apollo
            .query({
               query: GET_NATIONS,
               variables: { id_empresa: this.id_empresa },
            })
            .then((res) => {
               var resp = res.data.naciones;
               this.nacion_list = resp;
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      getSociedad() {
         this.$apollo
            .query({
               query: GET_SOCIETIES,
               variables: { id_empresa: this.id_empresa },
            })
            .then((res) => {
               var resp = res.data.sociedades;
               this.sociedad_list = resp;
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      getSindicato() {
         this.$apollo
            .query({
               query: GET_SINDICATO,
               variables: { id_empresa: this.id_empresa },
            })
            .then((res) => {
               var resp = res.data.sindicatos;
               this.sindicato_list = resp;
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      getUserType() {
         this.$apollo
            .query({
               query: GET_PRIVILEGIOS,
               variables: { id_empresa: this.id_empresa },
            })
            .then((res) => {
               var resp = res.data.privilegios;
               this.user_type_list = resp;
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
      getGrupos() {
         var id_privilegio = this.$ls.get("user").privilegio[0].id_tipo;
         // Si el usuario que hace la carga remota es fundador no tendra
         // acceso a los grupos por lo que se cambia a 'Admnistrador'
         // para poder acceder a este dato
         var tipo_privilegio = id_privilegio == "1" ? "2" : id_privilegio;
         this.$apollo
            .query({
               query: GET_GROUPS,
               variables: {
                  id_empresa: this.id_empresa,
                  id_tipo_privilegio: tipo_privilegio,
               },
            })
            .then((res) => {
               var resp = res.data.listGruposAcciones;
               console.log(resp);
               if (resp.length > 0) {
                  this.grupo_list = resp;
               } else {
                  var obj = {
                     nombre_grupo: "-",
                     id_grupo: "-",
                  };
                  this.grupo_list.push(obj);
               }
            })
            .catch((err) => {
               console.log("err", err);
            });
      },
   },
};
</script>
