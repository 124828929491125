import { apolloClient } from "../../../vue-apollo";
import { GET_MODULES_ADMIN } from "@/apollo/queries";
export default {
    namespaced: true,
    state: { modules: [] },
    mutations: {
        SET_MODULES(state, res) {
            state.modules = res;
        },
    },
    getters: {
        userHasVideoModule(state) {
            const { modules } = state;
            return modules.some(module => module.moduloGeneral.id_modulo == "6");
        },
        userHasOnlyOneModule(state) {
            const { modules } = state;
            return (modules.length === 1) || 
                (modules.length === 2 && modules.some(module => module.moduloGeneral.id_modulo === "1"));
        },
    },
    actions: {
        async getModules({ commit }, data_) {
            let response = [];
            let data = [];
            await apolloClient.query({
                query: GET_MODULES_ADMIN,
                variables: data_
            }).then(res => {
                if (!res.loading) data = res.data.modulosAdmin;
                response = data;
                response.forEach(module => {
                    module.visitas = 0;
                });
            });

            // const response = [{
            //     "id_detalle": "41",
            //     "nombre_modulo": "Canal Corporativo",
            //     "imagen_url": null,
            //     "url_menu_activo": "https://storage.googleapis.com/c3-bucket-01/iconos_administrador/Icono_Canal_corporativo_Color_lwk.svg",
            //     "url_menu_inactivo": "https://storage.googleapis.com/c3-bucket-01/iconos_administrador/Icono_Canal_corporativo_Gris.svg",
            //     "moduloGeneral": {
            //         "id_modulo": "24",
            //         "nombre_modulo": "Canal Corporativo",
            //         "descripcion_modulo": "Modulo CC",
            //         "is_web": false,
            //         "ruta_admin": "/CanalCorporativo",
            //         "__typename": "ModulosAdmin"
            //     },
            //     "__typename": "ModuloDetallesAdmin",
            //     "visitas": 0
            // }];
            // response.forEach(module => {
            //     module.visitas = 0;
            // });
            commit("SET_MODULES", response);
        }
    },
};