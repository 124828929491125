import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { apolloClient } from "../vue-apollo";
import { GET_VIDEOS } from "../apollo/queries";
import newsModule from "./modules/news/storeNews";
import galleryModule from "./modules/gallery/storeGallery";
import usersModule from "./modules/users/storeUsers";
import cultureModule from "./modules/culture/storeCulture";
import recognizeModule from "./modules/recognize/storeRecognize";
import benefitModule from "./modules/benefit/storeBenefits";
import docsModule from "./modules/docs/storeDocs";
import safetyModule from "./modules/safety/storeSafety";
import encuestasModule from "./modules/encuestas/storeEncuestas";
import alertPushModule from "./modules/alertPush/storeAlertPush";
import transporteModule from "./modules/transporte/storeTransporte";
import adminHomeModule from "./modules/adminHome/storeHome.js";
import sidebar from "./modules/sidebar/storeSidebar.js";
import wallModule from "./modules/wall/storeWall";
import WallPerfiles from "./modules/wall/storeWallPerfiles";
import videoModule from "./modules/videos/storeVideos";
import videoCatModule from "./modules/videos/storeVideosCategories";
import organigramaModule from "./modules/organigrama/storeOrganigrama";
import canalCorporativoModule from "./modules/tv/storeTV";
import tvCorporativaModule from "./modules/tv/storeAddContent";
import homeCMSModule from "./modules/adminHome/storeHomeCMS"


export default new Vuex.Store({
    //state migrado a modularizacion de vuex

    state: {
        videos_empresa: [],
        video_empresa: null,
        // sidebar_open: false,
        // loading:null,
    },
    //mutations migradas a modularizacion de vuex
    mutations: {
        getVideos(state, res) {
            if (state.videos_empresa.length < 1) {
                state.videos_empresa = res;
            }
        },
        addVideo(state) {
            state.videos_empresa = [state.video_empresa, ...state.videos_empresa];
        },
    },
    //actions migradas a modularizacion de vuex
    actions: {
        async getVideosAc({ commit }, id) {
            const { data } = await apolloClient.query({
                query: GET_VIDEOS,
                variables: { id_empresa: id },
            });
            commit("getVideos", data.videos);
        },
        async addVideo({ commit }) {
            commit("addVideo");
        },
    },
    modules: {
        //integracion statemodularizado
        newsModule,
        galleryModule,
        usersModule,
        cultureModule,
        recognizeModule,
        benefitModule,
        docsModule,
        safetyModule,
        encuestasModule,
        alertPushModule,
        transporteModule,
        adminHomeModule,
        wallModule,
        WallPerfiles,
        sidebar,
        videoModule,
        videoCatModule,
        organigramaModule,
        canalCorporativoModule,
        tvCorporativaModule,
        homeCMSModule
    },
    //getters migrados a modularizacion de vuex
    getters: {},
});