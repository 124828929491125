import { 
    GET_SAFETY_DOCS, 
    GET_EMPRESAS_ORGANIGRAMA,
    GET_SAFETY_DOCS_CATEGORIES
 } from "../../../apollo/queries";
import { 
    DELETE_SAFETY_DOC, 
    EDIT_SAFETY_DOC, 
    ADD_SAFETY_DOC,
    CHANGE_SAFETY_DOC_STATUS,
    ADD_SAFETY_CATEGORY_DOC,
    EDIT_SAFETY_CATEGORY_DOC,
    DELETE_SAFETY_CATEGORY_DOC 
} from "../../../apollo/mutations";
import { apolloClient } from "../../../vue-apollo";
export default {
    namespaced: true,
    state: {
        documents: [],
        company: "",
        nombre: "",
        categorias: [],
        categoriasEmpresas: []
    },
    mutations: {
        SEARCH_BY_COMPANY(state, newSearch) {
            state.company = newSearch;
        },
        SEARCH_BY_NAME(state, newSearch) {
            state.nombre = newSearch;
        },
        SET_ORGS_LIST(state, organigramas) {
            state.documents = organigramas;
        },
        DELETE_ORG_FROM_LIST(state, id_org) {
            let index_org = state.documents.findIndex(
                (doc) => doc.id_documento == id_org
            );
            state.documents.splice(index_org, 1);
        },
        SET_UPDATE_ORGANIGRAMA(state, edited_doc) {
            // obtiene index del obj doc
            let index_org = state.documents.findIndex(
                (doc) => doc.id_documento == edited_doc.id_documento
            );
            state.documents[index_org] = edited_doc;
            
        },
        SET_ADD_ORGANIGRAMA(state, doc){
            state.documents.unshift(doc);
        },
        
        SET_EMPRESA_ORGANIGRAMA(state, empresas){
            state.categorias = empresas;
        },
        SET_EMPRESA_CATEGORIAS(state, empresas){
            state.categoriasEmpresas = empresas;
        },
        SET_CREAR_CATEGORIA_EMPRESA(state, empresa){
            state.categoriasEmpresas.unshift(empresa);
        },
        SET_UPDATE_CATEGORIA_EMPRESA(state, empresa){
            let index_org = state.categoriasEmpresas.findIndex(
                (categoria) => categoria.id_categoria === empresa.id_categoria
            );
            //console.log("index_org: editando: encontro elementros: ", state.categoriasEmpresas[index_org], "empresa: ", empresa);
            state.categoriasEmpresas[index_org] = empresa;
        },
        DELETE_EMPRESA_CATEGORIA(state, empresa){
            let index_org = state.categoriasEmpresas.findIndex(
                (categoria) => categoria.id_categoria == empresa.id_categoria
            );
            state.categoriasEmpresas.splice(index_org, 1);
        }
        

    },
    actions: {
        searchByName({ commit }, newSearch) {
            commit("SEARCH_BY_NAME", newSearch);
        },
        searchByCompany({ commit }, newSearch) {
            commit("SEARCH_BY_COMPANY", newSearch);
        },
        async getOrganigramas({ commit }, data_) {
            let response = [];
            let params = {
                query: GET_SAFETY_DOCS,
                variables: data_
            };
            await apolloClient.query(params).then(res => {
                console.log('organigramas', res);
                if (!res.loading) response = res.data.AllDocumentosAdmin;
            });
            commit('SET_ORGS_LIST', response);
        },
        async crearOrganigrama({ commit }, data_) {
            let response = null;
            let params = {
                mutation: ADD_SAFETY_DOC,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('crearOrganigrama', res.data.agregarDocumento);
                if (!res.loading) response = res.data.agregarDocumento;
            });
            return new Promise((resolve)=>{
                if(response  != null){
                    //console.log("pasa a distinto de null")
                    resolve(response);
                    commit("SET_ADD_ORGANIGRAMA", response)
                } else {
                    resolve(false);
                }
            });
        },
        async deleteOrganigrama({ commit }, data_) {
            let response = null;
            let params = {
                mutation: DELETE_SAFETY_DOC,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('delete organigrama', res);
                if (!res.loading) response = res.data.eliminarDocumento;
            
            });
            return new Promise((resolve) => {
                if (response != null) {
                    commit('DELETE_ORG_FROM_LIST', response);
                    resolve(response);
                }
                else {
                    resolve(false)
                }
                
            })
        },
        async editarOrganigrama({ commit }, data_) {
            let response = null;
            let params = {
                mutation: EDIT_SAFETY_DOC,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('edit organigrama', res.data.editarDocumento);
                if (!res.loading) response = res.data.editarDocumento;
            });
            return new Promise((resolve)=>{
                if(response  != null){
                    //console.log("pasa a distinto de null")
                    resolve(response);
                    commit("SET_UPDATE_ORGANIGRAMA", response)
                } else {
                    resolve(false);
                }
            });
        },
        async cambiaEstadoOrganigrama({ commit }, data_) {
            let response = null;
            let params = {
                mutation: CHANGE_SAFETY_DOC_STATUS,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                //console.log('change status organigrama', res.data.cambiarEstadoDocumento);
                if (!res.loading) response = res.data.cambiarEstadoDocumento;
            });
            return new Promise((resolve) => {
                if(response  != null){
                    //console.log("pasa a distinto de null")
                    resolve(response);
                    commit("SET_UPDATE_ORGANIGRAMA", response)
                } else {
                    resolve(false);
                }
            })
        },
        async getEmpresasCategoria({ commit }, data_){
            let response = [];
            let params = {
                query: GET_EMPRESAS_ORGANIGRAMA,
                variables: data_
            }
            try {
                await apolloClient.query(params).then(res => {
                    console.log('getEmpresasCategoria', res.data.CategoriasDocumentos);
                    if (!res.loading) response = res.data.CategoriasDocumentos;
                });
            } catch (e) {
                console.log("Falla metodo getEmpresasCategoria: ", e);
                response = null;
            }
            return new Promise((resolve)=>{
                if(response  != null){
                    //console.log("pasa a distinto de null")
                    resolve(response);
                    commit("SET_EMPRESA_ORGANIGRAMA", response)
                } else {
                    resolve(null);
                }
            });
        },
        // CRUD EMPRESAS ("CATEGORIAS ORGANIGRAMAS")
        async crearEmpresaCategoria({ commit }, data_) {
            let response = null;
            let params = {
                mutation: ADD_SAFETY_CATEGORY_DOC,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('crearEmpresaCategoria', res.data.agregarCategoriaDocumento);
                if (!res.loading) response = res.data.agregarCategoriaDocumento;
            });
            return new Promise((resolve)=>{
                if(response  != null){
                    //console.log("pasa a distinto de null")
                    resolve(response);
                    commit("SET_CREAR_CATEGORIA_EMPRESA", response);
                } else {
                    resolve(false);
                }
            });
        },
        async editarEmpresaCategoria({ commit }, data_) {
            let response = null;
            let params = {
                mutation: EDIT_SAFETY_CATEGORY_DOC,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('edit organigrama', res.data.editarCategoriaDocumento);
                if (!res.loading) response = res.data.editarCategoriaDocumento;
            });
            return new Promise((resolve)=>{
                if(response  != null){
                    //console.log("pasa a distinto de null")
                    resolve(response);
                    commit("SET_UPDATE_CATEGORIA_EMPRESA", response);
                } else {
                    resolve(false);
                }
            });
        },
        async deleteEmpresaCategoria({ commit }, data_) {
            let response = null;
            let params = {
                mutation: DELETE_SAFETY_CATEGORY_DOC,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('delete deleteEmpresaCategoria', res);
                if (!res.loading) response = res.data.eliminarCategoriaDocumento;
                
            });
            return new Promise((resolve) => {
                if (response != null) {
                    commit('DELETE_EMPRESA_CATEGORIA', response);
                    resolve(response);
                }
                else{
                    resolve(false);
                }
                
            })
        },
        async getCategoriaForEmpresa({ commit }, data_){
            let response = [];
            let params = {
                query: GET_SAFETY_DOCS_CATEGORIES,
                variables: data_
            }
            try {
                await apolloClient.query(params).then(res => {
                    console.log('getEmpresasCategoria', res.data.CategoriasDocumentosAdmin);
                    if (!res.loading) response = res.data.CategoriasDocumentosAdmin;
                });
            } catch (e) {
                console.log("Falla metodo getEmpresasCategoria: ", e);
                response = null;
            }
            return new Promise((resolve)=>{
                if(response  != null){
                    //console.log("pasa a distinto de null")
                    resolve(response);
                    commit("SET_EMPRESA_CATEGORIAS", response)
                } else {
                    resolve(false);
                }
            });
        },
    },
    getters: {
        getOrganigramaById: (state) => (id) => {
            const { documents } = state;
            const document = documents.find((document) => document.id_documento == id);
            //console.log("Organigrama: ", documents);
            return document;
            // const { GetPantallaList } = state;
            // const screen = GetPantallaList.find((screen) => screen.id_pantalla == id);
            // return screen;
        },
    },
};