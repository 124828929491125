import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import "./assets/scss/main.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faStar as farStar,
    faHeart as farHeart,
    faTrashAlt as farTrashAlt

} from "@fortawesome/free-regular-svg-icons";
import { CHECK_TOKEN } from "@/apollo/mutations";
import {
    faPlay,
    faChevronRight,
    faChevronLeft,
    faBold,
    faItalic,
    faSortAlphaDown,
    faSortAlphaUp,
    faUnderline,
    faList,
    faListOl,
    faQuestion,
    faArrowDown,
    faCaretDown,
    faSort,
    faCaretUp,
    faImages,
    faImage,
    faKey,
    faLink,
    faTimes,
    faGripVertical,
    faThumbtack,
    faAngleLeft,
    faAngleRight,
    faStar,
    faCheck,
    faBan,
    faUpload,
    faSearch,
    faPen,
    faTrashAlt,
    faEye,
    faAngleDown,
    faAngleUp,
    faExclamationCircle,
    faPlusCircle,
    faDownload,
    faPlus,
    faExclamation,
    faUser,
    faExpand,
    faCommentDots,
    faBell,
    faPaperPlane,
    faEllipsisV,
    faComment,
    faBars,
    faCalendarDay,
    faHeart,
    faFileAlt,
} from "@fortawesome/free-solid-svg-icons";

// import { faHeart } from "@fortawesome/free-regular-svg-icons";
import VueApollo from "vue-apollo";
import axios from "axios";
import VueAxios from "vue-axios";
import Storage from "vue-ls";
import { createProvider } from "./vue-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import VCalendar from "v-calendar";
import VueCroppie from "vue-croppie";
import VueTippy, { TippyComponent } from "vue-tippy";
import "croppie/croppie.css";
import VueApexCharts from "vue-apexcharts";
import VueProgressBar from "vue-progressbar";
import VueMask from "v-mask";
import VueSplide from "@splidejs/vue-splide";
import Paginate from 'vuejs-paginate';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import ProgressBar from 'vuejs-progress-bar'

Vue.component('v-select', vSelect)
Vue.use(VueToast);
Vue.component('paginate', Paginate)
const options = {
    namespace: "vuejs__", // key prefix
    name: "ls", // name variable Vue.[ls] or this.[$ls],
    storage: "local", // storage name session, local, memory
};
const options_vue_progressBar = {
    color: "#bffaf3",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "left",
    inverse: false,
};

library.add(
    faPlay,
    faChevronRight,
    faChevronLeft,
    faBold,
    faItalic,
    faSortAlphaDown,
    faSortAlphaUp,
    faUnderline,
    faList,
    faListOl,
    faQuestion,
    faArrowDown,
    faCaretDown,
    faSort,
    faCaretUp,
    faImages,
    faImage,
    faKey,
    faLink,
    faTimes,
    faGripVertical,
    faThumbtack,
    faHeart,
    faAngleLeft,
    faAngleRight,
    faStar,
    faCalendarDay,
    faCheck,
    faBan,
    faUpload,
    faSearch,
    faPen,
    faTrashAlt,
    faEye,
    faAngleDown,
    faAngleUp,
    faExclamationCircle,
    faPlusCircle,
    faDownload,
    faPlus,
    faExclamation,
    faUser,
    faExpand,
    faCommentDots,
    faBell,
    faPaperPlane,
    faEllipsisV,
    faComment,
    faBars,
    farStar,
    faFileAlt,
    farHeart,
    farTrashAlt
);

Vue.use(VueMask);
Vue.use(VueApollo);
Vue.use(VueAxios, axios);
Vue.use(Storage, options);
Vue.use(VCalendar);
Vue.use(VueApexCharts);
Vue.use(VueCroppie);
Vue.use(VueAxios, axios);
Vue.use(VueTippy, {
    defaultProps: { placement: "top" },
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false,
            },
            hide: { enabled: false },
        },
    },
});
Vue.use(VueProgressBar, options_vue_progressBar);
Vue.use(VueSplide);
Vue.use(ProgressBar);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("tippy", TippyComponent);
Vue.component("apexchart", VueApexCharts);

Vue.prototype.$noResults = "No se han encontrado resultados para tu búsqueda";

const cache = new InMemoryCache({
    /* Person: {
      // In a user account system, the combination of a person's name AND email
      // address might uniquely identify them.
      keyFields: ["name", "email"],
    }, */
    User: {
        keyFields: [],
    },
});

Vue.config.productionTip = false;

Vue.mixin({
    methods: {
        redirectToLoneModule(modules) {
            if ((modules.length === 1) || (modules.length === 2 && modules.some(m => m.moduloGeneral.id_modulo === "1"))) {
				const onlyModule = modules.find(m => m.moduloGeneral.id_modulo != "1")
				if (onlyModule) {
                    this.$router.push({ path: onlyModule.moduloGeneral.ruta_admin });
				}
			}
        },

        checkIfUserIsLogged() {
            var current_route = this.$route.name;
            if (
                this.$ls.get("user") == null ||
                Object.keys(this.$ls.get("user")).length === 0
            ) {
                if (
                    current_route != "Login" &&
                    current_route != "Login-token"
                    //&& o.path != "/downloads"
                ) {
                    this.$router.push({ name: "Login" });
                }
                return false;
            } else {
                return true;
            }
        },
        checkMyLogin(o) {
            var current_route = o.name;
            //console.log("cheking")
            if (this.$ls.get("token") != null) {
                if (current_route == "Login" || current_route == "") {
                    this.$router.push({ name: "Inicio" });
                }
                //console.log(this.$ls.get("token"), this.$ls.get("user"));
                this.loginCheckToken(
                    this.$ls.get("token").token,
                    this.$ls.get("token").uuid
                );
            } else {
                if (
                    current_route != "Login" &&
                    current_route != "Login-token" &&
                    o.path.toUpperCase() != "/DOWNLOADS"
                ) {
                    this.$router.push({ name: "Login" });
                }
            }
        },
        loginCheckToken(t, u) {
            this.$apollo
                .mutate({
                    mutation: CHECK_TOKEN,
                    variables: {
                        token: t,
                        uuid: u,
                    },
                })
                .then((res) => {
                    var resp = res.data.checkToken;
                    if (resp == null) {
                        var current_route = this.$route.name;
                        this.$ls.remove("token");
                        this.$ls.remove("user");
                        //console.log("check");
                        if (current_route != "Login" && current_route != "Login-token") {
                            this.$router.push({ name: "Login" });
                        }
                    } else {
                        //console.log("todo ok")
                    }
                })
                .catch((err) => {
                    console.log("Error en loginCheckToken", err);
                });
        },
        dateFormatymd(date) {
            if (date != "Inicio" && date != "Término") {
                var date_day = new Date(date);
                var date_f = new Date(date);
                date_f.setDate(date_f.getDate() + 1);
                var month = date_f.getMonth();
                var day = date_f.getDate();
                return `${day < 10 ? `0${day}` : day}/${month + 1 < 10 ? `0${month + 1}` : month + 1
                    }/${date_day.getFullYear()}`;
            } else {
                return date;
            }
        },
        todayFormatymd() {

            var date_day = new Date();
            var date_f = new Date();
            date_f.setDate(date_f.getDate());
            var month = date_f.getMonth();
            var day = date_f.getDate();
            return `${day < 10 ? `0${day}` : day}/${month + 1 < 10 ? `0${month + 1}` : month + 1
                }/${date_day.getFullYear()}`;
        },
    },
});

// Directiva personalizada para detectar clicks afuera de elementos
Vue.directive("click-outside", {
    bind: function (element, binding, vnode) {
        //check that click was outside the el and his children
        element.clickOutsideEvent = function (event) {
            // and if it did, call method provided in attribute value
            if (!(element === event.target || element.contains(event.target))) {
                vnode.context[binding.expression](event);
                // binding.value(); run the arg
            }
        };
        document.body.addEventListener("click", element.clickOutsideEvent);
    },
    unbind: function (element) {
        document.body.removeEventListener("click", element.clickOutsideEvent);
    },
});

new Vue({
    router,
    store,
    cache,
    apolloProvider: createProvider(),
    watch: {
        $route: {
            handler: (to) => {
                document.title = to.meta.title || "LinkiWork";
            },
            immediate: true,
        },
    },
    render: (h) => h(App),
}).$mount("#app");