const homeList = [
  {
    path: "/inicio",
    name: "Inicio",
    component: (InicioAdmin) =>
      require(["@/views/ADMIN/Home.vue"], InicioAdmin),
    redirect: { name: "inicio-dash" },
    children: [
      {
        path: "dashboard",
        name: "inicio-dash",
        component: (InicioAdminDash) =>
          require(["@/components/HomeAdmin/Home.vue"], InicioAdminDash),
      },
      {
        path: "personalizacion",
        name: "inicio-admin",
        component: (InicioAdminIntranet) =>
          require([
            "@/components/HomePersonalizacion/PersonalizacionIntranet.vue",
          ], InicioAdminIntranet),
        children: [
          {
            path: "menu",
            name: "menu-admin",
            component: (MenuPrincipal) =>
              require(["@/components/HomePersonalizacion/MenuPrincipal.vue"], MenuPrincipal),
          },
          {
            path: "interfaz",
            name: "interfaz-admin",
            component: (Interfaz) =>
              require([
                "@/components/HomePersonalizacion/Interfaz.vue",
              ], Interfaz),
          },
          {
            path: "editar",
            name: "editar-admin",
            component: (Editar) =>
              require([
                "@/components/HomePersonalizacion/Editar.vue",
              ], Editar),
          },
          {
            path: "organizar",
            name: "organizar-admin",
            component: (Organizar) =>
              require([
                "@/components/HomePersonalizacion/Organizar.vue",
              ], Organizar),
          },
        ],
      },
    ],
  },
];

export default {
  homeList,
};
