const canalCorporativoList = [
  {
    path: "/CanalCorporativo",
    name: "CanalCorporativo",
    component: (CanalCorporativo) =>
      require(["@/views/ADMIN/TV.vue"], CanalCorporativo),
    redirect: { name: "CanalCorporativo-dashboard" },

    children: [
      {
        path: "dashboard",
        name: "CanalCorporativo-dashboard",
        component: (CanalCorporativoDash) =>
          require(["@/components/tv/TvDashboard.vue"], CanalCorporativoDash),
      },
      {
        path: "canales",
        name: "CanalCorporativo-canales",
        component: (CanalCorporativoCanales) =>
          require(["@/components/tv/HomeCanales.vue"], CanalCorporativoCanales),
      },
      {
        path: "contenido-add/:id",
        name: "CanalCorporativo-addContenido",
        component: (AddLayoutCanalCorporativo) =>
          require([
            "@/components/tv/AgregarLayout.vue",
          ], AddLayoutCanalCorporativo),
      },
      {
        path: "contenido-edit/:id",
        name: "CanalCorporativo-editContenido",
        component: (AddLayoutCanalCorporativo) =>
          require([
            "@/components/tv/AgregarLayout.vue",
          ], AddLayoutCanalCorporativo),
      },
      {
        path: "listado-videos/:id",
        name: "CanalCorporativo-listadoVideos",
        props: true,
        component: (CanalCorporativoListadoVideos) =>
          require([
            "@/components/tv/AgregarVideo.vue",
          ], CanalCorporativoListadoVideos),
      },
      {
        path: "edit-videos/:id",
        name: "CanalCorporativo-editVideos",
        props: true,
        component: (CanalCorporativoListadoVideos) =>
          require([
            "@/components/tv/AgregarVideo.vue",
          ], CanalCorporativoListadoVideos),
      },
      {
        path: "listado-banners/:id",
        name: "CanalCorporativo-listadoBanners",
        props: true,
        component: (CanalCorporativoListadoBanners) =>
          require([
            "@/components/tv/AgregarBanner.vue",
          ], CanalCorporativoListadoBanners),
      },
      {
        path: "edit-banners/:id",
        name: "CanalCorporativo-editBanners",
        props: true,
        component: (CanalCorporativoListadoBanners) =>
          require([
            "@/components/tv/AgregarBanner.vue",
          ], CanalCorporativoListadoBanners),
      },
      {
        path: "canales-add",
        name: "CanalCorporativo-add-canales",
        component: (CanalCorporativoAddCanales) =>
          require([
            "@/components/tv/AgregarCanal.vue",
          ], CanalCorporativoAddCanales),
      },
      {
        path: "canales-edit/:id",
        name: "CanalCorporativo-edit-canales",
        props: true,
        component: (CanalCorporativoEditCanales) =>
          require([
            "@/components/tv/AgregarCanal.vue",
          ], CanalCorporativoEditCanales),
      },
      {
        path: "pantallas",
        name: "CanalCorporativo-pantallas",
        component: (CanalCorporativoPantallas) =>
          require([
            "@/components/tv/HomePantallas.vue",
          ], CanalCorporativoPantallas),
      },
      {
        path: "pantallas-add",
        name: "CanalCorporativo-add-pantallas",
        component: (CanalCorporativoAddPantallas) =>
          require([
            "@/components/tv/AgregarPantallas.vue",
          ], CanalCorporativoAddPantallas),
      },
      {
        path: "pantallas-edit/:id",
        name: "CanalCorporativo-edit-pantallas",
        props: true,
        component: (CanalCorporativoEditPantallas) =>
          require([
            "@/components/tv/AgregarPantallas.vue",
          ], CanalCorporativoEditPantallas),
      },
    ],
  },
];

export default {
  canalCorporativoList,
};
