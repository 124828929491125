import { apolloClient } from "../../../vue-apollo";


import {
    GET_USER,
    GET_USERS,
    GET_USER_PAGINATION,
    GET_USER_TOTAL_PAGINACION,
    GET_SINDICATO,
    GET_CARGOS,
    GET_GERENCIAS,
    GET_SUCURSAL,
    GET_GROUPS,
    GET_SOCIETIES,
    GET_NATIONS,
    GET_WORK_SHIFTS
} from "../../../apollo/queries";
import {
    DELETE_USER,
    EDIT_USER
} from "../../../apollo/mutations";


export default {
    namespaced: true,
    state: {
        users_list: [],
        paginationArr: [],
        userArr: [],
        positionArr: [],
        areaArr: [],
        workplaceArr: [],
        shiftArr: [],
        societyArr: [],
        syndicateArr: [],
        nationArr: [],
        groupArr: [],
        user_edit: {}
    },
    mutations: {
        setPositions(state, resp) {
            state.positionArr = resp;
        },
        setAreas(state, resp) {
            state.areaArr = resp;
        },
        setWorkplaces(state, resp) {
            state.workplaceArr = resp;
        },
        setShifts(state, resp) {
            state.shiftArr = resp;
        },
        setSocieties(state, resp) {
            state.societyArr = resp;
        },
        setSyndicates(state, resp) {
            state.syndicateArr = resp;
        },
        setNations(state, resp) {
            state.nationArr = resp;
        },
        setGroups(state, resp) {
            state.groupArr = resp;
        },
        setUsersByPagination(state, resp) {
            state.userArr = resp;
        },
        setPagination(state, resp) {
            state.paginationArr = resp;
        },
        setUsers(state, resp) {
            if (state.users_list.length < 1) {
                state.users_list = resp;
            }
        },
        setNewUser(state, user) {
            state.userArr.unshift(user);
        },
        deleteUserFromList(state, user_id) {
            console.log('SE BORRA DE LA LISTA', user_id);
            console.log('USER LIST', state.userArr);
            let found_index = state.userArr.findIndex(user => user.id_usuario == user_id);
            console.log('USUARIO ENCONTRADO', found_index);
            state.userArr.splice(found_index, 1);
        },
        editUserList(state, user) {
            console.log('USUARIO', user);
            console.log('USUARIO LIST', state.userArr);
            let user_index = state.userArr.findIndex(user_ => user_.id_usuario == user.id_usuario);
            if (user_index != undefined) {
                console.log('USUARIO Encontrador', state.userArr[user_index]);
                if (state.userArr.length > 0) Object.assign(state.userArr[user_index], user)

            }
        },
        SET_USER(state, user) {
            console.log('Usuario from ws', user);
            Object.assign(state.user_edit, user);
        }

    },
    actions: {
        // Obtener usuarios
        async getUsersList({ commit }, id) {
            const { data } = await apolloClient.query({
                query: GET_USERS,
                variables: { id_empresa: id.empresa, id_usuario: id.usuario },
            });
            commit("setUsers", data.usuarios);
        },
        async getUserPagination({ commit }, data_) {
            let response = [];
            let params = {
                query: GET_USER_PAGINATION,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                if (!res.loading) response = res.data.TotalUsuariosPaginacion;
            });
            commit("setPagination", response);
        },
        async getUsersPaginated({ commit }, data_) {
            let response = [];
            let params = {
                query: GET_USER_TOTAL_PAGINACION,
                variables: data_,
                cachepolicy: 'network-only'
            }
            await apolloClient.query(params).then(res => {
                if (!res.loading) response = res.data.listUsersPaginacion;
                //console.log('USER PAGINATION', res);
            });
            commit("setUsersByPagination", response);
        },
        async getPositionsQuery({ commit }, data_) {
            let response = [];
            let data = [];
            let params = {
                query: GET_CARGOS,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                //if (!res.loading) response = res.data.listUsersPaginacion;
                //console.log('QUERY_POSITIONS', res);
                if (!res.loading) data = res.data.cargos;
                data.forEach(pos => {
                    let position = {
                        id: pos.id_cargo,
                        name: pos.nombre_cargo
                    }
                    response.push(position);
                });
            });
            commit("setPositions", response);
        },
        async getAreasQuery({ commit }, data_) {
            let response = [];
            let data = [];
            let params = {
                query: GET_GERENCIAS,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                //console.log('QUERY_AREAS', res);
                if (!res.loading) data = res.data.gerencias;
                data.forEach(ar => {
                    let area = {
                        id: ar.id_gerencia,
                        name: ar.nombre_gerencia
                    }
                    response.push(area);
                });
            });
            commit("setAreas", response);
        },
        async getWorkplaceQuery({ commit }, data_) {
            let response = [];
            let data = [];
            let params = {
                query: GET_SUCURSAL,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                //console.log('QUERY_WORKPLACE', res);
                if (!res.loading) data = res.data.sucursales;
                data.forEach(su => {

                    let sucursal = {
                        id: su.id_sucursal,
                        name: su.nombre_sucursal
                    }
                    response.push(sucursal);
                });
            });
            commit("setWorkplaces", response);
        },
        async getShiftsQuery({ commit }, data_) {
            let response = [];
            let data = [];
            let params = {
                query: GET_WORK_SHIFTS,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                //console.log('getShifts', res);
                if (!res.loading) data = res.data.turnos;
                data.forEach(sh => {
                    let shift = {
                        id: sh.id_turno,
                        name: sh.nombre_turno
                    }
                    response.push(shift);
                });
            });
            commit("setShifts", response);
        },
        async getSocietiesQuery({ commit }, data_) {
            let response = [];
            let data = [];
            let params = {
                query: GET_SOCIETIES,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                //console.log('getSocieties', res);
                if (!res.loading) data = res.data.sociedades;
                data.forEach(so => {
                    let society = {
                        id: so.id_sociedad,
                        name: so.nombre_sociedad
                    }
                    response.push(society);
                });
            });
            commit("setSocieties", response);
        },
        async getSyndicatesQuery({ commit }, data_) {
            let response = [];
            let data = [];
            let params = {
                query: GET_SINDICATO,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                //console.log('getSyndicates', res);
                if (!res.loading) data = res.data.sindicatos;
                data.forEach(sin => {
                    let syndicate = {
                        id: sin.id_sindicato,
                        name: sin.nombre_sindicato
                    }
                    response.push(syndicate);
                });
            });
            commit("setSyndicates", response);
        },
        async getNationsQuery({ commit }, data_) {
            let response = [];
            let data = [];
            let params = {
                query: GET_NATIONS,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                //console.log('getNations', res);
                if (!res.loading) data = res.data.naciones;
                data.forEach(nat => {
                    let nations = {
                        id: nat.id_nacion,
                        name: nat.nombre_nacion
                    }
                    response.push(nations);
                });
            });
            commit("setNations", response);
        },
        async getGroupsQuery({ commit }, data_) {
            let response = [];
            let data = [];
            let params = {
                query: GET_GROUPS,
                variables: data_
            }
            await apolloClient.query(params).then(res => {
                ////console.log('getGroups', res);
                if (!res.loading) data = res.data.listGruposAcciones;
                data.forEach(gr => {
                    let group = {
                        id: gr.id_grupo,
                        name: gr.nombre_grupo
                    }
                    response.push(group);
                });
            });
            commit("setGroups", response);
        },
        async deleteUser({ commit }, data_) {
            console.log('data para el delete');
            let response = '';
            let data = [];
            let params = {
                mutation: DELETE_USER,
                variables: data_
            }
            await apolloClient.mutate(params).then(res => {
                console.log('deleteUser', res);
                if (!res.loading) data = res.data.eliminarUsuario;
                response = data.id_usuario;
            });
            commit("deleteUserFromList", response);
        },
        async editUser({ commit }, data_) {
            console.log('data para el edit');
            let response = '';
            let data = [];
            let params = {
                mutation: EDIT_USER,
                variables: data_
            }
            await apolloClient.mutate(params).then(res => {
                console.log('deleteUser', res);
                if (!res.loading) data = res.data.editUsuario;
                response = data;
            });
            commit("editUserList", response);
        },
        async getUser({ commit }, data_) {
            console.log('getting user');
            let response = {};
            this.show_spinner = true;
            await apolloClient.query({
                query: GET_USER,
                variables: data_,
                fetchPolicy: 'network-only'
            }).then(res => {
                console.log('USER FROM WS');
                if (!res.loading) response = res.data.usuario;
            })
            commit("SET_USER", response);
        },
    },
};