export default {
  namespaced: true,
  state: {
    buscarMenu: [
      {
        id_header: "1",
        nombre_header: "Header 1",
        color_activo: "#4BBE61",
        color_inactivo: "#b6e3fc",
        descripcion: "Este es un Header",
        url_icono: null,
        orden: "1",
        id_empresa_fk: "2",
        id_estado_fk: "1",
        menu_elementos: [
          {
            id_elemento: "1",
            id_header_fk: "1",
            nombre_elementos: "Elemento 1 (H1)",
            color_activo: "#4bbe6130",
            color_inactivo: "#999BA680",
            descripcion: "esto es un elemento",
            url_icono: null,
            orden: "1",
            tipo_url: null,
            url_menu: null,
            id_empresa_fk: "2",
            id_estado_fk: "1",
            levThree: [],
          },
          {
            id_elemento: "2",
            id_header_fk: "1",
            nombre_elementos: "Elemento 2 (H1)",
            color_activo: "#4bbe6130",
            color_inactivo: "#999BA680",
            descripcion: "este es un elemento",
            url_icono: null,
            orden: "2",
            tipo_url: null,
            url_menu: null,
            id_empresa_fk: "2",
            id_estado_fk: "1",
            levThree: [
              {
                id_relacion: "5",
                id_elemento_fk: "2",
                id_relacion_fk: null,
                nombre_relacion: "Ejemplo 2-4",
                color_activo: "#4bbe6130",
                color_inactivo: "#999BA680",
                descripcion: null,
                nivel: "3",
                orden: "4",
                tipo_url: null,
                url_menu: null,
                id_empresa_fk: "2",
                id_estado_fk: "1",
                levFour: [],
              },
              {
                id_relacion: "4",
                id_elemento_fk: "2",
                id_relacion_fk: null,
                nombre_relacion: "Ejemplo 2-3",
                color_activo: "#4bbe6130",
                color_inactivo: "#999BA680",
                descripcion: null,
                nivel: "3",
                orden: "3",
                tipo_url: null,
                url_menu: null,
                id_empresa_fk: "2",
                id_estado_fk: "1",
                levFour: [],
              },
              {
                id_relacion: "2",
                id_elemento_fk: "2",
                id_relacion_fk: null,
                nombre_relacion: "Ejemplo 2-2",
                color_activo: "#4bbe6130",
                color_inactivo: "#999BA680",
                descripcion: null,
                nivel: "3",
                orden: "2",
                tipo_url: null,
                url_menu: null,
                id_empresa_fk: "2",
                id_estado_fk: "1",
                levFour: [],
              },
              {
                id_relacion: "1",
                id_elemento_fk: "2",
                id_relacion_fk: null,
                nombre_relacion: "Ejemplo 2-1",
                color_activo: "#4bbe6130",
                color_inactivo: "rojo",
                descripcion: null,
                nivel: "3",
                orden: "1",
                tipo_url: null,
                url_menu: null,
                id_empresa_fk: "2",
                id_estado_fk: "1",
                levFour: [],
              },
            ],
          },
          {
            id_elemento: "13",
            id_header_fk: "1",
            nombre_elementos: "Elemento 4 (H1)",
            color_activo: '#4bbe6130',
            color_inactivo: '#999BA680',
            descripcion: null,
            url_icono: null,
            orden: "4",
            tipo_url: null,
            url_menu: null,
            id_empresa_fk: "2",
            id_estado_fk: "1",
            levThree: [],
          },
          {
            id_elemento: "16",
            id_header_fk: "1",
            nombre_elementos: "Elemento 3 (H1)",
            color_activo: '#4bbe6130',
            color_inactivo: '#999BA680',
            descripcion: null,
            url_icono: null,
            orden: "3",
            tipo_url: null,
            url_menu: null,
            id_empresa_fk: "2",
            id_estado_fk: "1",
            levThree: [],
          },
        ],
      },
      {
        id_header: "2",
        nombre_header: "Header 2",
        color_activo: "#357BF3",
        color_inactivo: "#ed80cc",
        descripcion: "Este es un Header",
        url_icono: null,
        orden: "2",
        id_empresa_fk: "2",
        id_estado_fk: "1",
        menu_elementos: [
          {
            id_elemento: "3",
            id_header_fk: "2",
            nombre_elementos: "Elemento 1 (H2)",
            color_activo: "#357BF330",
            color_inactivo: "Azul",
            descripcion: "este es un elemento",
            url_icono: null,
            orden: "1",
            tipo_url: null,
            url_menu: null,
            id_empresa_fk: "2",
            id_estado_fk: "1",
            levThree: [],
          },
          {
            id_elemento: "4",
            id_header_fk: "2",
            nombre_elementos: "Elemento 2 (H2)",
            color_activo: "#357BF330",
            color_inactivo: "Azul",
            descripcion: "este es un elemento",
            url_icono: null,
            orden: "2",
            tipo_url: null,
            url_menu: null,
            id_empresa_fk: "2",
            id_estado_fk: "1",
            levThree: [],
          },
        ],
      },
      {
        id_header: "38",
        nombre_header: "Header 5",
        color_activo: "#EC206B",
        color_inactivo: '#999BA680',
        descripcion: null,
        url_icono: null,
        orden: "5",
        id_empresa_fk: "2",
        id_estado_fk: "1",
        menu_elementos: [
          {
            id_elemento: "23",
            id_header_fk: "38",
            nombre_elementos: "Elemento 1 (H5)",
            color_activo: '#EC206B30',
            color_inactivo: '#999BA680',
            descripcion: null,
            url_icono: null,
            orden: "1",
            tipo_url: null,
            url_menu: null,
            id_empresa_fk: "2",
            id_estado_fk: "1",
            levThree: [
              {
                id_relacion: "21",
                id_elemento_fk: "23",
                id_relacion_fk: null,
                nombre_relacion: "Ejemplo 5-2",
                color_activo: '#EC206B30',
                color_inactivo: null,
                descripcion: null,
                nivel: "3",
                orden: "2",
                tipo_url: null,
                url_menu: null,
                id_empresa_fk: "2",
                id_estado_fk: "1",
                levFour: [],
              },
              {
                id_relacion: "19",
                id_elemento_fk: "24",
                id_relacion_fk: null,
                nombre_relacion: "Ejemplo 5-1",
                color_activo: '#EC206B30',
                color_inactivo: null,
                descripcion: null,
                nivel: "3",
                orden: "1",
                tipo_url: null,
                url_menu: null,
                id_empresa_fk: "2",
                id_estado_fk: "1",
                levFour: [
                  {
                    id_relacion: "20",
                    id_elemento_fk: "25",
                    id_relacion_fk: "19",
                    nombre_relacion: "Ejemplo 5-1-1",
                    color_activo: '#EC206B30',
                    color_inactivo: null,
                    descripcion: null,
                    nivel: "4",
                    orden: "1",
                    tipo_url: null,
                    url_menu: null,
                    id_empresa_fk: "2",
                    id_estado_fk: "1",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id_header: "4",
        nombre_header: "Header 4",
        color_activo: "#52CEC1",
        color_inactivo: null,
        descripcion: null,
        url_icono: null,
        orden: "4",
        id_empresa_fk: "2",
        id_estado_fk: "1",
        menu_elementos: [],
      },
      {
        id_header: "3",
        nombre_header: "Header 3",
        color_activo: "#F6C700",
        color_inactivo: null,
        descripcion: null,
        url_icono: null,
        orden: "3",
        id_empresa_fk: "2",
        id_estado_fk: "1",
        menu_elementos: [
          {
            id_elemento: "33",
            id_header_fk: "",
            nombre_elementos: "",
            color_activo: '#F6C70030',
            color_inactivo: '#999BA680',
            descripcion: null,
            url_icono: null,
            orden: "1",
            tipo_url: null,
            url_menu: null,
            id_empresa_fk: "2",
            id_estado_fk: "1",
            levThree: [
            ],
          },
        ],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};
