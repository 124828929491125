import Vue from "vue";
import VueRouter from "vue-router";
import home from "./admin/home/routesHome";
import profile from "./profile/routesProfile";
import login from "./login/routesLogin";
import benefit from "./admin/benefit/routesBenefit";
import birthday from "./admin/birthday/routesBirthday";
import covid from "./admin/covid/routesCovid";
import culture from "./admin/culture/routesCulture";
import gallery from "./admin/gallery/routesGallery";

import users from "./admin/users/routesUsers";
import library from "./admin/library/routesLibrary";
import muro from "./admin/muro/routesMuro";
import news from "./admin/news/routesNews";
import organigrama from "./admin/organigrama/routesOrganigrama";
import recognize from "./admin/recognize/routesRecognize";
import seguridad from "./admin/seguridad/routesSeguridad";
import video from "./admin/video/routesVideo";
import alert from "./admin/alertaPush/routesAlert";
import encuestas from "./admin/encuestas/routesEncuestas";
import transport from "./admin/transporte/routesTransporte";
import wall from "./admin/wall/routesWall";
import tv from "./admin/tv/routesTV";


Vue.use(VueRouter);

const routes = [
  ...benefit.benefitList,
  ...birthday.birthdayList,
  ...covid.covidList,
  ...culture.cultureList,
  ...gallery.galleryList,
  ...home.homeList,
  ...library.libraryList,
  ...muro.muroList,
  ...news.newsList,
  ...organigrama.organigramaList,
  ...recognize.recognizeList,
  ...seguridad.seguridadList,
  ...video.videosList,
  ...login.loginList,
  ...profile.profileList,
  ...users.userList,
  ...alert.alertPushList,
  ...encuestas.encuestasList,
  ...transport.transporteList,
  ...wall.wallList,
  ...tv.canalCorporativoList,
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
