const encuestasList = [
  {
    path: "/encuestas",
    name: "Encuestas",
    component: (Encuestas) =>
      require(["@/views/ADMIN/Encuestas.vue"], Encuestas),
    redirect: { name: "encuestas-list" },
    children: [
      {
        path: "list",
        name: "encuestas-list",
        component: (EncuestasList) =>
          require(["@/components/Encuestas/List.vue"], EncuestasList),
      },
      {
        path: "add",
        name: "encuestas-add",
        component: (EncuestasAdd) => require(["@/components/Encuestas/Add.vue"], EncuestasAdd),
      },
      {
        path: "edit/:id",
        name: "encuestas-edit",
        component: (EncuestasAdd) => require(["@/components/Encuestas/Add.vue"], EncuestasAdd),
      },
    ],
  },
];

export default {
  encuestasList,
};
